/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CustomAttributeSimpleDto } from '../model/custom-attribute-simple-dto';
import { CustomAttributeTypeSimpleDto } from '../model/custom-attribute-type-simple-dto';
import { EntityCustomAttributesDto } from '../model/entity-custom-attributes-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class CustomAttributeService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param parcelID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public customAttributesParcelsParcelIDGet(parcelID: number, observe?: 'body', reportProgress?: boolean): Observable<EntityCustomAttributesDto>;
    public customAttributesParcelsParcelIDGet(parcelID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EntityCustomAttributesDto>>;
    public customAttributesParcelsParcelIDGet(parcelID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EntityCustomAttributesDto>>;
    public customAttributesParcelsParcelIDGet(parcelID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parcelID === null || parcelID === undefined) {
            throw new Error('Required parameter parcelID was null or undefined when calling customAttributesParcelsParcelIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<EntityCustomAttributesDto>(`${this.basePath}/custom-attributes/parcels/${encodeURIComponent(String(parcelID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param parcelID 
     * @param entityCustomAttributesDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public customAttributesParcelsParcelIDPost(parcelID: number, entityCustomAttributesDto?: EntityCustomAttributesDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public customAttributesParcelsParcelIDPost(parcelID: number, entityCustomAttributesDto?: EntityCustomAttributesDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public customAttributesParcelsParcelIDPost(parcelID: number, entityCustomAttributesDto?: EntityCustomAttributesDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public customAttributesParcelsParcelIDPost(parcelID: number, entityCustomAttributesDto?: EntityCustomAttributesDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (parcelID === null || parcelID === undefined) {
            throw new Error('Required parameter parcelID was null or undefined when calling customAttributesParcelsParcelIDPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/custom-attributes/parcels/${encodeURIComponent(String(parcelID))}`,
            entityCustomAttributesDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public customAttributesTypesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<CustomAttributeTypeSimpleDto>>;
    public customAttributesTypesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CustomAttributeTypeSimpleDto>>>;
    public customAttributesTypesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CustomAttributeTypeSimpleDto>>>;
    public customAttributesTypesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CustomAttributeTypeSimpleDto>>(`${this.basePath}/custom-attributes/types`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param waterAccountID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public customAttributesWaterAccountsWaterAccountIDGet(waterAccountID: number, observe?: 'body', reportProgress?: boolean): Observable<EntityCustomAttributesDto>;
    public customAttributesWaterAccountsWaterAccountIDGet(waterAccountID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EntityCustomAttributesDto>>;
    public customAttributesWaterAccountsWaterAccountIDGet(waterAccountID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EntityCustomAttributesDto>>;
    public customAttributesWaterAccountsWaterAccountIDGet(waterAccountID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (waterAccountID === null || waterAccountID === undefined) {
            throw new Error('Required parameter waterAccountID was null or undefined when calling customAttributesWaterAccountsWaterAccountIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<EntityCustomAttributesDto>(`${this.basePath}/custom-attributes/water-accounts/${encodeURIComponent(String(waterAccountID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param waterAccountID 
     * @param entityCustomAttributesDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public customAttributesWaterAccountsWaterAccountIDPost(waterAccountID: number, entityCustomAttributesDto?: EntityCustomAttributesDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public customAttributesWaterAccountsWaterAccountIDPost(waterAccountID: number, entityCustomAttributesDto?: EntityCustomAttributesDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public customAttributesWaterAccountsWaterAccountIDPost(waterAccountID: number, entityCustomAttributesDto?: EntityCustomAttributesDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public customAttributesWaterAccountsWaterAccountIDPost(waterAccountID: number, entityCustomAttributesDto?: EntityCustomAttributesDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (waterAccountID === null || waterAccountID === undefined) {
            throw new Error('Required parameter waterAccountID was null or undefined when calling customAttributesWaterAccountsWaterAccountIDPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/custom-attributes/water-accounts/${encodeURIComponent(String(waterAccountID))}`,
            entityCustomAttributesDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param customAttributeTypeID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDCustomAttributesCustomAttributeTypeIDGet(geographyID: number, customAttributeTypeID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<CustomAttributeSimpleDto>>;
    public geographiesGeographyIDCustomAttributesCustomAttributeTypeIDGet(geographyID: number, customAttributeTypeID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CustomAttributeSimpleDto>>>;
    public geographiesGeographyIDCustomAttributesCustomAttributeTypeIDGet(geographyID: number, customAttributeTypeID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CustomAttributeSimpleDto>>>;
    public geographiesGeographyIDCustomAttributesCustomAttributeTypeIDGet(geographyID: number, customAttributeTypeID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDCustomAttributesCustomAttributeTypeIDGet.');
        }

        if (customAttributeTypeID === null || customAttributeTypeID === undefined) {
            throw new Error('Required parameter customAttributeTypeID was null or undefined when calling geographiesGeographyIDCustomAttributesCustomAttributeTypeIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<CustomAttributeSimpleDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/custom-attributes/${encodeURIComponent(String(customAttributeTypeID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param customAttributeTypeID 
     * @param customAttributeSimpleDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDCustomAttributesCustomAttributeTypeIDPut(geographyID: number, customAttributeTypeID: number, customAttributeSimpleDto?: Array<CustomAttributeSimpleDto>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public geographiesGeographyIDCustomAttributesCustomAttributeTypeIDPut(geographyID: number, customAttributeTypeID: number, customAttributeSimpleDto?: Array<CustomAttributeSimpleDto>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public geographiesGeographyIDCustomAttributesCustomAttributeTypeIDPut(geographyID: number, customAttributeTypeID: number, customAttributeSimpleDto?: Array<CustomAttributeSimpleDto>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public geographiesGeographyIDCustomAttributesCustomAttributeTypeIDPut(geographyID: number, customAttributeTypeID: number, customAttributeSimpleDto?: Array<CustomAttributeSimpleDto>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDCustomAttributesCustomAttributeTypeIDPut.');
        }

        if (customAttributeTypeID === null || customAttributeTypeID === undefined) {
            throw new Error('Required parameter customAttributeTypeID was null or undefined when calling geographiesGeographyIDCustomAttributesCustomAttributeTypeIDPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/custom-attributes/${encodeURIComponent(String(customAttributeTypeID))}`,
            customAttributeSimpleDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}
