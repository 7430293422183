<div class="grid-header flex-between">
    <div class="grid-actions flex">
        <div class="global-filter">
            <div class="field" *ngIf="!hideGlobalFilter">
                <input type="text" name="quickFilterText" [(ngModel)]="quickFilterText" placeholder="Search grid..." />
            </div>
        </div>
        <ng-content select="[customGridActionsLeft]"></ng-content>
    </div>
    <div class="grid-actions flex-end">
        <ng-container *ngIf="multiSelectEnabled">
            <span class="selection-info" *ngIf="selectedRowsCount > 0">
                {{ selectedRowsCount }} of {{ rowData?.length }} Selected
            </span>

            <button class="btn btn-sm btn-secondary" (click)="onSelectAll()" [class.disabled]="allRowsSelected">
                Select All
                <span *ngIf="anyFilterPresent">(filtered)</span>
            </button>
            <button class="btn btn-sm btn-secondary" (click)="onDeselectAll()" [class.disabled]="selectedRowsCount < 1">
                Deselect All
                <span *ngIf="anyFilterPresent">(filtered)</span>
            </button>
        </ng-container>

        <ng-content select="[customGridActions]"></ng-content>

        <div class="clear-filters-button">
            <qanat-clear-grid-filters-button [grid]="gridref"
                (filtersCleared)="onFiltersCleared()"></qanat-clear-grid-filters-button>
        </div>
    </div>
</div>

<div class="table-responsive">
    <ag-grid-angular [style.height]="height" [style.width]="width" defaultGridOptions #gridref class="ag-theme-balham"
        [overlayLoadingTemplate]="agGridOverlay" [autoSizeStrategy]="autoSizeStrategy" [rowData]="rowData"
        [columnDefs]="columnDefs" [defaultColDef]="defaultColDef" [tooltipInteraction]="true" [tooltipShowDelay]="800"
        [rowSelection]="rowSelection" [suppressRowClickSelection]="suppressRowClickSelection"
        [rowMultiSelectWithClick]="rowMultiSelectWithClick" [suppressRowTransform]="true" [suppressMenuHide]="true"
        [pagination]="pagination" [paginationPageSize]="paginationPageSize" [suppressPaginationPanel]="true"
        [quickFilterText]="quickFilterText" [getRowId]="getRowId" (gridReady)="onGridReady($event)"
        (firstDataRendered)="onFirstDataRendered($event)" (gridColumnsChanged)="onGridColumnsChanged($event)"
        (selectionChanged)="this.onSelectionChanged($event)"
        (filterChanged)="onFilterChanged($event)"></ag-grid-angular>

    <div class="table-footer flex-between">
        <div *ngIf="!hideDownloadButton" class="download-button">
            <qanat-csv-download-button [grid]="gridref" [fileName]="downloadFileName"
                [colIDsToExclude]="colIDsToExclude"></qanat-csv-download-button>
        </div>

        <pagination-controls *ngIf="gridLoaded && pagination" [grid]="gridref"></pagination-controls>

        <div class="row-count-data">
            Total Records: {{ rowData?.length | number }}

            <div *ngIf="anyFilterPresent" class="filtered-rows-count">
                Filtered Records: {{ filteredRowsCount | number }}
            </div>
        </div>
    </div>
</div>