/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { WellRegistryWorkflowProgressDtoSteps } from '././well-registry-workflow-progress-dto-steps';
import { WellRegistrationStatusSimpleDto } from '././well-registration-status-simple-dto';

import { FormControl, FormControlOptions, FormControlState, Validators } from "@angular/forms";
export class WellRegistryWorkflowProgressDto { 
    WellRegistrationID?: number;
    GeographyID?: number;
    WellRegistrationStatus?: WellRegistrationStatusSimpleDto;
    Steps?: WellRegistryWorkflowProgressDtoSteps;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export interface WellRegistryWorkflowProgressDtoForm { 
    WellRegistrationID?: FormControl<number>;
    GeographyID?: FormControl<number>;
    WellRegistrationStatus?: FormControl<WellRegistrationStatusSimpleDto>;
    Steps?: FormControl<WellRegistryWorkflowProgressDtoSteps>;
}

export class WellRegistryWorkflowProgressDtoFormControls { 
    public static WellRegistrationID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static GeographyID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WellRegistrationStatus = (value: FormControlState<WellRegistrationStatusSimpleDto> | WellRegistrationStatusSimpleDto = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<WellRegistrationStatusSimpleDto>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static Steps = (value: FormControlState<WellRegistryWorkflowProgressDtoSteps> | WellRegistryWorkflowProgressDtoSteps = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<WellRegistryWorkflowProgressDtoSteps>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
}
