/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { FormControl, FormControlOptions, FormControlState, Validators } from "@angular/forms";
export class FrequentlyAskedQuestionAdminFormDto { 
    FrequentlyAskedQuestionID?: number;
    QuestionText: string;
    AnswerText: string;
    FaqDisplayLocationTypeIDs?: Array<number>;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export interface FrequentlyAskedQuestionAdminFormDtoForm { 
    FrequentlyAskedQuestionID?: FormControl<number>;
    QuestionText: FormControl<string>;
    AnswerText: FormControl<string>;
    FaqDisplayLocationTypeIDs?: FormControl<Array<number>>;
}

export class FrequentlyAskedQuestionAdminFormDtoFormControls { 
    public static FrequentlyAskedQuestionID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static QuestionText = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
                Validators.minLength(1),
            ],
        }
    );
    public static AnswerText = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
                Validators.minLength(1),
            ],
        }
    );
    public static FaqDisplayLocationTypeIDs = (value: FormControlState<Array<number>> | Array<number> = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<Array<number>>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
}
