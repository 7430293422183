import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'trading-configure',
    templateUrl: './trading-configure.component.html',
    styleUrls: ['./trading-configure.component.scss'],
    standalone: true
})
export class TradingConfigureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
