import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WaterAccountDashboardMapComponent } from '../water-account-dashboard-map/water-account-dashboard-map.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { WaterAccountBudgetGridComponent } from 'src/app/shared/components/water-account/water-account-budget-grid/water-account-budget-grid.component';
import { WaterAccountDashboardDto } from 'src/app/shared/generated/model/water-account-dashboard-dto';
import { GeographySimpleDto, UserDto } from 'src/app/shared/generated/model/models';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { GeographyDisplayDto } from 'src/app/shared/generated/model/geography-display-dto';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { AlertDisplayComponent } from 'src/app/shared/components/alert-display/alert-display.component';
import { WaterAccountSmallCardComponent } from '../water-account-small-card/water-account-small-card.component';

@Component({
  selector: 'water-account-dashboard',
  standalone: true,
  imports: [CommonModule, WaterAccountDashboardMapComponent, ReactiveFormsModule, AgGridModule, AlertDisplayComponent, WaterAccountBudgetGridComponent, WaterAccountSmallCardComponent, RouterModule],
  templateUrl: './water-account-dashboard.component.html',
  styleUrls: ['./water-account-dashboard.component.scss'],
})
export class WaterAccountDashboardComponent implements OnInit, OnChanges {
  @Input() waterAccounts: WaterAccountDashboardDto[];
  public viewingWaterAccountsTab: boolean = true;

  public uniqueGeographies: GeographySimpleDto[] = [];
  public currentUser$: Observable<UserDto>;
  public currentUser: UserDto;
  public highlightedWaterAccountDto: WaterAccountDashboardDto;
  public geographyWaterAccountDict = {};

  public ownsWaterAccountInSelectedGeography: boolean;
  public showRequestChangesButton: boolean = false;

  private _highlightedWaterAccountID: number;
  set highlightedWaterAccountID(value: number) {
    this._highlightedWaterAccountID = value;
  }

  get highlightedWaterAccountID(): number {
    return this._highlightedWaterAccountID;
  }

  public selectedGeography: FormControl<GeographyDisplayDto> = new FormControl<GeographyDisplayDto>(null);
  public isReady: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.waterAccounts && !changes.waterAccounts.firstChange) {
      this.filterGeographies();
      this.getGeographiesToShow();
    }
  }

  ngOnInit(): void {
    this.currentUser$ = this.authenticationService.getCurrentUser().pipe(
      tap(currentUser => {
        this.currentUser = currentUser;
        this.filterGeographies();
      })
    );
  }

  filterGeographies() {
    const geographies = this.waterAccounts.map((x) => x.Geography);
    this.uniqueGeographies = [];

    geographies.forEach((geography) => {
      const alreadyExists = this.uniqueGeographies.map((x) => x.GeographyID).includes(geography.GeographyID);
      if (!alreadyExists) {
        this.uniqueGeographies.push(geography);
      }
      this.geographyWaterAccountDict[geography.GeographyName] = this.waterAccounts.filter((x) => x.Geography.GeographyName == geography.GeographyName);
    });

    let geographyFromQueryParam = [];
    this.route.queryParams.subscribe(params => {
      if (params.geography) {
        const geographyIDQueryParam = parseInt(params.geography);
        geographyFromQueryParam = this.uniqueGeographies.filter(x => x.GeographyID == geographyIDQueryParam);
      }
    });

    if (geographyFromQueryParam.length == 1) {
      this.selectedGeography.setValue(geographyFromQueryParam[0]);
    } else if (this.isCurrentUserAdmin()) {
      this.selectedGeography.setValue(this.uniqueGeographies.find((x) => x.GeographyName == 'Demo'));
    } else if (this.uniqueGeographies.length == 1) {
      this.selectedGeography.setValue(this.uniqueGeographies[0]);
    }

    this.isReady = true;
  }

  isCurrentUserAdmin() {
    return this.authenticationService.isCurrentUserAnAdministrator();
  }

  selectWaterAccount(waterAccount) {
    this.highlightedWaterAccountID = waterAccount.WaterAccountID;
  }

  getWaterAccounts(geography) {
    return this.geographyWaterAccountDict[geography.GeographyName];
  }

  getNumberOfWaterAccounts(geography) {
    return '(' + this.geographyWaterAccountDict[geography.GeographyName].length + ' Water Accounts)';
  }

  getGeographiesToShow() {
    if (this.selectedGeography.value == null) {
      return this.uniqueGeographies;
    } else {
      return [this.selectedGeography.value];
    }
  }
}
