import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WellLandownerDashboardDto } from 'src/app/shared/generated/model/well-landowner-dashboard-dto';
import { ColDef } from 'ag-grid-community';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { QanatGridComponent } from 'src/app/shared/components/qanat-grid/qanat-grid.component';
import { UtilityFunctionsService } from 'src/app/shared/services/utility-functions.service';
import { AlertDisplayComponent } from '../../alert-display/alert-display.component';
import { LoadingDirective } from 'src/app/shared/directives/loading.directive';

@Component({
  selector: 'landowner-well-grid',
  standalone: true,
  templateUrl: './landowner-well-grid.component.html',
  styleUrls: ['./landowner-well-grid.component.scss'],
  imports: [CommonModule, AgGridModule, QanatGridComponent, AlertDisplayComponent, LoadingDirective]
})
export class LandownerWellGridComponent implements OnInit {
  @Output() wellDelete: EventEmitter<number> = new EventEmitter();
  @Input() wells: WellLandownerDashboardDto[];

  public columnDefs: ColDef[];

  constructor(
    private utilityFunctionsService: UtilityFunctionsService
  ) { }

  ngOnInit(): void {
    this.createColumnDefs();
  }

  private createColumnDefs() {
    this.columnDefs = [
      this.utilityFunctionsService.createBasicColumnDef('Well Name', 'WellName'),
      this.utilityFunctionsService.createBasicColumnDef('Geography', 'Geography.GeographyName', { CustomDropdownFilterField: 'Geography.GeographyName ' }),
      this.utilityFunctionsService.createBasicColumnDef('County Well Permit #', 'CountyWellPermitNumber', { FieldDefinitionType: 'CountyWellPermitNo' }),
      this.utilityFunctionsService.createBasicColumnDef('State WCR #', 'StateWCRNumber', { FieldDefinitionType: 'StateWCRNo' }),
      this.utilityFunctionsService.createDateColumnDef('DateDrilled', 'DateDrilled', 'M/d/yyyy', { FieldDefinitionType: 'DateDrilled' }),
      this.utilityFunctionsService.createDecimalColumnDef('Well Depth', 'WellDepth', { FieldDefinitionType: 'WellDepth' }),
      this.utilityFunctionsService.createLatLonColumnDef('Latitude', 'Latitude'),
      this.utilityFunctionsService.createLatLonColumnDef('Longitude', 'Longitude')
    ];
  }
}
