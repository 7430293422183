<page-header
    icon="Zones"
    [customRichTextTypeID]="richTextTypeID"
    [pageTitle]="'Assign Parcels to Zones'"></page-header>
<div class="page-body">
    <app-alert-display></app-alert-display>

    <ng-container *ngIf="geographyZoneGroups$ | async as geographyZoneGroups">
        <div class="grid-12">
            <form-field
                class="g-col-4"
                fieldLabel="Select a Zone Group"
                [formControl]="selectedZoneGroupField"
                [formInputOptions]="zoneGroupSelectOptions"
                [type]="FormFieldType.Select"></form-field>
        </div>

        <div
            *ngIf="selectedZoneGroupFieldValue$ | async as selectedZoneGroup; else noneSelected"
            class="mt-5">
            <manage-zone-group-card [zoneGroup]="selectedZoneGroup">
                <form class="form mb-3 grid-9">
                    <ng-template #fileInputPanel>
                        <div class="field g-col-4">
                            <label class="required field-label">File</label>
                            <div class="file-upload-wrapper pb-2">
                                <label for="file-upload" class="custom-file-upload">
                                    <span class="file-upload-name">
                                        <input
                                            type="file"
                                            class="form-control"
                                            name="file-upload"
                                            [id]="fileUploadElementID"
                                            (change)="onFileUploadChange($event)"
                                            required />
                                        {{ fileUpload?.name ?? 'No file chosen...' }}
                                    </span>

                                    <fresca-button
                                        iconClass="fas fa-folder-open"
                                        (click)="onClickFileUpload()">
                                        Browse
                                    </fresca-button>
                                </label>
                            </div>
                            <em>Accepted extensions: CSV</em>
                            <i class="fas fa-file-open"></i>
                        </div>
                        <div class="g-col-5">
                            <qanat-map
                                (onMapLoad)="handleMapReady($event)"
                                mapHeight="400px"
                                [boundingBox]="geography.BoundingBox">
                                <zone-group-layer
                                    *ngIf="mapIsReady"
                                    [displayOnLoad]="true"
                                    [zoneGroupID]="selectedZoneGroup.ZoneGroupID"
                                    [zoneGroupName]="selectedZoneGroup.ZoneGroupName"
                                    [map]="map"
                                    [layerControl]="layerControl"></zone-group-layer>
                            </qanat-map>
                        </div>

                        <div class="form__actions flex-end">
                            <fresca-button
                                (click)="getFileUploadHeaders()"
                                [disabled]="!fileUpload || isLoadingSubmit">
                                <span
                                    *ngIf="isLoadingSubmit"
                                    class="fa fa-spinner loading-spinner"></span>
                                Continue
                            </fresca-button>
                        </div>
                    </ng-template>
                    <ng-container *ngIf="!displayFileInputPanel; else fileInputPanel">
                        <form class="form mb-3 grid-8">
                            <div class="field g-col-4">
                                <label class="d-block required field-label">APN Column</label>
                                <ng-select
                                    class="form-control"
                                    name="apnColumnName"
                                    [(ngModel)]="apnColumnName">
                                    <ng-option
                                        *ngFor="let fileUploadHeader of fileUploadHeaders"
                                        [value]="fileUploadHeader"
                                        [disabled]="fileUploadHeader == zoneColumnName">
                                        {{ fileUploadHeader }}
                                    </ng-option>
                                </ng-select>
                                <field-definition
                                    fieldDefinitionType="APNColumn"></field-definition>
                            </div>

                            <div class="field g-col-4">
                                <label class="d-block required field-label">Zone Column</label>
                                <ng-select
                                    class="form-control"
                                    name="zoneColumnName"
                                    [(ngModel)]="zoneColumnName">
                                    <ng-option
                                        *ngFor="let fileUploadHeader of fileUploadHeaders"
                                        [value]="fileUploadHeader"
                                        [disabled]="fileUploadHeader == apnColumnName">
                                        {{ fileUploadHeader }}
                                    </ng-option>
                                </ng-select>
                                <field-definition
                                    fieldDefinitionType="ZoneColumn"></field-definition>
                            </div>
                        </form>

                        <div class="form__actions flex-end">
                            <button
                                class="btn btn-primary"
                                (click)="onSubmit(selectedZoneGroup)"
                                [disabled]="isLoadingSubmit">
                                <span
                                    *ngIf="isLoadingSubmit"
                                    class="fa fa-spinner loading-spinner"></span>
                                Save
                            </button>

                            <button class="btn btn-primary-outline" (click)="closeColumns()">
                                Cancel
                            </button>
                        </div>
                    </ng-container>
                </form>
            </manage-zone-group-card>
        </div>
        <ng-template #noneSelected>
            <no-selected-item-box
                class="mt-5"
                [boxHeight]="500"
                boxText="No Zone Group Selected"></no-selected-item-box>
        </ng-template>
    </ng-container>
</div>
