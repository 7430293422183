/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ReferenceWellSimpleDto } from '././reference-well-simple-dto';
import { WaterUseTypesUsed } from '././water-use-types-used';

import { FormControl, FormControlOptions, FormControlState, Validators } from "@angular/forms";
export class WellRegistrationBasicInfoFormDto { 
    WellName?: string;
    StateWellNumber?: string;
    StateWellCompletionNumber?: string;
    CountyWellPermit?: string;
    DateDrilled?: string;
    WaterUseTypes?: Array<WaterUseTypesUsed>;
    ReferenceWell?: ReferenceWellSimpleDto;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export interface WellRegistrationBasicInfoFormDtoForm { 
    WellName?: FormControl<string>;
    StateWellNumber?: FormControl<string>;
    StateWellCompletionNumber?: FormControl<string>;
    CountyWellPermit?: FormControl<string>;
    DateDrilled?: FormControl<string>;
    WaterUseTypes?: FormControl<Array<WaterUseTypesUsed>>;
    ReferenceWell?: FormControl<ReferenceWellSimpleDto>;
}

export class WellRegistrationBasicInfoFormDtoFormControls { 
    public static WellName = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static StateWellNumber = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static StateWellCompletionNumber = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static CountyWellPermit = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static DateDrilled = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WaterUseTypes = (value: FormControlState<Array<WaterUseTypesUsed>> | Array<WaterUseTypesUsed> = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<Array<WaterUseTypesUsed>>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static ReferenceWell = (value: FormControlState<ReferenceWellSimpleDto> | ReferenceWellSimpleDto = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<ReferenceWellSimpleDto>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
}
