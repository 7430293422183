<div class="page-header">
    <div>
        <ng-container *ngIf="templateAbove" [ngTemplateOutlet]="templateAbove"></ng-container>
        <h3 *ngIf="preTitle" class="page-tab">{{ preTitle }}</h3>
        <div [ngClass]="templateRight ? 'flex-between no-wrap' : ''">
            <h1 class="page-title">
                <span>
                    <icon class="page-header-icon" *ngIf="icon" [icon]="icon"></icon>
                    {{ pageTitleDisplay }}
                </span>

                <ng-container
                    *ngIf="templateTitleAppend"
                    [ngTemplateOutlet]="templateTitleAppend"></ng-container>
            </h1>
            <div *ngIf="templateRight">
                <ng-container [ngTemplateOutlet]="templateRight"></ng-container>
            </div>
        </div>
        <div class="copy copy-2" *ngIf="customRichTextTypeID">
            <custom-rich-text
                [customRichTextTypeID]="customRichTextTypeID"
                [geographyID]="customRichTextTypeGeographyID"></custom-rich-text>
        </div>
        <ng-content></ng-content>
    </div>
</div>
