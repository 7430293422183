<ng-container *ngIf="wells?.length > 0; else noWaterAccountsMessage">
    <div class="flex-center mb-4">
        <div class="button-group">
            <button class="button-group__item active" [class.active]="viewingWellCardTab === true"
                (click)="viewingWellCardTab = true">
                Well Map
            </button>
            <button class="button-group__item" [class.active]="viewingWellCardTab === false"
                (click)="viewingWellCardTab = false">
                Well List
            </button>
        </div>
    </div>

    <section class="map-view" *ngIf="viewingWellCardTab">
        <div class="grid-12">
            <div class="g-col-3">
                <div class="form-field">
                    <div class="field" *ngIf="geographyWellDict.length > 1">
                        <label for="GeographyPicker" class="field-label">Filter by Geography</label>
                        <select name="GeographyPicker" id="GeographyPicker" *ngIf="uniqueGeographies.length > 0"
                            [formControl]="selectedGeography">
                            <option [ngValue]="null" *ngIf="!isCurrentUserAdmin() && uniqueGeographies.length > 1">
                                All Water Accounts
                            </option>
                            <option [ngValue]="geography" *ngFor="let geography of uniqueGeographies">
                                {{ geography.GeographyName }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid-12 mt-4">
            <div class="g-col-4">
                <div class="well-list scroll-shadow">
                    <div class="mb-4" *ngFor="let geography of getGeographiesToShow()">
                        <h3 class="module-title underline mb-3">
                            {{ geography.GeographyName }} {{ getNumberOfWells(geography) }}
                        </h3>
                        <div class="well-list__wrapper">
                            <div *ngFor="let well of getWellsFromGeography(geography)">
                                <well-small-card [well]="well" [isHighlighted]="highlightedWellID === well.WellID"
                                    (click)="setSelectedWell(well)"></well-small-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="g-col-8" *ngIf="isReady">
                <qanat-map (onMapLoad)="handleMapReady($event)" mapHeight="700px">
                    <ng-container *ngIf="mapIsReady">
                        <wells-layer [wells]="geographyWellDict[selectedGeography.value.GeographyName]"
                            [displayOnLoad]="true" [map]="map" (popupOpened)="openedPopup($event)"
                            [layerControl]="layerControl" [highlightedWellID]="highlightedWellID"></wells-layer>
                    </ng-container>
                </qanat-map>
            </div>
        </div>
    </section>

    <section class="budget-view" *ngIf="!viewingWellCardTab">
        <landowner-well-grid [wells]="wells" (wellDelete)="handleWellDelete($event)"></landowner-well-grid>
    </section>
</ng-container>
<ng-template #noWaterAccountsMessage>
    <div class="alert alert-info">
        <div class="alert-content">
            <i class="fa fa-info"></i>
            Your user profile does not have access to manage any Wells at this time. Wells will
            display here when a water manager has assigned a well to your water account. Contact a
            water manager for your geography for more information.
        </div>
    </div>
</ng-template>