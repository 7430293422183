<div class="parcel-detail-manage-card card">
    <div class="card-header">
        <h3 class="card-title">Details</h3>
    </div>
    <div class="card-body">
        <div class="grid-12">
            <div class="g-col-3">
                <key-value-pair-list>
                    <key-value-pair>
                        <ng-container key>APN</ng-container>
                        <ng-container keyValue>
                            {{ parcel.ParcelNumber }}
                        </ng-container>
                    </key-value-pair>
                    <key-value-pair>
                        <ng-container key>Area</ng-container>
                        <ng-container keyValue>
                            {{ parcel.ParcelArea | number : '1.0-1' }}
                        </ng-container>
                    </key-value-pair>
                    <key-value-pair>
                        <ng-container key>Owner Name</ng-container>
                        <ng-container keyValue>
                            {{ parcel.OwnerName ?? 'Not Available' }}
                        </ng-container>
                    </key-value-pair>
                    <key-value-pair>
                        <ng-container key>Owner Address</ng-container>
                        <ng-container keyValue>
                            {{ parcel.OwnerAddress ?? 'Not Available' }}
                        </ng-container>
                    </key-value-pair>
                </key-value-pair-list>
            </div>

            <div class="g-col-3">
                <key-value-pair-list>
                    <key-value-pair>
                        <ng-container key>
                            <field-definition
                                [fieldDefinitionType]="'WaterAccount'"
                                [inline]="false"></field-definition>
                        </ng-container>
                        <ng-container keyValue>
                            <ng-container *ngIf="parcel.WaterAccount; else noWaterAccount">
                                <a
                                    [routerLink]="[
                                        '/manage',
                                        geography.GeographyName.toLowerCase(),
                                        'water-accounts',
                                        parcel.WaterAccount.WaterAccountID
                                    ]">
                                    <water-account-title
                                        [waterAccountName]="parcel.WaterAccount.WaterAccountName"
                                        [waterAccountNumber]="
                                            parcel.WaterAccount.WaterAccountNumber.toString()
                                        "></water-account-title>
                                </a>
                            </ng-container>
                            <ng-template #noWaterAccount>
                                <em>Not Available</em>
                            </ng-template>
                        </ng-container>
                    </key-value-pair>
                    <key-value-pair>
                        <ng-container key>Wells on Parcel</ng-container>
                        <ng-container keyValue>
                            <ng-container
                                *ngIf="
                                    parcel.WellsOnParcel && parcel.WellsOnParcel.length > 0;
                                    else noWellsOnParcel
                                ">
                                <div *ngFor="let well of parcel.WellsOnParcel">
                                    <a
                                        [routerLink]="[
                                            '/manage',
                                            geography.GeographyName.toLowerCase(),
                                            'wells',
                                            well.WellID
                                        ]">
                                        {{ well.WellName }} ({{ well.WellID }})
                                    </a>
                                </div>
                            </ng-container>
                            <ng-template #noWellsOnParcel>
                                <em>Not Available</em>
                            </ng-template>
                        </ng-container>
                    </key-value-pair>
                    <key-value-pair>
                        <ng-container key>Irrigated By Wells</ng-container>
                        <ng-container keyValue>
                            <ng-container
                                *ngIf="
                                    parcel.IrrigatedByWells && parcel.IrrigatedByWells.length > 0;
                                    else noIrrigatedByWells
                                ">
                                <div *ngFor="let well of parcel.IrrigatedByWells">
                                    <a
                                        [routerLink]="[
                                            '/manage',
                                            geography.GeographyName.toLowerCase(),
                                            'wells',
                                            well.WellID
                                        ]">
                                        {{ well.WellName }} ({{ well.WellID }})
                                    </a>
                                </div>
                            </ng-container>
                            <ng-template #noIrrigatedByWells>
                                <em>Not Available</em>
                            </ng-template>
                        </ng-container>
                    </key-value-pair>
                    <key-value-pair>
                        <ng-container key>Zones</ng-container>
                        <ng-container keyValue>
                            <div class="zones">
                                <div class="zone" *ngFor="let zone of parcel.Zones">
                                    <div class="zone-group-name">{{ zone.ZoneGroupName }}</div>
                                    <div class="zone-name">
                                        <span
                                            class="zone-color"
                                            [style.background-color]="zone.ZoneColor"></span>
                                        <span>{{ zone.ZoneName }}</span>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </key-value-pair>
                    <key-value-pair>
                        <ng-container key>
                            <field-definition
                                [fieldDefinitionType]="'ParcelStatus'"
                                [labelOverride]="'Status'"
                                [inline]="false"></field-definition>
                        </ng-container>
                        <ng-container keyValue>
                            {{ parcel.ParcelStatus?.ParcelStatusDisplayName ?? 'Not Available' }}
                        </ng-container>
                    </key-value-pair>
                </key-value-pair-list>
            </div>
            <div class="g-col-6">
                <parcel-map
                    mapID="parcelsMapForParcel"
                    [selectedParcelIDs]="[parcel.ParcelID]"
                    mapHeight="500px"
                    [collapsedLayerControl]="true"
                    [displayParcelLayerOnLoad]="false"
                    [geographyID]="parcel.GeographyID"></parcel-map>
            </div>
        </div>
    </div>
    <div class="card-footer">
        <a href="javascript:void(0);" (click)="updateOwnershipInfo()">
            <icon icon="Users"></icon>
            Update Ownership Info
        </a>
        <a href="javascript:void(0);" (click)="updateWaterAccount()">
            <icon icon="WaterAccounts"></icon>
            Update Water Account
        </a>
        <a href="javascript:void(0);" (click)="editZoneAssignments()">
            <icon icon="Zones"></icon>
            Edit Zone Assignments
        </a>
        <a href="javascript:void(0);" (click)="modifyParcelStatus()">
            <icon icon="Parcels"></icon>
            Modify Parcel Status
        </a>
    </div>
</div>
