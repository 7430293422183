<div class="list-view">
    <page-header
        icon="Wells"
        [templateRight]="templateRight"
        [customRichTextTypeID]="richTextTypeID">
        <ng-template #templateRight>
            <span class="flex">
                <button class="btn btn-primary btn-rounded" [routerLink]="['bulk-upload']">
                    Upload Wells
                </button>
                <span class="ml-1">
                    <a
                        [routerLink]="[
                            '/well-registry',
                            geography.GeographyName.toLowerCase(),
                            'new'
                        ]"
                        class="btn btn-primary-outline btn-rounded">
                        Register a Well
                    </a>
                </span>
            </span>
        </ng-template>
    </page-header>

    <div class="page-body">
        <app-alert-display></app-alert-display>

        <ng-container *ngIf="wells$ | async as wellsResponse; else loading">
            <div class="parcel-map">
                <parcel-map
                    mapID="wellMap"
                    mapHeight="540px"
                    [highlightedWellID]="highlightedWellID"
                    [highlightWellOnClick]="true"
                    [displayWellLayerOnLoad]="true"
                    [boundingBoxInput]="geography?.BoundingBox"
                    [geographyID]="geography.GeographyID"
                    [cqlFilter]="mapCqlFilter"
                    (highlightedWellIDChange)="onMapSelection($event)"></parcel-map>
            </div>
            <div class="parcel-list pt-5">
                <qanat-grid
                    [rowData]="wellsResponse"
                    [columnDefs]="columnDefs"
                    rowSelection="single"
                    (gridReady)="onGridReady($event)"
                    (selectionChanged)="onSelectionChanged($event)"
                    (filterChanged)="selectHighlightedWellIDRowNode()"
                    downloadFileName="wells"></qanat-grid>
            </div>
        </ng-container>
        <ng-template #loading>
            <div [loadingSpinner]="{ loadingHeight: 700, isLoading: true }"></div>
        </ng-template>
    </div>
</div>
