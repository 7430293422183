<div class="grid-12">
    <div class="g-col-12" *ngIf="data$ | async; else isLoading">
        <div class="filter-btn-group flex-between mb-3">
            <div class="statistics__filter year mb-2">
                <h5 class="statistics__filter-label">Year</h5>

                <select
                    name="years"
                    [(ngModel)]="selectedYear"
                    (change)="updateReportForSelectedYear()">
                    <option *ngFor="let year of yearsInReportingPeriods" [value]="year">
                        {{ year }}
                    </option>
                </select>
            </div>
        </div>

        <qanat-grid
            [rowData]="budgetReports"
            [columnDefs]="columnDefs"
            downloadFileName="water-account-budgets"
            (gridReady)="budgetGridReady($event)"></qanat-grid>
    </div>

    <ng-template #isLoading>
        <div [loadingSpinner]="{ isLoading: true, loadingHeight: 720 }"></div>
    </ng-template>
</div>
