//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[Permission]

export enum PermissionEnum {
  CustomRichTextRights = 2,
  FieldDefinitionRights = 3,
  FileResourceRights = 4,
  UserRights = 5,
  WaterAccountRights = 6,
  ParcelRights = 7,
  TagRights = 8,
  WellRights = 9,
  WaterTransactionRights = 10,
  GETActionRights = 11,
  ReportingPeriodRights = 12,
  WaterTypeRights = 13,
  GeographyRights = 14,
  ExternalMapLayerRights = 15,
  WaterAccountUserRights = 16,
  ZoneGroupRights = 17,
  MonitoringWellRights = 18,
  AllocationPlanRights = 19,
  FrequentlyAskedQuestionRights = 20,
  CustomAttributeRights = 21,
  UsageEntityRights = 22
}
