<div class="menu-container" *ngIf="params && !params.hidden">
    <a class="btn btn-primary-outline btn-small" id="menu-dropdown" [dropdownToggle]="contextMenu">
        {{ params.title }}
        <icon icon="CaretDown"></icon>
    </a>

    <div class="context-menu-wrapper">
        <div #contextMenu class="context-menu" aria-labelledby="menu-dropdown">
            <ng-container *ngFor="let action of params.value">
                <button *ngIf="action.ActionHandler" (click)="action.ActionHandler()">
                    <span *ngIf="action.ActionIcon" [class]="action.ActionIcon"></span>
                    {{ action.ActionName }}
                </button>
                <a *ngIf="!action.ActionHandler" [routerLink]="[action.ActionLink]">
                    <span *ngIf="action.ActionIcon" [class]="action.ActionIcon"></span>
                    {{ action.ActionName }}
                </a>
            </ng-container>
        </div>
    </div>
</div>
