<page-header
    pageTitle="Water Account Attributes"
    [customRichTextTypeID]="customRichTextTypeID"></page-header>

<div class="page-body">
    <app-alert-display></app-alert-display>

    <entity-custom-attributes-edit
        *ngIf="waterAccountCustomAttributes$ | async as waterAccountCustomAttributes"
        [entityCustomAttributes]="waterAccountCustomAttributes"
        [isLoadingSubmit]="isLoadingSubmit"
        (save)="save($event)"></entity-custom-attributes-edit>
</div>
