import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { FlagEnum } from 'src/app/shared/generated/enum/flag-enum';
import { PermissionEnum } from 'src/app/shared/generated/enum/permission-enum';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { RightsEnum } from 'src/app/shared/models/enums/rights.enum';
import { AlertService } from 'src/app/shared/services/alert.service';
import { routeParams } from 'src/app/app.routes';
import { SelectedGeographyService } from 'src/app/shared/services/selected-geography.service';
import { ModalService, ModalSizeEnum, ModalThemeEnum } from 'src/app/shared/services/modal/modal.service';
import { DeleteWaterAccountComponent } from 'src/app/shared/components/water-account/modals/delete-water-account/delete-water-account.component';
import { MergeWaterAccountsComponent } from 'src/app/shared/components/water-account/modals/merge-water-accounts/merge-water-accounts.component';
import { UpdateParcelsComponent } from 'src/app/shared/components/water-account/modals/update-parcels/update-parcels.component';
import { UpdateWaterAccountInfoComponent, WaterAccountContext } from 'src/app/shared/components/water-account/modals/update-water-account-info/update-water-account-info.component';
import { EntityCustomAttributesDto, ParcelMinimalDto, UserDto, WaterAccountDto, WellLinkDisplayDto } from 'src/app/shared/generated/model/models';
import { WaterAccountService } from 'src/app/shared/generated/api/water-account.service';
import { ParcelService } from 'src/app/shared/generated/api/parcel.service';
import { ViewingDetailMenuService } from 'src/app/shared/services/viewing-detail-menu.service';
import { CustomAttributeService } from 'src/app/shared/generated/api/custom-attribute.service';
import { ParcelMapComponent } from '../../shared/components/parcel-map/parcel-map.component';
import { FieldDefinitionComponent } from '../../shared/components/field-definition/field-definition.component';
import { AlertDisplayComponent } from '../../shared/components/alert-display/alert-display.component';
import { NgIf, NgFor, AsyncPipe, DatePipe, KeyValuePipe } from '@angular/common';
import { PageHeaderComponent } from 'src/app/shared/components/page-header/page-header.component';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import { KeyValuePairListComponent } from 'src/app/shared/components/key-value-pair-list/key-value-pair-list.component';
import { KeyValuePairComponent } from 'src/app/shared/components/key-value-pair/key-value-pair.component';

@Component({
  selector: 'water-account-detail',
  templateUrl: './water-account-detail.component.html',
  styleUrls: ['./water-account-detail.component.scss'],
  standalone: true,
  imports: [NgIf, PageHeaderComponent, RouterLink, IconComponent, AlertDisplayComponent, FieldDefinitionComponent, NgFor, ParcelMapComponent, KeyValuePairListComponent, KeyValuePairComponent, AsyncPipe, DatePipe, KeyValuePipe]
})
export class WaterAccountDetailComponent implements OnInit, OnDestroy {
  private selectedGeography$: Subscription = Subscription.EMPTY;
  private currentUser: UserDto;
  public geographyID: number;
  public waterAccount: WaterAccountDto;
  public parcels: ParcelMinimalDto[];
  public wells: WellLinkDisplayDto[];
  public waterAccountCustomAttributes$: Observable<EntityCustomAttributesDto>;

  public waterAccountsUrl: string = '/manage/water-accounts';
  public usersUrl: string = '/manage/users/';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private waterAccountService: WaterAccountService,
    private parcelService: ParcelService,
    private authenticationService: AuthenticationService,
    private selectedGeographyService: SelectedGeographyService,
    private modalService: ModalService,
    private viewContainerRef: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    private viewingMenuDetailService: ViewingDetailMenuService,
    private customAttributeService: CustomAttributeService
  ) {
  }

  ngOnInit() {
    this.selectedGeography$ = this.selectedGeographyService.curentUserSelectedGeographyObservable.subscribe(geography => {
      this.geographyID = geography.GeographyID;
      this.getDataForGeographyID(this.geographyID);
    });
  }

  getDataForGeographyID(geographyID: number) {
    this.authenticationService.getCurrentUser().subscribe(currentUser => {
      this.currentUser = currentUser;
      const id = parseInt(this.route.snapshot.paramMap.get(routeParams.waterAccountID));
      if (id) {
        forkJoin([
          this.waterAccountService.waterAccountsWaterAccountIDGet(id),
          this.parcelService.waterAccountsWaterAccountIDParcelMinimalsGet(id),
          this.waterAccountService.waterAccountsWaterAccountIDWellsGet(id)
        ]).subscribe({
          next: ([waterAccount, parcels, wells]) => {
            this.waterAccount = waterAccount;
            this.viewingMenuDetailService.loadedWaterAccount(waterAccount);
            this.parcels = parcels;
            this.wells = wells;
          },
          error: error => {
            if (error.status === 404) {
              this.router.navigate(['/']).then(() => {
                this.alertService.pushAlert(new Alert('The requested water account could not be found.', AlertContext.Info));
              });
            }
          }
        });

        this.waterAccountCustomAttributes$ = this.customAttributeService.customAttributesWaterAccountsWaterAccountIDGet(id);
      }
    });
  }

  ngOnDestroy() {
    this.cdr.detach();
    this.selectedGeography$.unsubscribe();
    this.viewingMenuDetailService.unLoadedWaterAccount();
  }

  public canViewLandownerDashboard(): boolean {
    return this.waterAccount.Users.map(x => x.UserID).includes(this.currentUser.UserID) || this.authenticationService.hasFlag(this.currentUser, FlagEnum.HasManagerDashboard);
  }

  public canUpdateWaterAccount(): boolean {
    const hasPermission = this.authenticationService.hasOverallPermission(this.currentUser, PermissionEnum.WaterAccountRights, RightsEnum.Update, this.geographyID);
    return hasPermission;
  }

  public getSelectedParcelIDs(): Array<number> {
    return this.parcels !== undefined ? this.parcels.map(p => p.ParcelID) : [];
  }

  public currentUserIsAnAdministrator(): boolean {
    return this.authenticationService.isUserAnAdministrator(this.currentUser);
  }

  openUpdateInfoModal(): void {
    this.modalService.open(UpdateWaterAccountInfoComponent, this.viewContainerRef, { ModalSize: ModalSizeEnum.Medium, ModalTheme: ModalThemeEnum.Light }, { WaterAccountID: this.waterAccount.WaterAccountID, GeographyID: this.waterAccount.Geography.GeographyID } as WaterAccountContext)
      .instance.result.then(result => {
        if (result) {
          this.waterAccount = result;
        }
      });

  }

  openMergeModal(): void {
    this.modalService.open(MergeWaterAccountsComponent, this.viewContainerRef, { ModalSize: ModalSizeEnum.Large, ModalTheme: ModalThemeEnum.Light }, { WaterAccountID: this.waterAccount.WaterAccountID, GeographyID: this.waterAccount.Geography.GeographyID } as WaterAccountContext)
      .instance.result.then(result => {
        // todo: do something with the result if you need to
        console.log(result);
      });
  }

  openUpdateParcelsModal(): void {
    this.modalService.open(UpdateParcelsComponent, this.viewContainerRef, { ModalSize: ModalSizeEnum.ExtraLarge, ModalTheme: ModalThemeEnum.Light }, { WaterAccountID: this.waterAccount.WaterAccountID, GeographyID: this.waterAccount.Geography.GeographyID } as WaterAccountContext)
      .instance.result.then(result => {
        if (result) {
          this.waterAccount = result;
          this.parcels = [...result.Parcels];
        }
      });
  }

  openDeleteModal(): void {
    this.modalService.open(DeleteWaterAccountComponent, this.viewContainerRef, { ModalSize: ModalSizeEnum.Medium, ModalTheme: ModalThemeEnum.Light }, { WaterAccountID: this.waterAccount.WaterAccountID, GeographyID: this.waterAccount.Geography.GeographyID } as WaterAccountContext)
      .instance.result.then(result => {
        if (result) {
          this.router.navigate([`../`], { relativeTo: this.route }).then(x => {
            this.alertService.pushAlert(new Alert('Deleted water account successfully.', AlertContext.Success));
          });
        }
      });
  }
}
