<page-header
    pageTitle="Update Irrigated Parcels"
    [customRichTextTypeID]="customRichTextTypeID"></page-header>

<div class="page-body">
    <app-alert-display></app-alert-display>

    <well-irrigated-parcels-edit-map
        *ngIf="wellIrrigatedParcels$ | async"
        [wellIrrigatedParcels]="wellIrrigatedParcels"
        [geographyID]="geographyID"
        [wellLatLng]="wellLatLng"
        (selectionChanged)="onSelectionChanged($event)"></well-irrigated-parcels-edit-map>

    <div class="footer">
        <button class="btn btn-primary" (click)="save()" [disabled]="isLoadingSubmit">
            Update Well
        </button>
        <a class="btn btn-primary-outline" routerLink="..">Cancel</a>
    </div>
</div>
