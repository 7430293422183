<div *ngIf="meter$ | async as meter">
    <div class="modal-header">
        <h3>
            <icon icon="ActivityCenter"></icon>
            Update Meter
        </h3>
        <button type="button" class="close" (click)="close()">
            <i class="fa fa-times-circle"></i>
        </button>
    </div>

    <div class="modal-body">
        <app-alert-display></app-alert-display>
        <form
            action=""
            class="form"
            [formGroup]="formGroup"
            *ngIf="meterStatuses$ | async as meterStatuses">
            <div class="mb-3">
                <form-field
                    [formControl]="formGroup.controls.SerialNumber"
                    fieldLabel="Serial Number"
                    [type]="FormFieldType.Text"
                    required
                    [fieldDefinitionName]="'SerialNumber'"></form-field>
            </div>
            <div class="mb-3">
                <form-field
                    [formControl]="formGroup.controls.MeterStatusID"
                    fieldLabel="Meter Status"
                    [type]="FormFieldType.Select"
                    [formInputOptions]="meterStatusesSelectOptions"
                    required></form-field>
            </div>
            <div class="mb-3">
                <form-field
                    [formControl]="formGroup.controls.DeviceName"
                    fieldLabel="Device Name"
                    [type]="FormFieldType.Text"></form-field>
            </div>
            <div class="mb-3">
                <form-field
                    [formControl]="formGroup.controls.Make"
                    fieldLabel="Make"
                    [type]="FormFieldType.Text"></form-field>
            </div>
            <div class="mb-3">
                <form-field
                    [formControl]="formGroup.controls.ModelNumber"
                    fieldLabel="Model Number"
                    [type]="FormFieldType.Text"></form-field>
            </div>
        </form>
        <div class="copy copy-3 note-color p-2" *ngIf="meter$ | async as meter">
            <p>
                Are you sure you want to update the info for meter
                <strong>{{ meter.SerialNumber }}</strong>
                ?
            </p>
        </div>
    </div>

    <div class="modal-footer">
        <button
            class="btn btn-primary"
            (click)="save()"
            [disabled]="!formGroup.valid || isLoadingSubmit">
            Update Meter
        </button>
        <button class="btn btn-primary-outline" (click)="close()">Cancel</button>
    </div>
</div>
