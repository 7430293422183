<ng-container *ngIf="allocationPlans$ | async as allocationPlans">
    <ng-container *ngIf="zoneGroup$ | async as zoneGroup">
        <page-header
            *ngIf="geography"
            [pageTitle]="'#' + waterAccount.WaterAccountNumber"
            [templateTitleAppend]="templateTitleAppend"
            [customRichTextTypeID]="customRichTextTypeID"
            [customRichTextTypeGeographyID]="geography.GeographyID"
            icon="WaterAccounts"
            preTitle="Allocation Plans">
            <ng-template #templateTitleAppend>
                <span
                    class="water-account-name"
                    *ngIf="waterAccount.WaterAccountName?.length > 0"
                    title="{{ waterAccount.WaterAccountName }}">
                    {{ waterAccount.WaterAccountName }}
                </span>
                <name-tag
                    [name]="geography.GeographyName"
                    [routerLink]="['/geographies', geography.GeographyName.toLowerCase()]"
                    class="geo-tag"
                    title="{{ geography.GeographyDisplayName }}"></name-tag>
                <name-tag
                    [name]="accountZone?.ZoneName"
                    [routerLink]="[
                        '/geographies',
                        geography.GeographyName.toLowerCase(),
                        'allocation-plans'
                    ]"
                    [color]="accountZone?.ZoneColor"
                    class="zone-tag"
                    title="{{ zoneGroup?.ZoneGroupName }}: {{ accountZone?.ZoneName }}"></name-tag>
            </ng-template>
        </page-header>

        <div class="page-body grid-12">
            <app-alert-display></app-alert-display>
            <ng-container>
                <account-zone-card
                    [waterAccount]="waterAccount"
                    [zoneGroup]="zoneGroup"
                    [zoneID]="allocationPlans[0]?.ZoneID"></account-zone-card>
                <allocation-plan-select
                    [geographyID]="geography.GeographyID"
                    [allocationPlans]="allocationPlans"
                    [showZoneToggle]="false"></allocation-plan-select>
            </ng-container>
        </div>
    </ng-container>
</ng-container>
