<page-header icon="Review" [customRichTextTypeID]="customRichTextId"></page-header>
<div class="page-body">
    <app-alert-display></app-alert-display>
    <qanat-grid
        *ngIf="wellsToReview$ | async as wells"
        [rowData]="wells"
        [columnDefs]="colDefs"
        downloadFileName="submitted-wells"
        [colIDsToExclude]="['0']"
        [sizeColumnsToFitGrid]="true"></qanat-grid>
</div>
