<page-header icon="Geography-Alt"></page-header>

<div class="page-body grid-12">
    <app-alert-display></app-alert-display>

    <div class="row">
        <div class="g-col-2 flex-end ai-fs">
            <div>
                <button class="btn btn-primary-outline" (click)="triggerMonitorWellsCNRA()">
                    Rerun Monitoring Wells Job
                </button>
            </div>
            <div>
                <button
                    class="btn btn-primary-outline"
                    (click)="refreshGSABoundaries()"
                    [disabled]="isLoadingSubmit">
                    <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
                    <i *ngIf="!isLoadingSubmit" class="fas fa-sync-alt"></i>
                    Refresh GSA Boundaries
                </button>
                <ng-container *ngIf="geographyBoundaries$ | async as geographyBoundaries">
                    <div class="mt-2 text-muted flex-end" *ngIf="lastUpdatedGSABoundariesDate">
                        <small>
                            <em>
                                Last Updated: {{ lastUpdatedGSABoundariesDate | date: 'short' }}
                            </em>
                        </small>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <qanat-grid
        *ngIf="geographies$ | async as geographies"
        height="450px"
        [rowData]="geographies"
        [columnDefs]="columnDefs"
        downloadFileName="geographies"
        [colIDsToExclude]="colIDsToExclude"></qanat-grid>
</div>
