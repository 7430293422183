/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { FormControl, FormControlOptions, FormControlState, Validators } from "@angular/forms";
export class WellRegistrySupportingInfoDto { 
    WellDepth?: number;
    CasingDiameter?: number;
    TopOfPerforations?: number;
    BottomOfPerforations?: number;
    ManufacturerOfWaterMeter?: string;
    SerialNumberOfWaterMeter?: string;
    ElectricMeterNumber?: string;
    PumpDischargeDiameter?: number;
    MotorHorsePower?: number;
    FuelTypeID?: number;
    FuelOther?: string;
    MaximumFlow?: number;
    IsEstimatedMax?: boolean;
    TypicalPumpFlow?: number;
    IsEstimatedTypical?: boolean;
    PumpTestBy?: string;
    PumpTestDatePerformed?: string;
    PumpManufacturer?: string;
    PumpYield?: number;
    PumpStaticLevel?: number;
    PumpingLevel?: number;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export interface WellRegistrySupportingInfoDtoForm { 
    WellDepth?: FormControl<number>;
    CasingDiameter?: FormControl<number>;
    TopOfPerforations?: FormControl<number>;
    BottomOfPerforations?: FormControl<number>;
    ManufacturerOfWaterMeter?: FormControl<string>;
    SerialNumberOfWaterMeter?: FormControl<string>;
    ElectricMeterNumber?: FormControl<string>;
    PumpDischargeDiameter?: FormControl<number>;
    MotorHorsePower?: FormControl<number>;
    FuelTypeID?: FormControl<number>;
    FuelOther?: FormControl<string>;
    MaximumFlow?: FormControl<number>;
    IsEstimatedMax?: FormControl<boolean>;
    TypicalPumpFlow?: FormControl<number>;
    IsEstimatedTypical?: FormControl<boolean>;
    PumpTestBy?: FormControl<string>;
    PumpTestDatePerformed?: FormControl<string>;
    PumpManufacturer?: FormControl<string>;
    PumpYield?: FormControl<number>;
    PumpStaticLevel?: FormControl<number>;
    PumpingLevel?: FormControl<number>;
}

export class WellRegistrySupportingInfoDtoFormControls { 
    public static WellDepth = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static CasingDiameter = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static TopOfPerforations = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static BottomOfPerforations = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static ManufacturerOfWaterMeter = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static SerialNumberOfWaterMeter = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static ElectricMeterNumber = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static PumpDischargeDiameter = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static MotorHorsePower = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static FuelTypeID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static FuelOther = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static MaximumFlow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static IsEstimatedMax = (value: FormControlState<boolean> | boolean = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<boolean>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static TypicalPumpFlow = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static IsEstimatedTypical = (value: FormControlState<boolean> | boolean = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<boolean>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static PumpTestBy = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static PumpTestDatePerformed = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static PumpManufacturer = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static PumpYield = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static PumpStaticLevel = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static PumpingLevel = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
}
