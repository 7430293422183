/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { BeginWellRegistryRequestDto } from '../model/begin-well-registry-request-dto';
import { ConfirmWellRegistrationLocationDto } from '../model/confirm-well-registration-location-dto';
import { FuelTypeSimpleDto } from '../model/fuel-type-simple-dto';
import { ParcelDisplayDto } from '../model/parcel-display-dto';
import { SubmittedWellRegistrationListItemDto } from '../model/submitted-well-registration-list-item-dto';
import { WellRegistrationBasicInfoFormDto } from '../model/well-registration-basic-info-form-dto';
import { WellRegistrationContactsUpsertDto } from '../model/well-registration-contacts-upsert-dto';
import { WellRegistrationDetailedDto } from '../model/well-registration-detailed-dto';
import { WellRegistrationFileResourceDto } from '../model/well-registration-file-resource-dto';
import { WellRegistrationGridRowDto } from '../model/well-registration-grid-row-dto';
import { WellRegistrationIrrigatedParcelsRequestDto } from '../model/well-registration-irrigated-parcels-request-dto';
import { WellRegistrationIrrigatedParcelsResponseDto } from '../model/well-registration-irrigated-parcels-response-dto';
import { WellRegistrationLocationDto } from '../model/well-registration-location-dto';
import { WellRegistrationMinimalDto } from '../model/well-registration-minimal-dto';
import { WellRegistrationWaterUseTypeSimpleDto } from '../model/well-registration-water-use-type-simple-dto';
import { WellRegistrySupportingInfoDto } from '../model/well-registry-supporting-info-dto';
import { WellRegistryWorkflowProgressDto } from '../model/well-registry-workflow-progress-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class WellRegistrationService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDWellRegistrationsGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<WellRegistrationMinimalDto>>;
    public geographiesGeographyIDWellRegistrationsGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WellRegistrationMinimalDto>>>;
    public geographiesGeographyIDWellRegistrationsGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WellRegistrationMinimalDto>>>;
    public geographiesGeographyIDWellRegistrationsGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDWellRegistrationsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WellRegistrationMinimalDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/well-registrations`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDWellRegistrationsGridRowsGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<WellRegistrationGridRowDto>>;
    public geographiesGeographyIDWellRegistrationsGridRowsGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WellRegistrationGridRowDto>>>;
    public geographiesGeographyIDWellRegistrationsGridRowsGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WellRegistrationGridRowDto>>>;
    public geographiesGeographyIDWellRegistrationsGridRowsGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDWellRegistrationsGridRowsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WellRegistrationGridRowDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/well-registrations/grid-rows`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param beginWellRegistryRequestDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDWellRegistrationsPost(geographyID: number, beginWellRegistryRequestDto?: BeginWellRegistryRequestDto, observe?: 'body', reportProgress?: boolean): Observable<WellRegistrationMinimalDto>;
    public geographiesGeographyIDWellRegistrationsPost(geographyID: number, beginWellRegistryRequestDto?: BeginWellRegistryRequestDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WellRegistrationMinimalDto>>;
    public geographiesGeographyIDWellRegistrationsPost(geographyID: number, beginWellRegistryRequestDto?: BeginWellRegistryRequestDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WellRegistrationMinimalDto>>;
    public geographiesGeographyIDWellRegistrationsPost(geographyID: number, beginWellRegistryRequestDto?: BeginWellRegistryRequestDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDWellRegistrationsPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<WellRegistrationMinimalDto>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/well-registrations`,
            beginWellRegistryRequestDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDWellRegistrationsSubmittedGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<SubmittedWellRegistrationListItemDto>>;
    public geographiesGeographyIDWellRegistrationsSubmittedGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<SubmittedWellRegistrationListItemDto>>>;
    public geographiesGeographyIDWellRegistrationsSubmittedGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<SubmittedWellRegistrationListItemDto>>>;
    public geographiesGeographyIDWellRegistrationsSubmittedGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDWellRegistrationsSubmittedGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<SubmittedWellRegistrationListItemDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/well-registrations/submitted`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellRegistrationsPumpFuelTypesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<FuelTypeSimpleDto>>;
    public wellRegistrationsPumpFuelTypesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FuelTypeSimpleDto>>>;
    public wellRegistrationsPumpFuelTypesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FuelTypeSimpleDto>>>;
    public wellRegistrationsPumpFuelTypesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<FuelTypeSimpleDto>>(`${this.basePath}/well-registrations/pump-fuel-types`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellRegistrationsWaterUseTypesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<WellRegistrationWaterUseTypeSimpleDto>>;
    public wellRegistrationsWaterUseTypesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WellRegistrationWaterUseTypeSimpleDto>>>;
    public wellRegistrationsWaterUseTypesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WellRegistrationWaterUseTypeSimpleDto>>>;
    public wellRegistrationsWaterUseTypesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WellRegistrationWaterUseTypeSimpleDto>>(`${this.basePath}/well-registrations/water-use-types`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellRegistrationID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellRegistrationsWellRegistrationIDApprovePost(wellRegistrationID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wellRegistrationsWellRegistrationIDApprovePost(wellRegistrationID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wellRegistrationsWellRegistrationIDApprovePost(wellRegistrationID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wellRegistrationsWellRegistrationIDApprovePost(wellRegistrationID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellRegistrationID === null || wellRegistrationID === undefined) {
            throw new Error('Required parameter wellRegistrationID was null or undefined when calling wellRegistrationsWellRegistrationIDApprovePost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/well-registrations/${encodeURIComponent(String(wellRegistrationID))}/approve`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellRegistrationID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellRegistrationsWellRegistrationIDBasicInfoGet(wellRegistrationID: number, observe?: 'body', reportProgress?: boolean): Observable<WellRegistrationBasicInfoFormDto>;
    public wellRegistrationsWellRegistrationIDBasicInfoGet(wellRegistrationID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WellRegistrationBasicInfoFormDto>>;
    public wellRegistrationsWellRegistrationIDBasicInfoGet(wellRegistrationID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WellRegistrationBasicInfoFormDto>>;
    public wellRegistrationsWellRegistrationIDBasicInfoGet(wellRegistrationID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellRegistrationID === null || wellRegistrationID === undefined) {
            throw new Error('Required parameter wellRegistrationID was null or undefined when calling wellRegistrationsWellRegistrationIDBasicInfoGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<WellRegistrationBasicInfoFormDto>(`${this.basePath}/well-registrations/${encodeURIComponent(String(wellRegistrationID))}/basic-info`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellRegistrationID 
     * @param wellRegistrationBasicInfoFormDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellRegistrationsWellRegistrationIDBasicInfoPut(wellRegistrationID: number, wellRegistrationBasicInfoFormDto?: WellRegistrationBasicInfoFormDto, observe?: 'body', reportProgress?: boolean): Observable<WellRegistrationBasicInfoFormDto>;
    public wellRegistrationsWellRegistrationIDBasicInfoPut(wellRegistrationID: number, wellRegistrationBasicInfoFormDto?: WellRegistrationBasicInfoFormDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WellRegistrationBasicInfoFormDto>>;
    public wellRegistrationsWellRegistrationIDBasicInfoPut(wellRegistrationID: number, wellRegistrationBasicInfoFormDto?: WellRegistrationBasicInfoFormDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WellRegistrationBasicInfoFormDto>>;
    public wellRegistrationsWellRegistrationIDBasicInfoPut(wellRegistrationID: number, wellRegistrationBasicInfoFormDto?: WellRegistrationBasicInfoFormDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellRegistrationID === null || wellRegistrationID === undefined) {
            throw new Error('Required parameter wellRegistrationID was null or undefined when calling wellRegistrationsWellRegistrationIDBasicInfoPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<WellRegistrationBasicInfoFormDto>(`${this.basePath}/well-registrations/${encodeURIComponent(String(wellRegistrationID))}/basic-info`,
            wellRegistrationBasicInfoFormDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellRegistrationID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellRegistrationsWellRegistrationIDContactsGet(wellRegistrationID: number, observe?: 'body', reportProgress?: boolean): Observable<WellRegistrationContactsUpsertDto>;
    public wellRegistrationsWellRegistrationIDContactsGet(wellRegistrationID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WellRegistrationContactsUpsertDto>>;
    public wellRegistrationsWellRegistrationIDContactsGet(wellRegistrationID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WellRegistrationContactsUpsertDto>>;
    public wellRegistrationsWellRegistrationIDContactsGet(wellRegistrationID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellRegistrationID === null || wellRegistrationID === undefined) {
            throw new Error('Required parameter wellRegistrationID was null or undefined when calling wellRegistrationsWellRegistrationIDContactsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<WellRegistrationContactsUpsertDto>(`${this.basePath}/well-registrations/${encodeURIComponent(String(wellRegistrationID))}/contacts`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellRegistrationID 
     * @param wellRegistrationContactsUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellRegistrationsWellRegistrationIDContactsPost(wellRegistrationID: number, wellRegistrationContactsUpsertDto?: WellRegistrationContactsUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public wellRegistrationsWellRegistrationIDContactsPost(wellRegistrationID: number, wellRegistrationContactsUpsertDto?: WellRegistrationContactsUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public wellRegistrationsWellRegistrationIDContactsPost(wellRegistrationID: number, wellRegistrationContactsUpsertDto?: WellRegistrationContactsUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public wellRegistrationsWellRegistrationIDContactsPost(wellRegistrationID: number, wellRegistrationContactsUpsertDto?: WellRegistrationContactsUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellRegistrationID === null || wellRegistrationID === undefined) {
            throw new Error('Required parameter wellRegistrationID was null or undefined when calling wellRegistrationsWellRegistrationIDContactsPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<boolean>(`${this.basePath}/well-registrations/${encodeURIComponent(String(wellRegistrationID))}/contacts`,
            wellRegistrationContactsUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellRegistrationID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellRegistrationsWellRegistrationIDDelete(wellRegistrationID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wellRegistrationsWellRegistrationIDDelete(wellRegistrationID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wellRegistrationsWellRegistrationIDDelete(wellRegistrationID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wellRegistrationsWellRegistrationIDDelete(wellRegistrationID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellRegistrationID === null || wellRegistrationID === undefined) {
            throw new Error('Required parameter wellRegistrationID was null or undefined when calling wellRegistrationsWellRegistrationIDDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/well-registrations/${encodeURIComponent(String(wellRegistrationID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellRegistrationID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellRegistrationsWellRegistrationIDFileResourcesGet(wellRegistrationID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<WellRegistrationFileResourceDto>>;
    public wellRegistrationsWellRegistrationIDFileResourcesGet(wellRegistrationID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WellRegistrationFileResourceDto>>>;
    public wellRegistrationsWellRegistrationIDFileResourcesGet(wellRegistrationID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WellRegistrationFileResourceDto>>>;
    public wellRegistrationsWellRegistrationIDFileResourcesGet(wellRegistrationID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellRegistrationID === null || wellRegistrationID === undefined) {
            throw new Error('Required parameter wellRegistrationID was null or undefined when calling wellRegistrationsWellRegistrationIDFileResourcesGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WellRegistrationFileResourceDto>>(`${this.basePath}/well-registrations/${encodeURIComponent(String(wellRegistrationID))}/file-resources`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellRegistrationID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellRegistrationsWellRegistrationIDGet(wellRegistrationID: number, observe?: 'body', reportProgress?: boolean): Observable<WellRegistrationDetailedDto>;
    public wellRegistrationsWellRegistrationIDGet(wellRegistrationID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WellRegistrationDetailedDto>>;
    public wellRegistrationsWellRegistrationIDGet(wellRegistrationID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WellRegistrationDetailedDto>>;
    public wellRegistrationsWellRegistrationIDGet(wellRegistrationID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellRegistrationID === null || wellRegistrationID === undefined) {
            throw new Error('Required parameter wellRegistrationID was null or undefined when calling wellRegistrationsWellRegistrationIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<WellRegistrationDetailedDto>(`${this.basePath}/well-registrations/${encodeURIComponent(String(wellRegistrationID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellRegistrationID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellRegistrationsWellRegistrationIDIrrigatedParcelsGet(wellRegistrationID: number, observe?: 'body', reportProgress?: boolean): Observable<WellRegistrationIrrigatedParcelsResponseDto>;
    public wellRegistrationsWellRegistrationIDIrrigatedParcelsGet(wellRegistrationID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WellRegistrationIrrigatedParcelsResponseDto>>;
    public wellRegistrationsWellRegistrationIDIrrigatedParcelsGet(wellRegistrationID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WellRegistrationIrrigatedParcelsResponseDto>>;
    public wellRegistrationsWellRegistrationIDIrrigatedParcelsGet(wellRegistrationID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellRegistrationID === null || wellRegistrationID === undefined) {
            throw new Error('Required parameter wellRegistrationID was null or undefined when calling wellRegistrationsWellRegistrationIDIrrigatedParcelsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<WellRegistrationIrrigatedParcelsResponseDto>(`${this.basePath}/well-registrations/${encodeURIComponent(String(wellRegistrationID))}/irrigated-parcels`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellRegistrationID 
     * @param wellRegistrationIrrigatedParcelsRequestDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellRegistrationsWellRegistrationIDIrrigatedParcelsPut(wellRegistrationID: number, wellRegistrationIrrigatedParcelsRequestDto?: WellRegistrationIrrigatedParcelsRequestDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wellRegistrationsWellRegistrationIDIrrigatedParcelsPut(wellRegistrationID: number, wellRegistrationIrrigatedParcelsRequestDto?: WellRegistrationIrrigatedParcelsRequestDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wellRegistrationsWellRegistrationIDIrrigatedParcelsPut(wellRegistrationID: number, wellRegistrationIrrigatedParcelsRequestDto?: WellRegistrationIrrigatedParcelsRequestDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wellRegistrationsWellRegistrationIDIrrigatedParcelsPut(wellRegistrationID: number, wellRegistrationIrrigatedParcelsRequestDto?: WellRegistrationIrrigatedParcelsRequestDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellRegistrationID === null || wellRegistrationID === undefined) {
            throw new Error('Required parameter wellRegistrationID was null or undefined when calling wellRegistrationsWellRegistrationIDIrrigatedParcelsPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/well-registrations/${encodeURIComponent(String(wellRegistrationID))}/irrigated-parcels`,
            wellRegistrationIrrigatedParcelsRequestDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellRegistrationID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellRegistrationsWellRegistrationIDLocationConfirmGet(wellRegistrationID: number, observe?: 'body', reportProgress?: boolean): Observable<ConfirmWellRegistrationLocationDto>;
    public wellRegistrationsWellRegistrationIDLocationConfirmGet(wellRegistrationID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ConfirmWellRegistrationLocationDto>>;
    public wellRegistrationsWellRegistrationIDLocationConfirmGet(wellRegistrationID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ConfirmWellRegistrationLocationDto>>;
    public wellRegistrationsWellRegistrationIDLocationConfirmGet(wellRegistrationID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellRegistrationID === null || wellRegistrationID === undefined) {
            throw new Error('Required parameter wellRegistrationID was null or undefined when calling wellRegistrationsWellRegistrationIDLocationConfirmGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ConfirmWellRegistrationLocationDto>(`${this.basePath}/well-registrations/${encodeURIComponent(String(wellRegistrationID))}/location/confirm`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellRegistrationID 
     * @param confirmWellRegistrationLocationDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellRegistrationsWellRegistrationIDLocationConfirmPut(wellRegistrationID: number, confirmWellRegistrationLocationDto?: ConfirmWellRegistrationLocationDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wellRegistrationsWellRegistrationIDLocationConfirmPut(wellRegistrationID: number, confirmWellRegistrationLocationDto?: ConfirmWellRegistrationLocationDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wellRegistrationsWellRegistrationIDLocationConfirmPut(wellRegistrationID: number, confirmWellRegistrationLocationDto?: ConfirmWellRegistrationLocationDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wellRegistrationsWellRegistrationIDLocationConfirmPut(wellRegistrationID: number, confirmWellRegistrationLocationDto?: ConfirmWellRegistrationLocationDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellRegistrationID === null || wellRegistrationID === undefined) {
            throw new Error('Required parameter wellRegistrationID was null or undefined when calling wellRegistrationsWellRegistrationIDLocationConfirmPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/well-registrations/${encodeURIComponent(String(wellRegistrationID))}/location/confirm`,
            confirmWellRegistrationLocationDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellRegistrationID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellRegistrationsWellRegistrationIDLocationGet(wellRegistrationID: number, observe?: 'body', reportProgress?: boolean): Observable<WellRegistrationLocationDto>;
    public wellRegistrationsWellRegistrationIDLocationGet(wellRegistrationID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WellRegistrationLocationDto>>;
    public wellRegistrationsWellRegistrationIDLocationGet(wellRegistrationID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WellRegistrationLocationDto>>;
    public wellRegistrationsWellRegistrationIDLocationGet(wellRegistrationID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellRegistrationID === null || wellRegistrationID === undefined) {
            throw new Error('Required parameter wellRegistrationID was null or undefined when calling wellRegistrationsWellRegistrationIDLocationGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<WellRegistrationLocationDto>(`${this.basePath}/well-registrations/${encodeURIComponent(String(wellRegistrationID))}/location`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellRegistrationID 
     * @param wellRegistrationLocationDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellRegistrationsWellRegistrationIDLocationPut(wellRegistrationID: number, wellRegistrationLocationDto?: WellRegistrationLocationDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wellRegistrationsWellRegistrationIDLocationPut(wellRegistrationID: number, wellRegistrationLocationDto?: WellRegistrationLocationDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wellRegistrationsWellRegistrationIDLocationPut(wellRegistrationID: number, wellRegistrationLocationDto?: WellRegistrationLocationDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wellRegistrationsWellRegistrationIDLocationPut(wellRegistrationID: number, wellRegistrationLocationDto?: WellRegistrationLocationDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellRegistrationID === null || wellRegistrationID === undefined) {
            throw new Error('Required parameter wellRegistrationID was null or undefined when calling wellRegistrationsWellRegistrationIDLocationPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/well-registrations/${encodeURIComponent(String(wellRegistrationID))}/location`,
            wellRegistrationLocationDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellRegistrationID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellRegistrationsWellRegistrationIDParcelGet(wellRegistrationID: number, observe?: 'body', reportProgress?: boolean): Observable<ParcelDisplayDto>;
    public wellRegistrationsWellRegistrationIDParcelGet(wellRegistrationID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ParcelDisplayDto>>;
    public wellRegistrationsWellRegistrationIDParcelGet(wellRegistrationID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ParcelDisplayDto>>;
    public wellRegistrationsWellRegistrationIDParcelGet(wellRegistrationID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellRegistrationID === null || wellRegistrationID === undefined) {
            throw new Error('Required parameter wellRegistrationID was null or undefined when calling wellRegistrationsWellRegistrationIDParcelGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ParcelDisplayDto>(`${this.basePath}/well-registrations/${encodeURIComponent(String(wellRegistrationID))}/parcel`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellRegistrationID 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellRegistrationsWellRegistrationIDParcelPut(wellRegistrationID: number, body?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wellRegistrationsWellRegistrationIDParcelPut(wellRegistrationID: number, body?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wellRegistrationsWellRegistrationIDParcelPut(wellRegistrationID: number, body?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wellRegistrationsWellRegistrationIDParcelPut(wellRegistrationID: number, body?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellRegistrationID === null || wellRegistrationID === undefined) {
            throw new Error('Required parameter wellRegistrationID was null or undefined when calling wellRegistrationsWellRegistrationIDParcelPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/well-registrations/${encodeURIComponent(String(wellRegistrationID))}/parcel`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellRegistrationID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellRegistrationsWellRegistrationIDProgressGet(wellRegistrationID: number, observe?: 'body', reportProgress?: boolean): Observable<WellRegistryWorkflowProgressDto>;
    public wellRegistrationsWellRegistrationIDProgressGet(wellRegistrationID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WellRegistryWorkflowProgressDto>>;
    public wellRegistrationsWellRegistrationIDProgressGet(wellRegistrationID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WellRegistryWorkflowProgressDto>>;
    public wellRegistrationsWellRegistrationIDProgressGet(wellRegistrationID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellRegistrationID === null || wellRegistrationID === undefined) {
            throw new Error('Required parameter wellRegistrationID was null or undefined when calling wellRegistrationsWellRegistrationIDProgressGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<WellRegistryWorkflowProgressDto>(`${this.basePath}/well-registrations/${encodeURIComponent(String(wellRegistrationID))}/progress`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellRegistrationID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellRegistrationsWellRegistrationIDReturnPost(wellRegistrationID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wellRegistrationsWellRegistrationIDReturnPost(wellRegistrationID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wellRegistrationsWellRegistrationIDReturnPost(wellRegistrationID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wellRegistrationsWellRegistrationIDReturnPost(wellRegistrationID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellRegistrationID === null || wellRegistrationID === undefined) {
            throw new Error('Required parameter wellRegistrationID was null or undefined when calling wellRegistrationsWellRegistrationIDReturnPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/well-registrations/${encodeURIComponent(String(wellRegistrationID))}/return`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellRegistrationID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellRegistrationsWellRegistrationIDSubmitWellPost(wellRegistrationID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public wellRegistrationsWellRegistrationIDSubmitWellPost(wellRegistrationID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public wellRegistrationsWellRegistrationIDSubmitWellPost(wellRegistrationID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public wellRegistrationsWellRegistrationIDSubmitWellPost(wellRegistrationID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellRegistrationID === null || wellRegistrationID === undefined) {
            throw new Error('Required parameter wellRegistrationID was null or undefined when calling wellRegistrationsWellRegistrationIDSubmitWellPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/well-registrations/${encodeURIComponent(String(wellRegistrationID))}/submit-well`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellRegistrationID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellRegistrationsWellRegistrationIDSupportingInfoGet(wellRegistrationID: number, observe?: 'body', reportProgress?: boolean): Observable<WellRegistrySupportingInfoDto>;
    public wellRegistrationsWellRegistrationIDSupportingInfoGet(wellRegistrationID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WellRegistrySupportingInfoDto>>;
    public wellRegistrationsWellRegistrationIDSupportingInfoGet(wellRegistrationID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WellRegistrySupportingInfoDto>>;
    public wellRegistrationsWellRegistrationIDSupportingInfoGet(wellRegistrationID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellRegistrationID === null || wellRegistrationID === undefined) {
            throw new Error('Required parameter wellRegistrationID was null or undefined when calling wellRegistrationsWellRegistrationIDSupportingInfoGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<WellRegistrySupportingInfoDto>(`${this.basePath}/well-registrations/${encodeURIComponent(String(wellRegistrationID))}/supporting-info`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param wellRegistrationID 
     * @param wellRegistrySupportingInfoDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public wellRegistrationsWellRegistrationIDSupportingInfoPut(wellRegistrationID: number, wellRegistrySupportingInfoDto?: WellRegistrySupportingInfoDto, observe?: 'body', reportProgress?: boolean): Observable<WellRegistrySupportingInfoDto>;
    public wellRegistrationsWellRegistrationIDSupportingInfoPut(wellRegistrationID: number, wellRegistrySupportingInfoDto?: WellRegistrySupportingInfoDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WellRegistrySupportingInfoDto>>;
    public wellRegistrationsWellRegistrationIDSupportingInfoPut(wellRegistrationID: number, wellRegistrySupportingInfoDto?: WellRegistrySupportingInfoDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WellRegistrySupportingInfoDto>>;
    public wellRegistrationsWellRegistrationIDSupportingInfoPut(wellRegistrationID: number, wellRegistrySupportingInfoDto?: WellRegistrySupportingInfoDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (wellRegistrationID === null || wellRegistrationID === undefined) {
            throw new Error('Required parameter wellRegistrationID was null or undefined when calling wellRegistrationsWellRegistrationIDSupportingInfoPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<WellRegistrySupportingInfoDto>(`${this.basePath}/well-registrations/${encodeURIComponent(String(wellRegistrationID))}/supporting-info`,
            wellRegistrySupportingInfoDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}
