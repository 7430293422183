import { Component, Input, ViewContainerRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParcelWaterAccountDashboardDto } from 'src/app/shared/generated/model/models';
import { RouterLink } from '@angular/router';
import { WaterAccountTitleComponent } from '../water-account/water-account-title/water-account-title.component';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'parcel-small-card',
  standalone: true,
  templateUrl: './parcel-small-card.component.html',
  styleUrls: ['./parcel-small-card.component.scss'],
  imports: [CommonModule, WaterAccountTitleComponent, IconComponent, RouterLink]
})
export class ParcelSmallCardComponent {
  private _isHighlighted: boolean = false;

  @Input() set isHighlighted(value: boolean) {
    this._isHighlighted = value;
    if (this._isHighlighted) {
      this.vcr.element.nativeElement.scrollIntoView({ block: 'nearest' });
    }
  }
  get isHighlighted() {
    return this._isHighlighted;
  }

  @Input() parcel: ParcelWaterAccountDashboardDto;

  constructor(
    private vcr: ViewContainerRef
  ) { }
}
