<header-nav></header-nav>

<main class="site">
    <div class="site-wrapper">
        <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
        <router-outlet *ngIf="!isIframe"></router-outlet>
    </div>
</main>

<footer-nav></footer-nav>
