export enum GeographyEnum {
  miugsa = 1,
  pajaro = 2,
  rrb = 3,
  yolo = 4,
  demo = 5,
  msgsa = 6,
  etsgsa = 7,
}

// NOTE: This enum does not autmoatically generate, and will need to be manually updated if/when Geographies change
