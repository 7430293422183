<div class="full-width-page">
    <page-header [customRichTextTypeID]="richTextID" icon="Wells"></page-header>
    <water-dashboard-navigation></water-dashboard-navigation>

    <div class="page-body" [loadingSpinner]="{ isLoading: isLoading, loadingHeight: 500 }">
        <water-dashboard-wells
            *ngIf="wells$ | async as wells"
            [wells]="wells"></water-dashboard-wells>
    </div>
</div>
