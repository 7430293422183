<div class="dashboard" *ngIf="currentGeography$ | async as currentSelectedGeography">
    <aside class="sidebar">
        <div class="sidebar-header">
            <div>
                <a [routerLink]="'/configure/' + currentSelectedGeography.GeographyID">
                    <geography-logo [geographyID]="currentSelectedGeography.GeographyID"></geography-logo>
                </a>
            </div>
        </div>

        <div class="sidebar-body sticky-nav">
            <div class="sidebar-body__wrapper">
                <h3 class="sidebar-body__title">
                    <icon icon="Configure"></icon>
                    Configure
                </h3>

                <div class="sidebar-body__switcher">
                    <h4 class="sidebar-body__current">
                        <icon icon="Geography-Alt"></icon>
                        {{ currentSelectedGeography.GeographyName }}
                    </h4>

                    <geography-switcher></geography-switcher>
                </div>
            </div>

            <ul class="sidebar-nav pb-5">
                <li class="sidebar-item">
                    <a [routerLink]="['./']" routerLinkActive="active"
                        [routerLinkActiveOptions]="routerLinkActiveOptions" class="sidebar-link flex">
                        <icon icon="Configure"></icon>
                        <span class="sidebar-link__label">Geography Configuration</span>
                    </a>
                </li>

                <li class="sidebar-item">
                    <a [routerLink]="['setup']" routerLinkActive="active"
                        [routerLinkActiveOptions]="routerLinkActiveOptions" class="sidebar-link flex">
                        <icon icon="Geography"></icon>
                        <span class="sidebar-link__label">Setup</span>
                    </a>
                </li>

                <li class="sidebar-item">
                    <a [routerLink]="['reporting-period']" routerLinkActive="active"
                        [routerLinkActiveOptions]="routerLinkActiveOptions" class="sidebar-link flex">
                        <icon icon="Calendar"></icon>
                        <span class="sidebar-link__label">Reporting Period</span>
                    </a>
                </li>
                <li *ngIf="isWaterSupplyActive()" class="sidebar-item">
                    <a [routerLink]="['water-supply']" routerLinkActive="active"
                        [routerLinkActiveOptions]="routerLinkActiveOptions" class="sidebar-link flex">
                        <icon icon="WaterDropFilled"></icon>
                        <span class="sidebar-link__label">Water Supply</span>
                    </a>
                </li>
                <li class="sidebar-item">
                    <a [routerLink]="['allocation-plans']" routerLinkActive="active"
                        [routerLinkActiveOptions]="routerLinkActiveOptions" class="sidebar-link flex">
                        <icon icon="Allocations"></icon>
                        <span class="sidebar-link__label">Allocation Plans</span>
                    </a>
                </li>

                <li class="sidebar-item" *ngIf="currentSelectedGeography.GeographyConfiguration.WellRegistryEnabled">
                    <a [routerLink]="['well-registry']" routerLinkActive="active"
                        [routerLinkActiveOptions]="routerLinkActiveOptions" class="sidebar-link flex">
                        <icon icon="Wells"></icon>
                        Well Registry
                    </a>
                </li>

                <li class="sidebar-item">
                    <a [routerLink]="['zones']" routerLinkActive="active"
                        [routerLinkActiveOptions]="routerLinkActiveOptions" class="sidebar-link flex">
                        <icon icon="Zones"></icon>
                        <span class="sidebar-link__label">Zones</span>
                    </a>
                </li>

                <li class="sidebar-item">
                    <a [routerLink]="['edit-water-managers']" routerLinkActive="active"
                        [routerLinkActiveOptions]="routerLinkActiveOptions" class="sidebar-link flex">
                        <icon icon="Users"></icon>
                        <span class="sidebar-link__label">Water Managers</span>
                    </a>
                </li>

                <li class="sidebar-item">
                    <a [routerLink]="['custom-attributes']" routerLinkActive="active"
                        [routerLinkActiveOptions]="routerLinkActiveOptions" class="sidebar-link flex">
                        <i class="fas fa-pencil-ruler"></i>
                        <span class="sidebar-link__label">Custom Attributes</span>
                    </a>
                </li>

                <li class="sidebar-item">
                    <a [routerLink]="['geospatial-data']" routerLinkActive="active"
                        [routerLinkActiveOptions]="routerLinkActiveOptions" class="sidebar-link flex">
                        <icon icon="DataLayers"></icon>
                        <span class="sidebar-link__label">Geospatial Data Layers</span>
                    </a>
                </li>

                <li class="sidebar-item" *ngIf="currentSelectedGeography.GeographyConfiguration.MetersEnabled">
                    <a [routerLink]="['meter-data']" routerLinkActive="active"
                        [routerLinkActiveOptions]="routerLinkActiveOptions" class="sidebar-link flex">
                        <icon icon="DataLayers"></icon>
                        <span class="sidebar-link__label">Meter Data</span>
                    </a>
                </li>

                <li class="sidebar-item" *ngIf="currentSelectedGeography.GeographyConfiguration.LandingPageEnabled">
                    <a [routerLink]="['landing-page']" routerLinkActive="active"
                        [routerLinkActiveOptions]="routerLinkActiveOptions" class="sidebar-link flex">
                        <icon icon="DataLayers"></icon>
                        <span class="sidebar-link__label">Landing Page</span>
                    </a>
                </li>
            </ul>
        </div>
    </aside>

    <main class="main">
        <router-outlet #manageOutlet="outlet"></router-outlet>

        <div *ngIf="!manageOutlet.isActivated">
            <page-header pageTitle="Geography Configuration" icon="Configure"></page-header>

            <div class="page-body">
                <app-alert-display></app-alert-display>
                <div class="configure-options grid-9">
                    <div class="g-col-3">
                        <configure-card title="Setup" routerLinkValue="setup"
                            [customRichTextTypeID]="setupRichTextTypeID" [hideToggle]="true"></configure-card>
                    </div>

                    <div class="g-col-3">
                        <configure-card title="Reporting Period" routerLinkValue="reporting-period"
                            [customRichTextTypeID]="reportingPeriodRichTextTypeID"
                            [toggleChecked]="true"></configure-card>
                    </div>

                    <div class="g-col-3">
                        <configure-card title="Water Supply" routerLinkValue="water-supply"
                            [customRichTextTypeID]="waterSupplyRichTextTypeID" [toggleChecked]="true"></configure-card>
                    </div>

                    <div class="g-col-3">
                        <configure-card title="Allocation Plans" routerLinkValue="allocation-plans"
                            [customRichTextTypeID]="allocationPlanRichTextTypeID" (toggle)="changeWaterSupplyActive()"
                            [toggleChecked]="isWaterSupplyActive()"></configure-card>
                    </div>

                    <div class="g-col-3">
                        <configure-card title="Meter Data" routerLinkValue="meter-data"
                            [customRichTextTypeID]="meterRichTextTypeID" [toggleChecked]="
                                currentSelectedGeography.GeographyConfiguration.MetersEnabled
                            "></configure-card>
                    </div>

                    <div class="g-col-3">
                        <configure-card title="Water Levels" routerLinkValue="water-levels"
                            [customRichTextTypeID]="waterLevelsRichTextTypeID" [toggleChecked]="true"
                            [linkDisabled]="true"></configure-card>
                    </div>

                    <div class="g-col-3">
                        <configure-card title="Scenarios" routerLinkValue="scenarios"
                            [customRichTextTypeID]="scenariosRichTextTypeID" [linkDisabled]="true"></configure-card>
                    </div>

                    <div class="g-col-3">
                        <configure-card title="Well Registry" routerLinkValue="well-registry"
                            [customRichTextTypeID]="wellRegistryRichTextTypeID" [toggleChecked]="
                                currentSelectedGeography.GeographyConfiguration.WellRegistryEnabled
                            "></configure-card>
                    </div>

                    <div class="g-col-3">
                        <configure-card title="Zones" routerLinkValue="zones"
                            [customRichTextTypeID]="zoneGroupRichTextTypeID" [toggleChecked]="true"></configure-card>
                    </div>

                    <div class="g-col-3">
                        <configure-card title="Custom Attributes" routerLinkValue="custom-attributes"
                            [customRichTextTypeID]="customAttributesRichTextTypeID"
                            [toggleChecked]="true"></configure-card>
                    </div>

                    <div class="g-col-3">
                        <configure-card title="Water Managers" routerLinkValue="edit-water-managers"
                            [customRichTextTypeID]="waterManagersRichTextTypeID"
                            [toggleChecked]="true"></configure-card>
                    </div>

                    <div class="g-col-3">
                        <configure-card title="Geospatial Data Layers" routerLinkValue="geospatial-data"
                            [customRichTextTypeID]="geospatialDataRichTextTypeID"
                            [toggleChecked]="true"></configure-card>
                    </div>

                    <div class="g-col-3">
                        <configure-card title="Landing Page" [customRichTextTypeID]="landingPageRichTextTypeID"
                            routerLinkValue="landing-page" [toggleChecked]="
                                currentSelectedGeography.GeographyConfiguration.LandingPageEnabled
                            "></configure-card>
                    </div>

                    <div class="g-col-3">
                        <configure-card title="Trading" routerLinkValue="trading"
                            [customRichTextTypeID]="waterLevelsRichTextTypeID" [linkDisabled]="true"></configure-card>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>