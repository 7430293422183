<ng-container *ngIf="parcels?.length > 0; else noWaterAccountsMessage">
    <div class="flex-center mb-4">
        <div class="button-group">
            <button class="button-group__item active" [class.active]="viewingWaterAccountsTab == true"
                (click)="viewingWaterAccountsTab = true">
                Parcel Map
            </button>
            <button class="button-group__item" [class.active]="viewingWaterAccountsTab == false"
                (click)="viewingWaterAccountsTab = false">
                Parcel List
            </button>
        </div>
    </div>

    <section class="map-view" *ngIf="viewingWaterAccountsTab">
        <div class="grid-12">
            <div class="g-col-3">
                <div class="form-field">
                    <div class="field">
                        <label for="GeographyPicker" class="field-label">Filter by Geography</label>
                        <select name="GeographyPicker" id="GeographyPicker" *ngIf="uniqueGeographies.length > 0"
                            [formControl]="selectedGeography">
                            <option [ngValue]="null" *ngIf="!isCurrentUserAdmin() && uniqueGeographies.length > 1">
                                All Water Accounts
                            </option>
                            <option [ngValue]="geography" *ngFor="let geography of uniqueGeographies">
                                {{ geography.GeographyName }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid-12 mt-4">
            <div class="g-col-4">
                <div class="water-account-list scroll-shadow">
                    <div class="mb-4" *ngFor="let geography of getGeographiesToShow()">
                        <h3 class="module-title underline mb-3">
                            {{ geography.GeographyName }} {{ getNumberOfParcels(geography) }}
                        </h3>
                        <div class="water-account-list__wrapper">
                            <div *ngFor="let parcel of getParcelsFromGeography(geography)">
                                <parcel-small-card [parcel]="parcel"
                                    [isHighlighted]="highlightedParcelID == parcel.Parcel.ParcelID"
                                    (click)="setSelectedParcel(parcel)"></parcel-small-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="g-col-8" *ngIf="isReady">
                <parcel-water-account-dashboard-map [parcelIDs]="parcelIDs"
                    [geographyID]="selectedGeography.value?.GeographyID" [(highlightedParcelID)]="
                        highlightedParcelID
                    "></parcel-water-account-dashboard-map>
            </div>
        </div>
    </section>

    <section class="budget-view" *ngIf="!viewingWaterAccountsTab">
        <div class="grid-12">
            <div class="g-col-12"
                *ngIf="parcelWaterSupplyAndUsages$ | async as parcelWaterSupplyAndUsages; else isLoading">
                <qanat-grid [rowData]="parcelWaterSupplyAndUsages" [columnDefs]="columnDefs" [pagination]="true"
                    [paginationPageSize]="100" downloadFileName="parcel-budgets" [sizeColumnsToFitGrid]="true"
                    (gridReady)="parcelGridReady($event)"></qanat-grid>
            </div>

            <ng-template #isLoading>
                <div [loadingSpinner]="{ isLoading: true, loadingHeight: 720 }"></div>
            </ng-template>
        </div>
    </section>
</ng-container>
<ng-template #noWaterAccountsMessage>
    <div class="alert alert-info">
        <div class="alert-content">
            <i class="fa fa-info"></i>
            Your user profile does not have access to manage any Water Accounts at this time. Claim
            Water Accounts to get started or contact a water manager for your geography for
            assistance.
        </div>
    </div>
</ng-template>