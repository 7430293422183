<page-header pageTitle="Update Parcels" [templateRight]="templateRight" [customRichTextTypeID]="customRichTextTypeID">
    <ng-template #templateRight>
        <button class="btn btn-primary btn-sm" [routerLink]="['../../', 'upload-parcel-data']"
            [disabled]="unreviewedParcelsCount > 0">
            Upload Parcel Data
        </button>
    </ng-template>
</page-header>
<app-alert-display></app-alert-display>

<div class="page-body grid-12" *ngIf="selectedGeography$ | async">
    <div class="section-header flex-start">
        <h2 class="section-title text-primary">Review Parcel Changes</h2>

        <div class="review-stats" *ngIf="unreviewedParcelsCount !== null">
            <div class="orange">{{ unreviewedParcelsCount | number }} Unreviewed</div>
            <div>{{ reviewedParcelsCount | number }} Reviewed</div>
        </div>
    </div>

    <note noteType="info" *ngIf="latestGDBUpload$ | async as latestGDBUpload">
        <icon icon="Info"></icon>
        Parcel data was last uploaded on
        <b>{{ latestGDBUpload.UploadDate | date: 'medium' }}</b>
    </note>

    <parcel-review-changes-card *ngIf="parcelsToDisplay?.length > 0; else noParcelsToDisplay" [parcel]="selectedParcel"
        [nextButtonDisabled]="parcelsToDisplay?.length === 1" (parcelReviewed)="onParcelReviewed()"
        (parcelUpdated)="onParcelUpdated()" (nextParcel)="selectNextParcel()"></parcel-review-changes-card>

    <ng-template #noParcelsToDisplay>
        <div class="no-parcels-to-display" *ngIf="parcelsToDisplay?.length === 0">
            No{{ showReviewedParcels ? ' ' : ' unreviewed ' }}parcels to display
        </div>
    </ng-template>

    <div class="table-responsive" *ngIf="parcels$ | async">
        <qanat-grid height="400px" [rowData]="parcelsToDisplay" [columnDefs]="columnDefs" [getRowId]="getRowId"
            rowSelection="single" [sizeColumnsToFitGrid]="true" (gridReady)="onGridReady($event)"
            (filterChanged)="onFilterChanged($event)" (selectionChanged)="onParcelSelected($event)">
            <div customGridActionsLeft>
                <div class="reviewed-items-toggle">
                    <em class="mr=2">Show Reviewed Items</em>
                    <label class="switch">
                        <input type="checkbox" name="hideReviewedItems" [(ngModel)]="showReviewedParcels"
                            (ngModelChange)="setParcelsToDisplay()" />
                        <span class="toggle toggle-primary"></span>
                    </label>
                </div>
            </div>

            <button customGridActions class="btn btn-primary btn-sm" (click)="markAllAsReviewed()">
                Mark {{ anyGridFilterPresent ? 'Filtered' : 'All' }} as Reviewed
                {{ anyGridFilterPresent ? '(' + filteredRowsCount + ')' : '' }}
            </button>
        </qanat-grid>
    </div>
</div>