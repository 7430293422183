/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ScenarioObservationPointDto } from '././scenario-observation-point-dto';
import { ScenarioPumpingWellDto } from '././scenario-pumping-well-dto';

import { FormControl, FormControlOptions, FormControlState, Validators } from "@angular/forms";
export class AddAWellScenarioDto { 
    ScenarioRunName: string;
    ScenarioPumpingWells?: Array<ScenarioPumpingWellDto>;
    ScenarioObservationPoints?: Array<ScenarioObservationPointDto>;
    ModelID?: number;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export interface AddAWellScenarioDtoForm { 
    ScenarioRunName: FormControl<string>;
    ScenarioPumpingWells?: FormControl<Array<ScenarioPumpingWellDto>>;
    ScenarioObservationPoints?: FormControl<Array<ScenarioObservationPointDto>>;
    ModelID?: FormControl<number>;
}

export class AddAWellScenarioDtoFormControls { 
    public static ScenarioRunName = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
                Validators.minLength(1),
            ],
        }
    );
    public static ScenarioPumpingWells = (value: FormControlState<Array<ScenarioPumpingWellDto>> | Array<ScenarioPumpingWellDto> = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<Array<ScenarioPumpingWellDto>>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static ScenarioObservationPoints = (value: FormControlState<Array<ScenarioObservationPointDto>> | Array<ScenarioObservationPointDto> = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<Array<ScenarioObservationPointDto>>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static ModelID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
}
