import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'workflow-nav',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './workflow-nav.component.html',
  styleUrls: ['./workflow-nav.component.scss']
})
export class WorkflowNavComponent {

}
