/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AllocationPlanManageDto } from '../model/allocation-plan-manage-dto';
import { AllocationPlanMinimalDto } from '../model/allocation-plan-minimal-dto';
import { AllocationPlanPeriodUpsertDto } from '../model/allocation-plan-period-upsert-dto';
import { AllocationPlanPreviewChangesDto } from '../model/allocation-plan-preview-changes-dto';
import { GeographyAllocationPlanConfigurationDto } from '../model/geography-allocation-plan-configuration-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class AllocationPlanService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param geographyID 
     * @param allocationPlanID 
     * @param allocationPlanPeriodID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public allocationPlansGeographyIDAllocationPlanIDAllocationPlanPeriodIDDelete(geographyID: number, allocationPlanID: number, allocationPlanPeriodID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public allocationPlansGeographyIDAllocationPlanIDAllocationPlanPeriodIDDelete(geographyID: number, allocationPlanID: number, allocationPlanPeriodID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public allocationPlansGeographyIDAllocationPlanIDAllocationPlanPeriodIDDelete(geographyID: number, allocationPlanID: number, allocationPlanPeriodID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public allocationPlansGeographyIDAllocationPlanIDAllocationPlanPeriodIDDelete(geographyID: number, allocationPlanID: number, allocationPlanPeriodID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling allocationPlansGeographyIDAllocationPlanIDAllocationPlanPeriodIDDelete.');
        }

        if (allocationPlanID === null || allocationPlanID === undefined) {
            throw new Error('Required parameter allocationPlanID was null or undefined when calling allocationPlansGeographyIDAllocationPlanIDAllocationPlanPeriodIDDelete.');
        }

        if (allocationPlanPeriodID === null || allocationPlanPeriodID === undefined) {
            throw new Error('Required parameter allocationPlanPeriodID was null or undefined when calling allocationPlansGeographyIDAllocationPlanIDAllocationPlanPeriodIDDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/allocation-plans/${encodeURIComponent(String(geographyID))}/${encodeURIComponent(String(allocationPlanID))}/${encodeURIComponent(String(allocationPlanPeriodID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDAllocationPlanConfigurationGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<GeographyAllocationPlanConfigurationDto>;
    public geographiesGeographyIDAllocationPlanConfigurationGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeographyAllocationPlanConfigurationDto>>;
    public geographiesGeographyIDAllocationPlanConfigurationGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeographyAllocationPlanConfigurationDto>>;
    public geographiesGeographyIDAllocationPlanConfigurationGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDAllocationPlanConfigurationGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<GeographyAllocationPlanConfigurationDto>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/allocation-plan-configuration`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param geographyAllocationPlanConfigurationDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDAllocationPlanConfigurationPost(geographyID: number, geographyAllocationPlanConfigurationDto?: GeographyAllocationPlanConfigurationDto, observe?: 'body', reportProgress?: boolean): Observable<GeographyAllocationPlanConfigurationDto>;
    public geographiesGeographyIDAllocationPlanConfigurationPost(geographyID: number, geographyAllocationPlanConfigurationDto?: GeographyAllocationPlanConfigurationDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeographyAllocationPlanConfigurationDto>>;
    public geographiesGeographyIDAllocationPlanConfigurationPost(geographyID: number, geographyAllocationPlanConfigurationDto?: GeographyAllocationPlanConfigurationDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeographyAllocationPlanConfigurationDto>>;
    public geographiesGeographyIDAllocationPlanConfigurationPost(geographyID: number, geographyAllocationPlanConfigurationDto?: GeographyAllocationPlanConfigurationDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDAllocationPlanConfigurationPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GeographyAllocationPlanConfigurationDto>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/allocation-plan-configuration`,
            geographyAllocationPlanConfigurationDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param geographyAllocationPlanConfigurationDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDAllocationPlanConfigurationPreviewPut(geographyID: number, geographyAllocationPlanConfigurationDto?: GeographyAllocationPlanConfigurationDto, observe?: 'body', reportProgress?: boolean): Observable<Array<AllocationPlanPreviewChangesDto>>;
    public geographiesGeographyIDAllocationPlanConfigurationPreviewPut(geographyID: number, geographyAllocationPlanConfigurationDto?: GeographyAllocationPlanConfigurationDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AllocationPlanPreviewChangesDto>>>;
    public geographiesGeographyIDAllocationPlanConfigurationPreviewPut(geographyID: number, geographyAllocationPlanConfigurationDto?: GeographyAllocationPlanConfigurationDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AllocationPlanPreviewChangesDto>>>;
    public geographiesGeographyIDAllocationPlanConfigurationPreviewPut(geographyID: number, geographyAllocationPlanConfigurationDto?: GeographyAllocationPlanConfigurationDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDAllocationPlanConfigurationPreviewPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Array<AllocationPlanPreviewChangesDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/allocation-plan-configuration/preview`,
            geographyAllocationPlanConfigurationDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param geographyAllocationPlanConfigurationDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDAllocationPlanConfigurationPut(geographyID: number, geographyAllocationPlanConfigurationDto?: GeographyAllocationPlanConfigurationDto, observe?: 'body', reportProgress?: boolean): Observable<GeographyAllocationPlanConfigurationDto>;
    public geographiesGeographyIDAllocationPlanConfigurationPut(geographyID: number, geographyAllocationPlanConfigurationDto?: GeographyAllocationPlanConfigurationDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GeographyAllocationPlanConfigurationDto>>;
    public geographiesGeographyIDAllocationPlanConfigurationPut(geographyID: number, geographyAllocationPlanConfigurationDto?: GeographyAllocationPlanConfigurationDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GeographyAllocationPlanConfigurationDto>>;
    public geographiesGeographyIDAllocationPlanConfigurationPut(geographyID: number, geographyAllocationPlanConfigurationDto?: GeographyAllocationPlanConfigurationDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDAllocationPlanConfigurationPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<GeographyAllocationPlanConfigurationDto>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/allocation-plan-configuration`,
            geographyAllocationPlanConfigurationDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param allocationPlanID 
     * @param allocationPlanPeriodID 
     * @param allocationPlanPeriodUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDAllocationPlansAllocationPlanIDAllocationPlanPeriodIDPut(geographyID: number, allocationPlanID: number, allocationPlanPeriodID: number, allocationPlanPeriodUpsertDto?: AllocationPlanPeriodUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public geographiesGeographyIDAllocationPlansAllocationPlanIDAllocationPlanPeriodIDPut(geographyID: number, allocationPlanID: number, allocationPlanPeriodID: number, allocationPlanPeriodUpsertDto?: AllocationPlanPeriodUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public geographiesGeographyIDAllocationPlansAllocationPlanIDAllocationPlanPeriodIDPut(geographyID: number, allocationPlanID: number, allocationPlanPeriodID: number, allocationPlanPeriodUpsertDto?: AllocationPlanPeriodUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public geographiesGeographyIDAllocationPlansAllocationPlanIDAllocationPlanPeriodIDPut(geographyID: number, allocationPlanID: number, allocationPlanPeriodID: number, allocationPlanPeriodUpsertDto?: AllocationPlanPeriodUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDAllocationPlansAllocationPlanIDAllocationPlanPeriodIDPut.');
        }

        if (allocationPlanID === null || allocationPlanID === undefined) {
            throw new Error('Required parameter allocationPlanID was null or undefined when calling geographiesGeographyIDAllocationPlansAllocationPlanIDAllocationPlanPeriodIDPut.');
        }

        if (allocationPlanPeriodID === null || allocationPlanPeriodID === undefined) {
            throw new Error('Required parameter allocationPlanPeriodID was null or undefined when calling geographiesGeographyIDAllocationPlansAllocationPlanIDAllocationPlanPeriodIDPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/allocation-plans/${encodeURIComponent(String(allocationPlanID))}/${encodeURIComponent(String(allocationPlanPeriodID))}`,
            allocationPlanPeriodUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param allocationPlanID 
     * @param allocationPlanPeriodUpsertDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDAllocationPlansAllocationPlanIDPost(geographyID: number, allocationPlanID: number, allocationPlanPeriodUpsertDto?: AllocationPlanPeriodUpsertDto, observe?: 'body', reportProgress?: boolean): Observable<AllocationPlanManageDto>;
    public geographiesGeographyIDAllocationPlansAllocationPlanIDPost(geographyID: number, allocationPlanID: number, allocationPlanPeriodUpsertDto?: AllocationPlanPeriodUpsertDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AllocationPlanManageDto>>;
    public geographiesGeographyIDAllocationPlansAllocationPlanIDPost(geographyID: number, allocationPlanID: number, allocationPlanPeriodUpsertDto?: AllocationPlanPeriodUpsertDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AllocationPlanManageDto>>;
    public geographiesGeographyIDAllocationPlansAllocationPlanIDPost(geographyID: number, allocationPlanID: number, allocationPlanPeriodUpsertDto?: AllocationPlanPeriodUpsertDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDAllocationPlansAllocationPlanIDPost.');
        }

        if (allocationPlanID === null || allocationPlanID === undefined) {
            throw new Error('Required parameter allocationPlanID was null or undefined when calling geographiesGeographyIDAllocationPlansAllocationPlanIDPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AllocationPlanManageDto>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/allocation-plans/${encodeURIComponent(String(allocationPlanID))}`,
            allocationPlanPeriodUpsertDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param copyToAllocationPlanID 
     * @param copyFromAllocationPlanID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDAllocationPlansCopyToAllocationPlanIDCopyFromCopyFromAllocationPlanIDPost(geographyID: number, copyToAllocationPlanID: number, copyFromAllocationPlanID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public geographiesGeographyIDAllocationPlansCopyToAllocationPlanIDCopyFromCopyFromAllocationPlanIDPost(geographyID: number, copyToAllocationPlanID: number, copyFromAllocationPlanID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public geographiesGeographyIDAllocationPlansCopyToAllocationPlanIDCopyFromCopyFromAllocationPlanIDPost(geographyID: number, copyToAllocationPlanID: number, copyFromAllocationPlanID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public geographiesGeographyIDAllocationPlansCopyToAllocationPlanIDCopyFromCopyFromAllocationPlanIDPost(geographyID: number, copyToAllocationPlanID: number, copyFromAllocationPlanID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDAllocationPlansCopyToAllocationPlanIDCopyFromCopyFromAllocationPlanIDPost.');
        }

        if (copyToAllocationPlanID === null || copyToAllocationPlanID === undefined) {
            throw new Error('Required parameter copyToAllocationPlanID was null or undefined when calling geographiesGeographyIDAllocationPlansCopyToAllocationPlanIDCopyFromCopyFromAllocationPlanIDPost.');
        }

        if (copyFromAllocationPlanID === null || copyFromAllocationPlanID === undefined) {
            throw new Error('Required parameter copyFromAllocationPlanID was null or undefined when calling geographiesGeographyIDAllocationPlansCopyToAllocationPlanIDCopyFromCopyFromAllocationPlanIDPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/allocation-plans/${encodeURIComponent(String(copyToAllocationPlanID))}/copy-from/${encodeURIComponent(String(copyFromAllocationPlanID))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param waterTypeSlug 
     * @param zoneSlug 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publicAllocationPlansGeographyIDWaterTypeSlugZoneSlugGet(geographyID: number, waterTypeSlug: string, zoneSlug: string, observe?: 'body', reportProgress?: boolean): Observable<AllocationPlanManageDto>;
    public publicAllocationPlansGeographyIDWaterTypeSlugZoneSlugGet(geographyID: number, waterTypeSlug: string, zoneSlug: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AllocationPlanManageDto>>;
    public publicAllocationPlansGeographyIDWaterTypeSlugZoneSlugGet(geographyID: number, waterTypeSlug: string, zoneSlug: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AllocationPlanManageDto>>;
    public publicAllocationPlansGeographyIDWaterTypeSlugZoneSlugGet(geographyID: number, waterTypeSlug: string, zoneSlug: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling publicAllocationPlansGeographyIDWaterTypeSlugZoneSlugGet.');
        }

        if (waterTypeSlug === null || waterTypeSlug === undefined) {
            throw new Error('Required parameter waterTypeSlug was null or undefined when calling publicAllocationPlansGeographyIDWaterTypeSlugZoneSlugGet.');
        }

        if (zoneSlug === null || zoneSlug === undefined) {
            throw new Error('Required parameter zoneSlug was null or undefined when calling publicAllocationPlansGeographyIDWaterTypeSlugZoneSlugGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<AllocationPlanManageDto>(`${this.basePath}/public/allocation-plans/${encodeURIComponent(String(geographyID))}/${encodeURIComponent(String(waterTypeSlug))}/${encodeURIComponent(String(zoneSlug))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publicGeographyGeographyIDAllocationPlanConfigurationDescriptionGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public publicGeographyGeographyIDAllocationPlanConfigurationDescriptionGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public publicGeographyGeographyIDAllocationPlanConfigurationDescriptionGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public publicGeographyGeographyIDAllocationPlanConfigurationDescriptionGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling publicGeographyGeographyIDAllocationPlanConfigurationDescriptionGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/public/geography/${encodeURIComponent(String(geographyID))}/allocation-plan-configuration/description`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publicGeographyGeographyIDAllocationPlansGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<AllocationPlanMinimalDto>>;
    public publicGeographyGeographyIDAllocationPlansGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AllocationPlanMinimalDto>>>;
    public publicGeographyGeographyIDAllocationPlansGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AllocationPlanMinimalDto>>>;
    public publicGeographyGeographyIDAllocationPlansGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling publicGeographyGeographyIDAllocationPlansGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<AllocationPlanMinimalDto>>(`${this.basePath}/public/geography/${encodeURIComponent(String(geographyID))}/allocation-plans`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}
