//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[WellRegistrationStatus]

export enum WellRegistrationStatusEnum {
  Draft = 1,
  Submitted = 2,
  Returned = 3,
  Approved = 4
}
