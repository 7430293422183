<dashboard-menu
    *ngIf="wellMenu$ | async as wellMenu"
    [dashboardMenu]="wellMenu"
    [viewingDetailPage]="viewingDetailPage"></dashboard-menu>
<dashboard-menu
    *ngIf="waterAccountMenu$ | async as waterAccountMenu"
    [dashboardMenu]="waterAccountMenu"
    [viewingDetailPage]="viewingDetailPage"></dashboard-menu>
<dashboard-menu
    *ngIf="parcelMenu$ | async as parcelMenu"
    [dashboardMenu]="parcelMenu"
    [viewingDetailPage]="viewingDetailPage"></dashboard-menu>
<dashboard-menu
    *ngIf="dashboardMenu$ | async as dashboardMenu"
    [dashboardMenu]="dashboardMenu"
    [viewingDetailPage]="viewingDetailPage"></dashboard-menu>
