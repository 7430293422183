/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AllocationPlanMinimalDto } from '../model/allocation-plan-minimal-dto';
import { GeographySimpleDto } from '../model/geography-simple-dto';
import { MergeWaterAccountsDto } from '../model/merge-water-accounts-dto';
import { ParcelWaterAccountDashboardDto } from '../model/parcel-water-account-dashboard-dto';
import { UpdateWaterAccountParcelsDto } from '../model/update-water-account-parcels-dto';
import { WaterAccountBudgetReportDto } from '../model/water-account-budget-report-dto';
import { WaterAccountDashboardDto } from '../model/water-account-dashboard-dto';
import { WaterAccountDto } from '../model/water-account-dto';
import { WaterAccountGeoJSONDto } from '../model/water-account-geo-json-dto';
import { WaterAccountMinimalDto } from '../model/water-account-minimal-dto';
import { WaterAccountParcelWaterMeasurementDto } from '../model/water-account-parcel-water-measurement-dto';
import { WaterAccountUpdateDto } from '../model/water-account-update-dto';
import { WaterAccountUserMinimalDto } from '../model/water-account-user-minimal-dto';
import { WaterAccountWaterTypeSupplyDto } from '../model/water-account-water-type-supply-dto';
import { WellLinkDisplayDto } from '../model/well-link-display-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class WaterAccountService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterAccountsCurrentUserGeographiesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<GeographySimpleDto>>;
    public waterAccountsCurrentUserGeographiesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<GeographySimpleDto>>>;
    public waterAccountsCurrentUserGeographiesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<GeographySimpleDto>>>;
    public waterAccountsCurrentUserGeographiesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<GeographySimpleDto>>(`${this.basePath}/water-accounts/current-user/geographies`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterAccountsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<WaterAccountMinimalDto>>;
    public waterAccountsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WaterAccountMinimalDto>>>;
    public waterAccountsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WaterAccountMinimalDto>>>;
    public waterAccountsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WaterAccountMinimalDto>>(`${this.basePath}/water-accounts`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param year 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterAccountsWaterAccountBudgetReportYearGet(year: number, observe?: 'body', reportProgress?: boolean): Observable<Array<WaterAccountBudgetReportDto>>;
    public waterAccountsWaterAccountBudgetReportYearGet(year: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WaterAccountBudgetReportDto>>>;
    public waterAccountsWaterAccountBudgetReportYearGet(year: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WaterAccountBudgetReportDto>>>;
    public waterAccountsWaterAccountBudgetReportYearGet(year: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling waterAccountsWaterAccountBudgetReportYearGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WaterAccountBudgetReportDto>>(`${this.basePath}/water-accounts/water-account-budget-report/${encodeURIComponent(String(year))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param waterAccountID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterAccountsWaterAccountIDAllocationPlansGet(waterAccountID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<AllocationPlanMinimalDto>>;
    public waterAccountsWaterAccountIDAllocationPlansGet(waterAccountID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AllocationPlanMinimalDto>>>;
    public waterAccountsWaterAccountIDAllocationPlansGet(waterAccountID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AllocationPlanMinimalDto>>>;
    public waterAccountsWaterAccountIDAllocationPlansGet(waterAccountID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (waterAccountID === null || waterAccountID === undefined) {
            throw new Error('Required parameter waterAccountID was null or undefined when calling waterAccountsWaterAccountIDAllocationPlansGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<AllocationPlanMinimalDto>>(`${this.basePath}/water-accounts/${encodeURIComponent(String(waterAccountID))}/allocation-plans`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param waterAccountID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterAccountsWaterAccountIDDelete(waterAccountID: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public waterAccountsWaterAccountIDDelete(waterAccountID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public waterAccountsWaterAccountIDDelete(waterAccountID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public waterAccountsWaterAccountIDDelete(waterAccountID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (waterAccountID === null || waterAccountID === undefined) {
            throw new Error('Required parameter waterAccountID was null or undefined when calling waterAccountsWaterAccountIDDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/water-accounts/${encodeURIComponent(String(waterAccountID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param waterAccountID 
     * @param waterAccountUserMinimalDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterAccountsWaterAccountIDEditUsersPut(waterAccountID: number, waterAccountUserMinimalDto?: Array<WaterAccountUserMinimalDto>, observe?: 'body', reportProgress?: boolean): Observable<WaterAccountMinimalDto>;
    public waterAccountsWaterAccountIDEditUsersPut(waterAccountID: number, waterAccountUserMinimalDto?: Array<WaterAccountUserMinimalDto>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WaterAccountMinimalDto>>;
    public waterAccountsWaterAccountIDEditUsersPut(waterAccountID: number, waterAccountUserMinimalDto?: Array<WaterAccountUserMinimalDto>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WaterAccountMinimalDto>>;
    public waterAccountsWaterAccountIDEditUsersPut(waterAccountID: number, waterAccountUserMinimalDto?: Array<WaterAccountUserMinimalDto>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (waterAccountID === null || waterAccountID === undefined) {
            throw new Error('Required parameter waterAccountID was null or undefined when calling waterAccountsWaterAccountIDEditUsersPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<WaterAccountMinimalDto>(`${this.basePath}/water-accounts/${encodeURIComponent(String(waterAccountID))}/edit-users`,
            waterAccountUserMinimalDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param waterAccountID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterAccountsWaterAccountIDGeojsonGet(waterAccountID: number, observe?: 'body', reportProgress?: boolean): Observable<WaterAccountGeoJSONDto>;
    public waterAccountsWaterAccountIDGeojsonGet(waterAccountID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WaterAccountGeoJSONDto>>;
    public waterAccountsWaterAccountIDGeojsonGet(waterAccountID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WaterAccountGeoJSONDto>>;
    public waterAccountsWaterAccountIDGeojsonGet(waterAccountID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (waterAccountID === null || waterAccountID === undefined) {
            throw new Error('Required parameter waterAccountID was null or undefined when calling waterAccountsWaterAccountIDGeojsonGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<WaterAccountGeoJSONDto>(`${this.basePath}/water-accounts/${encodeURIComponent(String(waterAccountID))}/geojson`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param waterAccountID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterAccountsWaterAccountIDGet(waterAccountID: number, observe?: 'body', reportProgress?: boolean): Observable<WaterAccountDto>;
    public waterAccountsWaterAccountIDGet(waterAccountID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WaterAccountDto>>;
    public waterAccountsWaterAccountIDGet(waterAccountID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WaterAccountDto>>;
    public waterAccountsWaterAccountIDGet(waterAccountID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (waterAccountID === null || waterAccountID === undefined) {
            throw new Error('Required parameter waterAccountID was null or undefined when calling waterAccountsWaterAccountIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<WaterAccountDto>(`${this.basePath}/water-accounts/${encodeURIComponent(String(waterAccountID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param waterAccountID 
     * @param secondaryWaterAccountID 
     * @param mergeWaterAccountsDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterAccountsWaterAccountIDMergeSecondaryWaterAccountIDPut(waterAccountID: number, secondaryWaterAccountID: number, mergeWaterAccountsDto?: MergeWaterAccountsDto, observe?: 'body', reportProgress?: boolean): Observable<WaterAccountMinimalDto>;
    public waterAccountsWaterAccountIDMergeSecondaryWaterAccountIDPut(waterAccountID: number, secondaryWaterAccountID: number, mergeWaterAccountsDto?: MergeWaterAccountsDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WaterAccountMinimalDto>>;
    public waterAccountsWaterAccountIDMergeSecondaryWaterAccountIDPut(waterAccountID: number, secondaryWaterAccountID: number, mergeWaterAccountsDto?: MergeWaterAccountsDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WaterAccountMinimalDto>>;
    public waterAccountsWaterAccountIDMergeSecondaryWaterAccountIDPut(waterAccountID: number, secondaryWaterAccountID: number, mergeWaterAccountsDto?: MergeWaterAccountsDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (waterAccountID === null || waterAccountID === undefined) {
            throw new Error('Required parameter waterAccountID was null or undefined when calling waterAccountsWaterAccountIDMergeSecondaryWaterAccountIDPut.');
        }

        if (secondaryWaterAccountID === null || secondaryWaterAccountID === undefined) {
            throw new Error('Required parameter secondaryWaterAccountID was null or undefined when calling waterAccountsWaterAccountIDMergeSecondaryWaterAccountIDPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<WaterAccountMinimalDto>(`${this.basePath}/water-accounts/${encodeURIComponent(String(waterAccountID))}/merge/${encodeURIComponent(String(secondaryWaterAccountID))}`,
            mergeWaterAccountsDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param waterAccountID 
     * @param year 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterAccountsWaterAccountIDParcelSuppliesYearMonthlyUsageSummaryGet(waterAccountID: number, year: number, observe?: 'body', reportProgress?: boolean): Observable<Array<WaterAccountParcelWaterMeasurementDto>>;
    public waterAccountsWaterAccountIDParcelSuppliesYearMonthlyUsageSummaryGet(waterAccountID: number, year: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WaterAccountParcelWaterMeasurementDto>>>;
    public waterAccountsWaterAccountIDParcelSuppliesYearMonthlyUsageSummaryGet(waterAccountID: number, year: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WaterAccountParcelWaterMeasurementDto>>>;
    public waterAccountsWaterAccountIDParcelSuppliesYearMonthlyUsageSummaryGet(waterAccountID: number, year: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (waterAccountID === null || waterAccountID === undefined) {
            throw new Error('Required parameter waterAccountID was null or undefined when calling waterAccountsWaterAccountIDParcelSuppliesYearMonthlyUsageSummaryGet.');
        }

        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling waterAccountsWaterAccountIDParcelSuppliesYearMonthlyUsageSummaryGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WaterAccountParcelWaterMeasurementDto>>(`${this.basePath}/water-accounts/${encodeURIComponent(String(waterAccountID))}/parcel-supplies/${encodeURIComponent(String(year))}/monthlyUsageSummary`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param waterAccountID 
     * @param waterAccountUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterAccountsWaterAccountIDPut(waterAccountID: number, waterAccountUpdateDto?: WaterAccountUpdateDto, observe?: 'body', reportProgress?: boolean): Observable<WaterAccountDto>;
    public waterAccountsWaterAccountIDPut(waterAccountID: number, waterAccountUpdateDto?: WaterAccountUpdateDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WaterAccountDto>>;
    public waterAccountsWaterAccountIDPut(waterAccountID: number, waterAccountUpdateDto?: WaterAccountUpdateDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WaterAccountDto>>;
    public waterAccountsWaterAccountIDPut(waterAccountID: number, waterAccountUpdateDto?: WaterAccountUpdateDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (waterAccountID === null || waterAccountID === undefined) {
            throw new Error('Required parameter waterAccountID was null or undefined when calling waterAccountsWaterAccountIDPut.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<WaterAccountDto>(`${this.basePath}/water-accounts/${encodeURIComponent(String(waterAccountID))}`,
            waterAccountUpdateDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param waterAccountID 
     * @param updateWaterAccountParcelsDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterAccountsWaterAccountIDUpdateParcelsPatch(waterAccountID: number, updateWaterAccountParcelsDto?: UpdateWaterAccountParcelsDto, observe?: 'body', reportProgress?: boolean): Observable<WaterAccountMinimalDto>;
    public waterAccountsWaterAccountIDUpdateParcelsPatch(waterAccountID: number, updateWaterAccountParcelsDto?: UpdateWaterAccountParcelsDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WaterAccountMinimalDto>>;
    public waterAccountsWaterAccountIDUpdateParcelsPatch(waterAccountID: number, updateWaterAccountParcelsDto?: UpdateWaterAccountParcelsDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WaterAccountMinimalDto>>;
    public waterAccountsWaterAccountIDUpdateParcelsPatch(waterAccountID: number, updateWaterAccountParcelsDto?: UpdateWaterAccountParcelsDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (waterAccountID === null || waterAccountID === undefined) {
            throw new Error('Required parameter waterAccountID was null or undefined when calling waterAccountsWaterAccountIDUpdateParcelsPatch.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.patch<WaterAccountMinimalDto>(`${this.basePath}/water-accounts/${encodeURIComponent(String(waterAccountID))}/update-parcels`,
            updateWaterAccountParcelsDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param waterAccountID 
     * @param year 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterAccountsWaterAccountIDWaterTypeSupplyYearGet(waterAccountID: number, year: number, observe?: 'body', reportProgress?: boolean): Observable<Array<WaterAccountWaterTypeSupplyDto>>;
    public waterAccountsWaterAccountIDWaterTypeSupplyYearGet(waterAccountID: number, year: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WaterAccountWaterTypeSupplyDto>>>;
    public waterAccountsWaterAccountIDWaterTypeSupplyYearGet(waterAccountID: number, year: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WaterAccountWaterTypeSupplyDto>>>;
    public waterAccountsWaterAccountIDWaterTypeSupplyYearGet(waterAccountID: number, year: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (waterAccountID === null || waterAccountID === undefined) {
            throw new Error('Required parameter waterAccountID was null or undefined when calling waterAccountsWaterAccountIDWaterTypeSupplyYearGet.');
        }

        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling waterAccountsWaterAccountIDWaterTypeSupplyYearGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WaterAccountWaterTypeSupplyDto>>(`${this.basePath}/water-accounts/${encodeURIComponent(String(waterAccountID))}/water-type-supply/${encodeURIComponent(String(year))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param waterAccountID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterAccountsWaterAccountIDWellsGet(waterAccountID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<WellLinkDisplayDto>>;
    public waterAccountsWaterAccountIDWellsGet(waterAccountID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WellLinkDisplayDto>>>;
    public waterAccountsWaterAccountIDWellsGet(waterAccountID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WellLinkDisplayDto>>>;
    public waterAccountsWaterAccountIDWellsGet(waterAccountID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (waterAccountID === null || waterAccountID === undefined) {
            throw new Error('Required parameter waterAccountID was null or undefined when calling waterAccountsWaterAccountIDWellsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WellLinkDisplayDto>>(`${this.basePath}/water-accounts/${encodeURIComponent(String(waterAccountID))}/wells`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterAccountsWaterAccountsDashboardGet(observe?: 'body', reportProgress?: boolean): Observable<Array<WaterAccountDashboardDto>>;
    public waterAccountsWaterAccountsDashboardGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WaterAccountDashboardDto>>>;
    public waterAccountsWaterAccountsDashboardGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WaterAccountDashboardDto>>>;
    public waterAccountsWaterAccountsDashboardGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WaterAccountDashboardDto>>(`${this.basePath}/water-accounts/water-accounts-dashboard`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param userID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public waterAccountsWaterAccountsDashboardParcelsUserUserIDGet(userID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ParcelWaterAccountDashboardDto>>;
    public waterAccountsWaterAccountsDashboardParcelsUserUserIDGet(userID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ParcelWaterAccountDashboardDto>>>;
    public waterAccountsWaterAccountsDashboardParcelsUserUserIDGet(userID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ParcelWaterAccountDashboardDto>>>;
    public waterAccountsWaterAccountsDashboardParcelsUserUserIDGet(userID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userID === null || userID === undefined) {
            throw new Error('Required parameter userID was null or undefined when calling waterAccountsWaterAccountsDashboardParcelsUserUserIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ParcelWaterAccountDashboardDto>>(`${this.basePath}/water-accounts/water-accounts-dashboard/parcels/user/${encodeURIComponent(String(userID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}
