import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'permissions-configure',
    templateUrl: './permissions-configure.component.html',
    styleUrls: ['./permissions-configure.component.scss'],
    standalone: true
})
export class PermissionsConfigureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
