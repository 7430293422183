<div id="WaterAccountDashboardMap" class="location-card" style="height: 700px">
    <div class="loading-indicator" [class.loading]="isLoadingWaterAccounts">
        Loading Water Accounts view...
    </div>
    <gsa-boundaries *ngIf="geography" [map]="map" [layerControl]="layerControl"
        [geographyID]="geography.GeographyID"></gsa-boundaries>
    <!-- <usage-entities-layer
        [map]="map"
        [layerControl]="layerControl"
        [geographyID]="geographyID"></usage-entities-layer> -->
</div>