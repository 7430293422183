/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { FormControl, FormControlOptions, FormControlState, Validators } from "@angular/forms";
export class GeographyLandingPageDto { 
    NumberOfWaterAccounts?: number;
    NumberOfWellRegistrations?: number;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export interface GeographyLandingPageDtoForm { 
    NumberOfWaterAccounts?: FormControl<number>;
    NumberOfWellRegistrations?: FormControl<number>;
}

export class GeographyLandingPageDtoFormControls { 
    public static NumberOfWaterAccounts = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static NumberOfWellRegistrations = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
}
