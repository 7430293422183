<div class="list-view">
    <page-header icon="WaterAccounts" [customRichTextTypeID]="richTextTypeID"></page-header>

    <div class="page-body" [loadingSpinner]="{ isLoading: isLoading }">
        <app-alert-display></app-alert-display>

        <qanat-grid *ngIf="waterAccounts$ | async as waterAccounts" [rowData]="waterAccounts" [columnDefs]="columnDefs"
            downloadFileName="water-accounts" [colIDsToExclude]="colIDsToExclude" rowSelection="single"
            (gridReady)="onGridReady($event)"></qanat-grid>
    </div>
</div>