/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { FormControl, FormControlOptions, FormControlState, Validators } from "@angular/forms";
export class CreateWaterAccountFromSuggestionDto { 
    ParcelIDList: Array<number>;
    EffectiveYear: number;
    WaterAccountName: string;
    ContactName?: string;
    ContactAddress?: string;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export interface CreateWaterAccountFromSuggestionDtoForm { 
    ParcelIDList: FormControl<Array<number>>;
    EffectiveYear: FormControl<number>;
    WaterAccountName: FormControl<string>;
    ContactName?: FormControl<string>;
    ContactAddress?: FormControl<string>;
}

export class CreateWaterAccountFromSuggestionDtoFormControls { 
    public static ParcelIDList = (value: FormControlState<Array<number>> | Array<number> = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<Array<number>>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
            ],
        }
    );
    public static EffectiveYear = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
            ],
        }
    );
    public static WaterAccountName = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
                Validators.minLength(1),
            ],
        }
    );
    public static ContactName = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static ContactAddress = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
}
