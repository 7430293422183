import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ColDef, GridApi, GridReadyEvent, RowNode } from 'ag-grid-community';
import { UtilityFunctionsService } from 'src/app/shared/services/utility-functions.service';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { Observable, Subscription, forkJoin, switchMap, tap } from 'rxjs';
import { SelectedGeographyService } from 'src/app/shared/services/selected-geography.service';
import { ModalService, ModalSizeEnum, ModalThemeEnum } from 'src/app/shared/services/modal/modal.service';
import { UpdateWaterAccountInfoComponent, WaterAccountContext } from 'src/app/shared/components/water-account/modals/update-water-account-info/update-water-account-info.component';
import { MergeWaterAccountsComponent } from 'src/app/shared/components/water-account/modals/merge-water-accounts/merge-water-accounts.component';
import { DeleteWaterAccountComponent } from 'src/app/shared/components/water-account/modals/delete-water-account/delete-water-account.component';
import { UpdateParcelsComponent } from 'src/app/shared/components/water-account/modals/update-parcels/update-parcels.component';
import { CreateWaterAccountComponent } from 'src/app/shared/components/water-account/modals/create-water-account/create-water-account.component';
import { CustomAttributeSimpleDto, WaterAccountDto, WaterAccountManageGridDto, ZoneGroupMinimalDto } from 'src/app/shared/generated/model/models';
import { CustomAttributeService } from 'src/app/shared/generated/api/custom-attribute.service';
import { CustomAttributeTypeEnum } from 'src/app/shared/generated/enum/custom-attribute-type-enum';
import { ZoneGroupService } from 'src/app/shared/generated/api/zone-group.service';
import { NgIf, AsyncPipe } from '@angular/common';
import { AlertDisplayComponent } from '../../shared/components/alert-display/alert-display.component';
import { LoadingDirective } from '../../shared/directives/loading.directive';
import { PageHeaderComponent } from 'src/app/shared/components/page-header/page-header.component';
import { QanatGridComponent } from 'src/app/shared/components/qanat-grid/qanat-grid.component';
import { WaterAccountByGeographyService } from 'src/app/shared/generated/api/water-account-by-geography.service';

@Component({
  selector: 'water-accounts',
  templateUrl: './water-accounts.component.html',
  styleUrls: ['./water-accounts.component.scss'],
  standalone: true,
  imports: [PageHeaderComponent, LoadingDirective, AlertDisplayComponent, NgIf, QanatGridComponent, AsyncPipe]
})
export class WaterAccountsComponent implements OnInit, OnDestroy {
  private selectedGeography$: Subscription = Subscription.EMPTY;
  public geographyID: number;

  private gridApi: GridApi;
  public columnDefs: ColDef<WaterAccountManageGridDto>[];
  public colIDsToExclude = ['0'];

  public waterAccounts$: Observable<WaterAccountManageGridDto[]>;

  public richTextTypeID: number = CustomRichTextTypeEnum.WaterAccounts;
  public isLoading: boolean = true;
  public selectedWaterAccount: WaterAccountDto = null;

  constructor(
    private waterAccountByGeographyService: WaterAccountByGeographyService,
    private utilityFunctionsService: UtilityFunctionsService,
    private selectedGeographyService: SelectedGeographyService,
    private modalService: ModalService,
    private viewContainerRef: ViewContainerRef,
    private customAttributeService: CustomAttributeService,
    private zoneGroupService: ZoneGroupService
  ) { }

  ngOnDestroy(): void {
    this.selectedGeography$.unsubscribe();
  }

  ngOnInit(): void {
    this.selectedGeography$ = this.selectedGeographyService.curentUserSelectedGeographyObservable.subscribe(geography => {
      this.geographyID = geography.GeographyID;
      this.getDataForGeographyID(this.geographyID);
    });
  }

  public onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  private getDataForGeographyID(geographyID: number): void {

    this.waterAccounts$ = forkJoin([
      this.customAttributeService.geographiesGeographyIDCustomAttributesCustomAttributeTypeIDGet(geographyID, CustomAttributeTypeEnum.WaterAccount),
      this.zoneGroupService.geographiesGeographyIDZoneGroupsGet(this.geographyID)
    ]).pipe(
      tap(([customAttributes, zoneGroups]) => this.createColumnDefs(customAttributes, zoneGroups)),
      switchMap(() => this.waterAccountByGeographyService.geographiesGeographyIDWaterAccountsGet(geographyID)),
      tap(() => this.isLoading = false)
    );
  }

  private createColumnDefs(customAttributes: CustomAttributeSimpleDto[], zoneGroups: ZoneGroupMinimalDto[]) {
    this.columnDefs = [
      this.utilityFunctionsService.createActionsColumnDef((params: any) => {
        return [
          { ActionName: 'Update Parcels', ActionIcon: 'fas fa-map', ActionHandler: () => this.updateParcelsModal(params.data.WaterAccountID, params.node) },
          { ActionName: 'Update Info', ActionIcon: 'fas fa-info-circle', ActionHandler: () => this.updateInfoModal(params.data.WaterAccountID, params.node) },
          { ActionName: 'Merge', ActionIcon: 'fas fa-long-arrow-alt-right', ActionHandler: () => this.mergeModal(params.data.WaterAccountID) },
          { ActionName: 'Delete', ActionIcon: 'fa fa-times-circle text-danger', ActionHandler: () => this.deleteModal(params.data.WaterAccountID) },
        ];
      }),
      this.utilityFunctionsService.createLinkColumnDef('Account Number', 'WaterAccountNumber', 'WaterAccountID', {
        FieldDefinitionType: 'WaterAccount',
        FieldDefinitionLabelOverride: 'Water Account #'
      }),
      { headerName: 'Account Name', field: 'WaterAccountName' },
      { headerName: 'Contact Name', field: 'ContactName' },
      { headerName: 'Contact Address', field: 'ContactAddress' },
      { headerName: 'Users', valueGetter: params => this.getUserNames(params.data.Users) },
      this.utilityFunctionsService.createDateColumnDef('Create Date', 'CreateDate', 'short'),
      this.utilityFunctionsService.createDateColumnDef('Inactivated Date', 'InactivatedDate', 'short'),
      { headerName: 'Water Account PIN', field: 'WaterAccountPIN' },
      this.utilityFunctionsService.createDateColumnDef('Water Account PIN Last Used', 'WaterAccountPINLastUsed', 'short'),
      this.utilityFunctionsService.createDecimalColumnDef('# of Users', 'Users.length', { DecimalPlacesToDisplay: 0 }),
      this.utilityFunctionsService.createDecimalColumnDef('# of Parcels', 'Parcels.length', { DecimalPlacesToDisplay: 0 }),
      { headerName: 'Notes', field: 'Notes' },
      this.utilityFunctionsService.createMultiLinkColumnDef('APN List', 'Parcels', 'ParcelID', 'ParcelNumber', {
        InRouterLink: '../parcels'
      }),
      ...zoneGroups.map(z => {
        return this.utilityFunctionsService.createZoneGroupColumnDef(z, 'Zones');
      }),
      ...this.utilityFunctionsService.createCustomAttributeColumnDefs(customAttributes)
    ];
  }

  private getUserNames(userDtos) {
    return userDtos?.map(user => user.UserFullName);
  }

  public openCreateNewModal() {
    this.modalService.open(CreateWaterAccountComponent, this.viewContainerRef, { ModalSize: ModalSizeEnum.Medium, ModalTheme: ModalThemeEnum.Light })
      .instance.result.then(result => {
        if (result) {
          this.selectedWaterAccount = result;
        }
      });
  }

  public deleteModal(waterAccountID: number) {
    this.modalService.open(DeleteWaterAccountComponent, this.viewContainerRef, { ModalSize: ModalSizeEnum.Medium, ModalTheme: ModalThemeEnum.Light }, { WaterAccountID: waterAccountID, GeographyID: this.geographyID } as WaterAccountContext)
      .instance.result.then(result => {
        if (result) {
          const selectedData = this.gridApi.getSelectedRows();
          this.gridApi.applyTransaction({ remove: selectedData });
        }
      });
  }

  public mergeModal(waterAccountID: number) {
    this.modalService.open(MergeWaterAccountsComponent, this.viewContainerRef, { ModalSize: ModalSizeEnum.Large, ModalTheme: ModalThemeEnum.Light }, { WaterAccountID: waterAccountID, GeographyID: this.geographyID } as WaterAccountContext)
      .instance.result.then(result => {
        // todo: do something with the result if you need to
      });
  }

  public updateInfoModal(waterAccountID: number, rowNode: RowNode) {
    this.modalService.open(UpdateWaterAccountInfoComponent, this.viewContainerRef, { ModalSize: ModalSizeEnum.Medium, ModalTheme: ModalThemeEnum.Light }, { WaterAccountID: waterAccountID, GeographyID: this.geographyID } as WaterAccountContext)
      .instance.result.then(result => {
        if (result) {
          rowNode.setData(result);
        }
      });
  }

  public updateParcelsModal(waterAccountID: number, rowNode: RowNode) {
    this.modalService.open(UpdateParcelsComponent, this.viewContainerRef, { ModalSize: ModalSizeEnum.ExtraLarge, ModalTheme: ModalThemeEnum.Light }, { WaterAccountID: waterAccountID, GeographyID: this.geographyID } as WaterAccountContext)
      .instance.result.then(result => {
        if (result) {
          rowNode.setData(result);
        }
      });
  }
}
