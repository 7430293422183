<div id="WaterAccountDashboardMap" class="location-card" style="height: 700px">
    <div class="loading-indicator" [class.loading]="isLoadingWaterAccounts">
        Loading Water Accounts view...
    </div>
    <gsa-boundaries
        [map]="map"
        [layerControl]="layerControl"
        [geographyID]="geographyID"></gsa-boundaries>
    <usage-entities-layer
        [layerControl]="layerControl"
        [geographyID]="geographyID"
        [parcelIDs]="parcelIDs"
        [map]="map"></usage-entities-layer>
</div>
