<page-header [customRichTextTypeID]="customRichTextId"></page-header>
<div class="page-body">
    <app-alert-display></app-alert-display>

    <qanat-grid
        *ngIf="allWells$ | async as wells; else isLoading"
        [rowData]="wells"
        [colIDsToExclude]="['0']"
        [downloadFileName]="geography.GeographyName + '-well-registrations'"
        [columnDefs]="colDefs"></qanat-grid>

    <ng-template #isLoading>
        <div [loadingSpinner]="{ loadingHeight: 720, isLoading: true }"></div>
    </ng-template>
</div>
