import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { BoundingBoxDto } from 'src/app/shared/generated/model/bounding-box-dto';
import { LeafletHelperService } from 'src/app/shared/services/leaflet-helper.service';
import { WfsService } from 'src/app/shared/services/wfs.service';
import { environment } from 'src/environments/environment';
import * as L from 'leaflet';
import {
  Control,
  Map,
  MapOptions
} from 'leaflet';
import { GroupByPipe } from 'src/app/shared/pipes/group-by.pipe';
import { GsaBoundariesComponent } from '../leaflet/layers/gsa-boundaries/gsa-boundaries.component';
import { UsageEntitiesLayerComponent } from '../leaflet/layers/usage-entities-layer/usage-entities-layer.component';

@Component({
  selector: 'parcel-water-account-dashboard-map',
  standalone: true,
  imports: [CommonModule, GsaBoundariesComponent, UsageEntitiesLayerComponent],
  templateUrl: './parcel-water-account-dashboard-map.component.html',
  styleUrls: ['./parcel-water-account-dashboard-map.component.scss']
})
export class ParcelWaterAccountDashboardMapComponent implements OnInit {
  @Input() parcelIDs: number[];
  @Input() geographyID: number;

  // highlighted water account
  private _highlightedParcelID: number = null;
  @Input() set highlightedParcelID(value: number) {
    if (this.highlightedParcelID != value) {
      this._highlightedParcelID = value;
      this.highlightedParcelIDChange.emit(value);
      this.changedWaterAccount(value);
    }
  }
  @Output() public highlightedParcelIDChange: EventEmitter<number> = new EventEmitter<number>();
  get highlightedParcelID(): number {
    return this._highlightedParcelID;
  }

  private defaultStyle = {
    'color': '#3388ff',
    'weight': 2,
    'opacity': 0.65,
    'fillOpacity': 0.1
  };

  private highlightStyle = {
    'color': '#fcfc12',
    'weight': 2,
    'opacity': 0.65,
    'fillOpacity': 0.1
  };

  private geoserverWFSSubscription: Subscription = Subscription.EMPTY;
  public map: Map;
  public featureLayer: any;
  public layerControl: Control.Layers;
  public boundingBox: BoundingBoxDto = {
    Left: environment.parcelBoundingBoxLeft,
    Bottom: environment.parcelBoundingBoxBottom,
    Right: environment.parcelBoundingBoxRight,
    Top: environment.parcelBoundingBoxTop
  };
  public tileLayers: { [key: string]: any } = LeafletHelperService.GetDefaultTileLayers();

  constructor(
    private wfsService: WfsService,
    private groupByPipe: GroupByPipe
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.geographyID && !changes.geographyID.firstChange) {
      this.updateWaterAccountParcels(true);
    }
  }

  ngOnDestroy(): void {
    this.geoserverWFSSubscription.unsubscribe();
  }

  ngAfterContentInit(): void {
    this.initMap();
  }

  ngOnInit(): void {
    this.isLoadingWaterAccounts = true;
  }

  initMap(): void {
    const mapOptions: MapOptions = {
      minZoom: 6,
      maxZoom: 17,
      layers: [
        this.tileLayers.Aerial,
      ],
      fullscreenControl: true,
      gestureHandling: true
    } as MapOptions;
    this.map = L.map('WaterAccountDashboardMap', mapOptions);
    this.map.fitBounds([[this.boundingBox.Bottom, this.boundingBox.Left], [this.boundingBox.Top, this.boundingBox.Right]]);

    this.layerControl = new Control.Layers(this.tileLayers, null, { collapsed: true })
      .addTo(this.map);
    this.updateWaterAccountParcels();
  }

  private allWaterAccountsFeatureGroup = L.featureGroup();
  public isLoadingWaterAccounts: boolean;

  private updateWaterAccountParcels(fitBoundsAfterLoad: boolean = true): void {

    this.geoserverWFSSubscription.unsubscribe();
    this.isLoadingWaterAccounts = true;
    const parcelIDs = this.parcelIDs.join(',');

    let cql_filter = `ParcelID in(${parcelIDs})`;
    if (this.geographyID != null) {
      cql_filter += ` and GeographyID = ${this.geographyID}`;
    }

    this.geoserverWFSSubscription = this.wfsService.getGeoserverWFSLayer(null, 'Qanat:AllParcels', cql_filter)
      .subscribe(response => {
        const featuresGroupedByWaterAccountID = this.groupByPipe.transform(response, 'properties.ParcelID');
        this.allWaterAccountsFeatureGroup.clearLayers();

        Object.keys(featuresGroupedByWaterAccountID).forEach(parcelID => {
          const geoJson = L.geoJSON(featuresGroupedByWaterAccountID[parcelID], {
            style: this.defaultStyle
          });

          //IMPORTANT: THIS ONLY WORKS BECAUSE I'VE INSTALLED @angular/elements AND CONFIGURED THIS IN THE app.module.ts bootstrapping
          geoJson.bindPopup(`<parcel-popup-custom-element parcel-id="${parcelID}"></parcel-popup-custom-element>`, {
            maxWidth: 475,
            keepInView: true
          });

          geoJson.on('mouseover', (e) => {
            geoJson.setStyle({ 'fillOpacity': 0.5 });
          });
          geoJson.on('mouseout', (e) => {
            geoJson.setStyle({ 'fillOpacity': 0.1 });
          });

          geoJson.on('click', (e) => {
            this.changedWaterAccount(Number(parcelID));
          });

          this.allWaterAccountsFeatureGroup.addLayer(geoJson);
        });

        this.allWaterAccountsFeatureGroup.addTo(this.map);
        this.layerControl.addOverlay(this.allWaterAccountsFeatureGroup, '<span style=\'display:inline-flex; gap:5px;\'><span style=\'width:15px;height:16px;background:#3388ff30;display: inline-block;border: 2px solid #3388ff;\'></span>My Water Accounts</span>');
        if (fitBoundsAfterLoad) {
          this.map.fitBounds(this.allWaterAccountsFeatureGroup.getBounds());
        }
        this.isLoadingWaterAccounts = false;
      });
  }

  changedWaterAccount(waterAccountID: number) {
    this.highlightedParcelID = waterAccountID;

    // clear styles
    this.allWaterAccountsFeatureGroup.setStyle(this.defaultStyle);

    // loop through the allWaterAccountsFeatureGroup
    this.allWaterAccountsFeatureGroup.eachLayer((layer) => {

      const geoJsonLayers = layer.getLayers();
      if (geoJsonLayers[0].feature.properties.ParcelID == waterAccountID) {
        layer.setStyle(this.highlightStyle);
        layer.openPopup();
      }
    });
  }

}
