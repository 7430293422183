/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { FormControl, FormControlOptions, FormControlState, Validators } from "@angular/forms";
export class MonthlyUsageSummaryDto { 
    EffectiveDate?: string;
    EffectiveMonth?: number;
    CurrentUsageAmount?: number;
    AverageUsageAmount?: number;
    CurrentCumulativeUsageAmount?: number;
    AverageCumulativeUsageAmount?: number;
    CurrentUsageAmountDepth?: number;
    AverageUsageAmountDepth?: number;
    CurrentCumulativeUsageAmountDepth?: number;
    AverageCumulativeUsageAmountDepth?: number;
    TotalSupply?: number;
    TotalSupplyDepth?: number;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export interface MonthlyUsageSummaryDtoForm { 
    EffectiveDate?: FormControl<string>;
    EffectiveMonth?: FormControl<number>;
    CurrentUsageAmount?: FormControl<number>;
    AverageUsageAmount?: FormControl<number>;
    CurrentCumulativeUsageAmount?: FormControl<number>;
    AverageCumulativeUsageAmount?: FormControl<number>;
    CurrentUsageAmountDepth?: FormControl<number>;
    AverageUsageAmountDepth?: FormControl<number>;
    CurrentCumulativeUsageAmountDepth?: FormControl<number>;
    AverageCumulativeUsageAmountDepth?: FormControl<number>;
    TotalSupply?: FormControl<number>;
    TotalSupplyDepth?: FormControl<number>;
}

export class MonthlyUsageSummaryDtoFormControls { 
    public static EffectiveDate = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static EffectiveMonth = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static CurrentUsageAmount = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static AverageUsageAmount = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static CurrentCumulativeUsageAmount = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static AverageCumulativeUsageAmount = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static CurrentUsageAmountDepth = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static AverageUsageAmountDepth = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static CurrentCumulativeUsageAmountDepth = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static AverageCumulativeUsageAmountDepth = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static TotalSupply = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static TotalSupplyDepth = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
}
