/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { FormControl, FormControlOptions, FormControlState, Validators } from "@angular/forms";
export class WaterAccountUpdateDto { 
    WaterAccountName?: string;
    ContactName: string;
    ContactAddress: string;
    Notes?: string;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export interface WaterAccountUpdateDtoForm { 
    WaterAccountName?: FormControl<string>;
    ContactName: FormControl<string>;
    ContactAddress: FormControl<string>;
    Notes?: FormControl<string>;
}

export class WaterAccountUpdateDtoFormControls { 
    public static WaterAccountName = (value: FormControlState<string> | string = '', formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
                Validators.maxLength(255),
            ],
        }
    );
    public static ContactName = (value: FormControlState<string> | string = '', formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(255),
            ],
        }
    );
    public static ContactAddress = (value: FormControlState<string> | string = '', formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
                Validators.minLength(1),
                Validators.maxLength(500),
            ],
        }
    );
    public static Notes = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
}
