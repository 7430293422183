<div class="water-dashboard-nav">
    <span class="nav-prefix">Navigate to:</span>
    <div class="links">
        <a
            [routerLink]="['/water-dashboard', 'water-accounts']"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }">
            <icon icon="WaterAccounts"></icon>
            Water Accounts
        </a>
        <a [routerLink]="['/water-dashboard', 'parcels']" routerLinkActive="active">
            <icon icon="Parcels"></icon>
            Parcels
        </a>
        <a [routerLink]="['/water-dashboard', 'wells']" routerLinkActive="active">
            <icon icon="Wells"></icon>
            Wells
        </a>
    </div>
</div>
