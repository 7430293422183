//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[FuelType]

export enum FuelTypeEnum {
  Electric = 1,
  Diesel = 2,
  LPGas = 3,
  Other = 4
}
