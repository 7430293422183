/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { WaterAccountRequestChangesDto } from '././water-account-request-changes-dto';
import { WaterAccountRequestChangesParcelDto } from '././water-account-request-changes-parcel-dto';

import { FormControl, FormControlOptions, FormControlState, Validators } from "@angular/forms";
export class WaterAccountParcelsRequestChangesDto { 
    WaterAccounts?: Array<WaterAccountRequestChangesDto>;
    ParcelsToRemove?: Array<WaterAccountRequestChangesParcelDto>;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export interface WaterAccountParcelsRequestChangesDtoForm { 
    WaterAccounts?: FormControl<Array<WaterAccountRequestChangesDto>>;
    ParcelsToRemove?: FormControl<Array<WaterAccountRequestChangesParcelDto>>;
}

export class WaterAccountParcelsRequestChangesDtoFormControls { 
    public static WaterAccounts = (value: FormControlState<Array<WaterAccountRequestChangesDto>> | Array<WaterAccountRequestChangesDto> = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<Array<WaterAccountRequestChangesDto>>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static ParcelsToRemove = (value: FormControlState<Array<WaterAccountRequestChangesParcelDto>> | Array<WaterAccountRequestChangesParcelDto> = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<Array<WaterAccountRequestChangesParcelDto>>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
}
