<div
    id="{{ mapID }}"
    class="location-card"
    [class.selecting-location]="selecting"
    style="height: 500px"></div>

<div class="copy copy-3 pt-2" style="font-style: italic">
    Wells and Observation Points can be dragged to adjust their location.
</div>

<ng-content [map]="map"></ng-content>
