<page-header *ngIf="waterAccount$ | async as waterAccount" [pageTitle]="'#' + waterAccount.WaterAccountNumber"
    [templateTitleAppend]="templateTitleAppend" icon="WaterAccounts" preTitle="Parcels">
    <ng-template #templateTitleAppend>
        <span class="water-account-name" *ngIf="waterAccount.WaterAccountName?.length > 0"
            title="{{ waterAccount.WaterAccountName }}">
            {{ waterAccount.WaterAccountName }}
        </span>
        <name-tag *ngIf="allocationPlans?.length > 0" [name]="allocationPlans[0].ZoneName"
            [color]="allocationPlans[0].ZoneColor" [routerLink]="[
                '/geographies',
                waterAccount.Geography.GeographyName.toLowerCase(),
                'allocation-plans',
                allocationPlans[0].WaterTypeSlug,
                allocationPlans[0].ZoneSlug
            ]" class="zone-tag" title="Allocation Plan Zone"></name-tag>
    </ng-template>
</page-header>

<div class="page-body grid-12">
    <div class="g-col-12" *ngIf="selectedParcelIDs && geographyID">
        <parcel-water-account-dashboard-map [parcelIDs]="selectedParcelIDs" [geographyID]="geographyID"
            [(highlightedParcelID)]="highlightedParcelID"></parcel-water-account-dashboard-map>
    </div>
    <div class="g-col-12">
        <h3 class="module-title underline">All Parcels</h3>
        <div class="table-wrapper">
            @if (parcels$ | async; as parcels) {
            <qanat-grid [rowData]="parcels" [columnDefs]="columnDefs" downloadFileName="parcels" rowSelection="single"
                (gridReady)="onGridReady($event)" (selectionChanged)="onSelectionChanged($event)" [height]="'500px'"
                (filterChanged)="selectHighlightedParcelIDRowNode()" [pagination]="true"></qanat-grid>
            }
        </div>
    </div>
</div>