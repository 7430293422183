import { Component } from '@angular/core';

@Component({
    selector: 'water-levels-configure',
    templateUrl: './water-levels-configure.component.html',
    styleUrls: ['./water-levels-configure.component.scss'],
    standalone: true
})
export class WaterLevelsConfigureComponent{
}
