<page-header icon="WaterAccounts" [customRichTextTypeID]="customRichTextTypeID"
    pageTitle="Water Account Suggestions"></page-header>

<div class="page-body" [loadingSpinner]="{ isLoading: isLoadingSuggestions }">
    <app-alert-display></app-alert-display>

    <section class="suggested-water-accounts">
        <ng-container *ngIf="waterAccountSuggestions">
            <h2 class="section-title suggested-water-accounts__title">
                Suggested New Water Accounts
                <span class="badge">{{ waterAccountSuggestions.length | number }}</span>
            </h2>

            <qanat-grid [rowData]="waterAccountSuggestions" [columnDefs]="columnDefs"
                downloadFileName="suggested-water-accounts" [colIDsToExclude]="colIDsToExclude" rowSelection="multiple"
                [suppressRowClickSelection]="true" [pagination]="true" (gridReady)="onGridReady($event)"
                (selectionChanged)="getSelectedRows($event)">
                <div customGridActions class="custom-grid-actions">
                    <button (click)="bulkApprove()" [buttonLoading]="isLoadingSubmit" class="btn btn-sm btn-success"
                        [disabled]="isLoadingSubmit || selectedRows.length === 0">
                        Approve
                    </button>

                    <button (click)="bulkReject()" [buttonLoading]="isLoadingSubmit" class="btn btn-sm btn-danger"
                        [disabled]="isLoadingSubmit || selectedRows.length === 0">
                        Reject
                    </button>
                </div>
            </qanat-grid>
        </ng-container>
    </section>
</div>