<div class="full-width-page">
    <page-header
        [customRichTextTypeID]="richTextID"
        icon="Parcels"
        pageTitle="Parcels"></page-header>
    <water-dashboard-navigation></water-dashboard-navigation>

    <div class="page-body" [loadingSpinner]="{ isLoading: isLoading, loadingHeight: 500 }">
        <water-dashboard-parcels
            *ngIf="parcels$ | async as parcels"
            [parcels]="parcels"></water-dashboard-parcels>
    </div>
</div>
