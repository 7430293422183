/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MeterStatusSimpleDto } from '././meter-status-simple-dto';
import { WellLinkDisplayDto } from '././well-link-display-dto';

import { FormControl, FormControlOptions, FormControlState, Validators } from "@angular/forms";
export class MeterGridDto { 
    MeterID?: number;
    SerialNumber: string;
    DeviceName?: string;
    Make?: string;
    ModelNumber?: string;
    GeographyID: number;
    MeterStatusID: number;
    MeterStatus?: MeterStatusSimpleDto;
    WellIDs?: Array<WellLinkDisplayDto>;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export interface MeterGridDtoForm { 
    MeterID?: FormControl<number>;
    SerialNumber: FormControl<string>;
    DeviceName?: FormControl<string>;
    Make?: FormControl<string>;
    ModelNumber?: FormControl<string>;
    GeographyID: FormControl<number>;
    MeterStatusID: FormControl<number>;
    MeterStatus?: FormControl<MeterStatusSimpleDto>;
    WellIDs?: FormControl<Array<WellLinkDisplayDto>>;
}

export class MeterGridDtoFormControls { 
    public static MeterID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static SerialNumber = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
                Validators.minLength(1),
            ],
        }
    );
    public static DeviceName = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static Make = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static ModelNumber = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static GeographyID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
            ],
        }
    );
    public static MeterStatusID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: true,
            validators: 
            [
                Validators.required,
            ],
        }
    );
    public static MeterStatus = (value: FormControlState<MeterStatusSimpleDto> | MeterStatusSimpleDto = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<MeterStatusSimpleDto>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WellIDs = (value: FormControlState<Array<WellLinkDisplayDto>> | Array<WellLinkDisplayDto> = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<Array<WellLinkDisplayDto>>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
}
