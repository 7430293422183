import { Component, OnInit } from '@angular/core';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { UtilityFunctionsService } from 'src/app/shared/services/utility-functions.service';
import { Observable, switchMap, tap } from 'rxjs';
import { GeographyDto } from 'src/app/shared/generated/model/geography-dto';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { SelectedGeographyService } from 'src/app/shared/services/selected-geography.service';
import { WellMinimalDto } from 'src/app/shared/generated/model/models';
import { WellService } from 'src/app/shared/generated/api/well.service';
import { LoadingDirective } from '../../shared/directives/loading.directive';
import { ParcelMapComponent } from '../../shared/components/parcel-map/parcel-map.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { AlertDisplayComponent } from '../../shared/components/alert-display/alert-display.component';
import { RouterLink } from '@angular/router';
import { PageHeaderComponent } from 'src/app/shared/components/page-header/page-header.component';
import { QanatGridComponent } from 'src/app/shared/components/qanat-grid/qanat-grid.component';

@Component({
  selector: 'well-list',
  templateUrl: './well-list.component.html',
  styleUrls: ['./well-list.component.scss'],
  standalone: true,
  imports: [PageHeaderComponent, RouterLink, AlertDisplayComponent, NgIf, ParcelMapComponent, QanatGridComponent, LoadingDirective, AsyncPipe]
})
export class WellListComponent implements OnInit {

  public geography: GeographyDto;
  private gridApi: GridApi;
  public wells: WellMinimalDto[];
  public wells$: Observable<WellMinimalDto[]>;
  public richTextTypeID: number = CustomRichTextTypeEnum.Wells;

  public columnDefs: ColDef[];
  public mapCqlFilter: string;
  public highlightedWellDto: WellMinimalDto;

  private _highlightedWellID: number;
  set highlightedWellID(value: number) {
    this._highlightedWellID = value;
    this.highlightedWellDto = this.wells.filter(x => x.WellID == value)[0];
    this.selectHighlightedWellIDRowNode();
  }

  get highlightedWellID(): number {
    return this._highlightedWellID;
  }

  constructor(
    private utilityFunctionsService: UtilityFunctionsService,
    private wellService: WellService,
    private selectedGeographyService: SelectedGeographyService
  ) { }

  ngOnInit(): void {

    this.wells$ = this.selectedGeographyService.curentUserSelectedGeographyObservable.pipe(
      switchMap(geography => {
        this.geography = geography;
        this.mapCqlFilter = `GeographyID = ${geography.GeographyID}`;
        return this.wellService.geographiesGeographyIDWellsGet(geography.GeographyID);
      }),
      tap(wells => {
        this.wells = wells;
        this.createColumnDefs();
      })
    );
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
  }

  public selectHighlightedWellIDRowNode() {
    this.gridApi.forEachNodeAfterFilterAndSort((rowNode, index) => {
      if (rowNode.data.WellID == this.highlightedWellID) {
        rowNode.setSelected(true);
        this.gridApi.ensureIndexVisible(index, 'top');
      }
    });
  }

  public createColumnDefs() {
    this.columnDefs = [
      this.utilityFunctionsService.createLinkColumnDef('Well ID', 'WellID', 'WellID'),
      this.utilityFunctionsService.createLinkColumnDef('Well Name', 'WellName', 'WellID'),
      this.utilityFunctionsService.createLinkColumnDef('Default APN', 'ParcelNumber', 'ParcelID'),
      this.utilityFunctionsService.createMultiLinkColumnDef('Irrigates', 'IrrigatesParcels', 'ParcelID', 'ParcelNumber', {
        InRouterLink: '../parcels', MaxWidth: 300
      }),
      this.utilityFunctionsService.createBasicColumnDef('County Well Permit #', 'CountyWellPermitNumber', { FieldDefinitionType: 'CountyWellPermitNo' }),
      this.utilityFunctionsService.createBasicColumnDef('State WCR #', 'StateWCRNumber', { FieldDefinitionType: 'StateWCRNo' }),
      this.utilityFunctionsService.createDateColumnDef('DateDrilled', 'DateDrilled', 'M/d/yyyy', { FieldDefinitionType: 'DateDrilled' }),
      this.utilityFunctionsService.createDecimalColumnDef('Well Depth', 'WellDepth', { FieldDefinitionType: 'WellDepth' }),
      this.utilityFunctionsService.createBasicColumnDef('Well Status', 'WellStatusDisplayName', {
        FieldDefinitionType: 'WellStatus',
        CustomDropdownFilterField: 'WellStatusDisplayName'
      }),
      this.utilityFunctionsService.createLatLonColumnDef('Latitude', 'Latitude'),
      this.utilityFunctionsService.createLatLonColumnDef('Longitude', 'Longitude'),
      this.utilityFunctionsService.createBasicColumnDef('Notes', 'Notes')
    ];
  }

  public onSelectionChanged(event) {
    const selection = this.gridApi.getSelectedRows()[0];
    if (selection && selection.WellID) {
      this.highlightedWellID = selection.WellID;
    }
  }

  public onMapSelection(wellID: number) {
    this.gridApi.deselectAll();
    this.gridApi.forEachNode(node => {
      if (node.data.WellID == wellID) {
        node.setSelected(true);
      }
    });
  }

}
