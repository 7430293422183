import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { SelectedGeographyService } from 'src/app/shared/services/selected-geography.service';
import { Observable, map, share, switchMap, tap } from 'rxjs';
import { GeographyDto } from 'src/app/shared/generated/model/geography-dto';
import { slideAnimation } from 'src/app/shared/animations/slide.animation';
import { CustomAttributeSimpleDto, ParcelDetailDto, WaterAccountDto, WellDetailDto } from 'src/app/shared/generated/model/models';
import { DashboardMenu, DashboardMenuComponent, DashboardMenuItem } from '../dashboard-menu/dashboard-menu.component';
import { ViewingDetailMenuService } from 'src/app/shared/services/viewing-detail-menu.service';
import { CustomAttributeService } from 'src/app/shared/generated/api/custom-attribute.service';
import { CustomAttributeTypeEnum } from 'src/app/shared/generated/enum/custom-attribute-type-enum';
import { IconComponent } from '../icon/icon.component';


@Component({
  selector: 'dashboard-manage-nav',
  standalone: true,
  imports: [CommonModule, DashboardMenuComponent, IconComponent],
  templateUrl: './dashboard-manage-nav.component.html',
  styleUrls: ['./dashboard-manage-nav.component.scss'],
  animations: [slideAnimation]

})
export class DashboardManageNavComponent implements OnInit {
  public dashboardMenu$: Observable<DashboardMenu>;
  public parcelMenu$: Observable<DashboardMenu>;
  public waterAccountMenu$: Observable<DashboardMenu>;
  public wellMenu$: Observable<DashboardMenu>;
  public viewingDetailPage: boolean = false;

  constructor(
    private selectedGeographyService: SelectedGeographyService,
    private router: Router,
    private viewingMenuDetailService: ViewingDetailMenuService,
    private customAttributeService: CustomAttributeService
  ) {

  }

  ngOnInit(): void {
    this.dashboardMenu$ = this.selectedGeographyService.curentUserSelectedGeographyObservable.pipe(
      map(geography => this.buildMenu(geography)),
      share()
    );

    this.parcelMenu$ = this.selectedGeographyService.curentUserSelectedGeographyObservable.pipe(
      switchMap(geography => {
        return this.viewingMenuDetailService.currentParcel$.pipe(
          tap(parcel => {
            this.viewingDetailPage = parcel != null;
          }),
          switchMap(parcelDetailDto => {
            return this.customAttributeService.geographiesGeographyIDCustomAttributesCustomAttributeTypeIDGet(geography.GeographyID, CustomAttributeTypeEnum.Parcel).pipe(
              map(customAttributes => this.buildParcelMenu(geography, parcelDetailDto, customAttributes))
            );
          }),

        );
      })
    );

    this.waterAccountMenu$ = this.selectedGeographyService.curentUserSelectedGeographyObservable.pipe(
      switchMap(geography => {
        return this.viewingMenuDetailService.currentWaterAccount$.pipe(
          map(waterAccount => this.buildWaterAccountMenu(geography, waterAccount)),
          tap(waterAccount => {
            this.viewingDetailPage = waterAccount != null;
          })
        );
      })
    );

    this.wellMenu$ = this.selectedGeographyService.curentUserSelectedGeographyObservable.pipe(
      switchMap(geography => {
        return this.viewingMenuDetailService.currentWell$.pipe(
          map(well => this.buildWellMenu(geography, well)),
          tap(well => {
            this.viewingDetailPage = well != null;
          })
        );
      })
    );
  }

  buildMenu(geography: GeographyDto): DashboardMenu {
    const geographySlug = geography.GeographyName.toLowerCase();
    const menu = {
      menuItems: [
        {
          title: 'Activity Center',
          icon: 'ActivityCenter',
          routerLink: ['/manage', geographySlug, 'activity-center'],
          isDropdown: false
        },
        {
          title: 'Water Accounts',
          icon: 'WaterAccounts',
          routerLink: ['/manage', geographySlug, 'activity-center'],
          isDropdown: true,
          menuItems: [
            {
              title: 'All Water Accounts',
              routerLink: ['/manage', geographySlug, 'water-accounts'],
            },
            {
              title: 'Water Account Suggestions',
              routerLink: ['/manage', geographySlug, 'water-accounts', 'water-account-suggestions'],
            },

          ]
        },
        {
          title: 'Parcels',
          icon: 'Parcels',
          routerLink: ['/manage', geographySlug, 'parcels'],
          isDropdown: true,
          menuItems: [
            {
              title: 'All Parcels',
              routerLink: ['/manage', geographySlug, 'parcels'],
            },
            {
              title: 'Update Parcels',
              routerLink: ['/manage', geographySlug, 'parcels', 'update'],
            },
            {
              title: 'Water Measurements',
              routerLink: ['/manage', geographySlug, 'water-measurements'],
            },
            {
              title: 'Water Transactions',
              routerLink: ['/manage', geographySlug, 'water-transactions'],
            },
            {
              title: 'OpenET Integration',
              routerLink: ['/manage', geographySlug, 'openet-integration'],
              isDisabled: !geography.IsOpenETActive
            },
            {
              title: 'Assign Parcels to Zones',
              routerLink: ['/manage', geographySlug, 'zones', 'upload'],
            },
            {
              title: 'Allocation Plans',
              routerLink: ['/manage', geographySlug, 'parcels', 'allocation-plans'],
              routerLinkActiveOptions: {
                matrixParams: 'ignored',
                queryParams: 'ignored',
                fragment: 'exact',
                paths: 'subset'
              },
              isDisabled: !geography.AllocationPlansEnabled
            },
            {
              title: 'Bulk Actions',
              routerLink: ['/manage', geographySlug, 'parcels', 'bulk-actions'],
            }
          ]
        },
        {
          title: 'Wells',
          icon: 'Wells',
          routerLink: ['/manage', geographySlug, 'wells'],
          isDropdown: true,
          menuItems: [
            {
              title: 'All Wells',
              routerLink: ['/manage', geographySlug, 'wells'],
            },
            {
              title: 'Review Submitted Wells',
              routerLink: ['/manage', geographySlug, 'wells', 'review-submitted-wells'],
              isDisabled: !geography.GeographyConfiguration.WellRegistryEnabled
            },
            {
              title: 'All Well Registrations',
              routerLink: ['/manage', geographySlug, 'wells', 'well-registrations'],
              isDisabled: !geography.GeographyConfiguration.WellRegistryEnabled
            },
            {
              title: 'Reference Wells',
              routerLink: ['/manage', geographySlug, 'wells', 'reference-wells'],
              isDisabled: !geography.GeographyConfiguration.WellRegistryEnabled
            },
            {
              title: 'Meters',
              routerLink: ['/manage', geographySlug, 'wells', 'meters'],
              isDisabled: !geography.GeographyConfiguration.MetersEnabled
            }
          ]
        },
      ]
    } as DashboardMenu;

    menu.menuItems.forEach(menuItem => {
      menuItem.menuItems?.forEach(childItem => {
        const urltree = this.router.createUrlTree(childItem.routerLink as any[]);
        const childRouteIsActive = this.router.isActive(urltree, childItem.routerLinkActiveOptions ? childItem.routerLinkActiveOptions : { paths: 'exact', queryParams: 'ignored', matrixParams: 'ignored' } as IsActiveMatchOptions);
        if (childRouteIsActive) {
          menuItem.isExpanded = true;
        }
      });
    });

    return menu;
  }

  buildParcelMenu(geography: GeographyDto, parcel: ParcelDetailDto, customAttributes: CustomAttributeSimpleDto[]): DashboardMenu {
    if (parcel == null) return null;
    const geographySlug = geography.GeographyName.toLowerCase();
    const menu = {
      menuItems: [
        {
          title: parcel.ParcelNumber,
          icon: 'Parcels',
          routerLink: ['/manage', geographySlug, 'parcels', parcel.ParcelID],
          isDropdown: true,
          preventCollapse: true,
          isExpanded: true,
          menuItems: [
            {
              title: 'Parcel Details',
              routerLink: ['/manage', geographySlug, 'parcels', parcel.ParcelID],
              fragment: 'parcel_details_anchor'
            },
            {
              title: 'Water Measurements',
              routerLink: ['/manage', geographySlug, 'parcels', parcel.ParcelID],
              fragment: 'water_measurements_anchor'
            },
            {
              title: 'Supply Activity',
              routerLink: ['/manage', geographySlug, 'parcels', parcel.ParcelID],
              fragment: 'ledger_activity_anchor'
            },
            {
              title: 'Ownership History',
              routerLink: ['/manage', geographySlug, 'parcels', parcel.ParcelID],
              fragment: 'ownership_history_anchor'
            },
            {
              title: 'Water Account History',
              routerLink: ['/manage', geographySlug, 'parcels', parcel.ParcelID],
              fragment: 'water_account_history_anchor'
            },
            {
              title: 'Back to All Parcels',
              icon: 'ArrowLeft',
              routerLink: ['/manage', geographySlug, 'parcels'],
              cssClasses: 'border-top'
            }
          ]
        },
      ]
    } as DashboardMenu;

    const customAttributesMenuItem = {
      title: 'Custom Attributes',
      routerLink: ['/manage', geographySlug, 'parcels', parcel.ParcelID],
      fragment: 'custom_attributes_anchor'
    };

    if (customAttributes.length > 0) {
      menu.menuItems[0].menuItems.splice(2, 0, customAttributesMenuItem as DashboardMenuItem);
    }

    return menu;
  }

  buildWaterAccountMenu(geography: GeographyDto, waterAccount: WaterAccountDto): DashboardMenu {
    if (waterAccount == null) return null;
    const geographySlug = geography.GeographyName.toLowerCase();
    const menu = {
      menuItems: [
        {
          title: waterAccount.WaterAccountNameAndNumber,
          icon: 'WaterAccounts',
          routerLink: ['/manage', geographySlug, 'water-accounts', waterAccount.WaterAccountID],
          isDropdown: true,
          preventCollapse: true,
          isExpanded: true,
          menuItems: [
            {
              title: 'Basics',
              routerLink: ['/manage', geographySlug, 'water-accounts', waterAccount.WaterAccountID],
              fragment: 'water_account_details_anchor'
            },
            {
              title: 'Back to All Water Accounts',
              icon: 'ArrowLeft',
              routerLink: ['/manage', geographySlug, 'water-accounts'],
              cssClasses: 'border-top'
            }
          ]
        },
      ]
    } as DashboardMenu;
    return menu;
  }

  buildWellMenu(geography: GeographyDto, well: WellDetailDto): DashboardMenu {
    if (well == null) return null;
    const geographySlug = geography.GeographyName.toLowerCase();
    const wellID = well.WellID;
    const menu = {
      menuItems: [
        {
          title: well.WellName,
          icon: 'Wells',
          routerLink: [`/manage/${geographySlug}/wells`, wellID],
          isDropdown: true,
          isExpanded: true,
          menuItems: [
            {
              title: 'Well Details',
              routerLink: [`/manage/${geographySlug}/wells`, wellID]
            },
            {
              title: 'Meter',
              routerLink: [`/manage/${geographySlug}/wells`, wellID],
              fragment: 'meter',
              isDisabled: !well.MetersEnabled
            },
            {
              title: 'Update Location',
              routerLink: [`/manage/${geographySlug}/wells`, wellID, 'update-location'],
            },
            {
              title: 'Update Irrigated Parcels',
              routerLink: [`/manage/${geographySlug}/wells`, wellID, 'update-irrigated-parcels'],
            },
            {
              title: 'Back to All Wells',
              icon: 'ArrowLeft',
              routerLink: [`/manage/${geographySlug}/wells`],
              cssClasses: 'border-top'
            }
          ]
        }
      ]
    } as DashboardMenu;
    return menu;

  }
}

