<div>
    <page-header
        [customRichTextTypeID]="richTextTypeID"
        [pageTitle]="
            isCreating ? 'Add Geospatial Data Layer' : 'Edit Geospatial Data Layer'
        "></page-header>

    <div class="page-body grid-12">
        <app-alert-display></app-alert-display>
        <form class="form" [formGroup]="formGroup" *ngIf="externalMapLayer$ | async">
            <div class="grid-12">
                <form-field
                    class="g-col-6"
                    [formControl]="formGroup.controls.ExternalMapLayerDisplayName"
                    [required]="true"
                    fieldLabel="Layer Name"
                    [fieldDefinitionName]="'ExternalMapLayersName'"
                    [type]="FormFieldType.Text"></form-field>
                <form-field
                    *ngIf="externalMapLayerTypes$ | async as options"
                    class="g-col-6"
                    [required]="true"
                    [formControl]="formGroup.controls.ExternalMapLayerTypeID"
                    fieldLabel="Layer Type"
                    [fieldDefinitionName]="'ExternalMapLayersType'"
                    [formInputOptions]="options"
                    [type]="FormFieldType.Select"></form-field>
                <ng-container
                    *ngIf="formGroup.controls.ExternalMapLayerTypeID.value === esriFeatureServerID">
                    <form-field
                        class="g-col-6"
                        [formControl]="formGroup.controls.PopUpField"
                        fieldLabel="PopUp Field"
                        [fieldDefinitionName]="'PopUpField'"
                        [type]="FormFieldType.Text"></form-field>
                    <form-field
                        class="g-col-6"
                        [required]="true"
                        [formControl]="formGroup.controls.MinZoom"
                        fieldLabel="Minimum Zoom"
                        [fieldDefinitionName]="'ExternalMapLayersMinimumZoom'"
                        [type]="FormFieldType.Number"></form-field>
                </ng-container>
                <form-field
                    class="g-col-12"
                    [required]="true"
                    [formControl]="formGroup.controls.ExternalMapLayerURL"
                    fieldLabel="URL"
                    [type]="FormFieldType.Text"></form-field>
                <form-field
                    class="g-col-12"
                    [formControl]="formGroup.controls.ExternalMapLayerDescription"
                    fieldLabel="Description"
                    [type]="FormFieldType.Text"></form-field>
                <form-field
                    class="g-col-6"
                    [formControl]="formGroup.controls.LayerIsOnByDefault"
                    fieldLabel="Visible By Default?"
                    [formInputOptions]="layerIsOnByDefaultOptions"
                    [type]="FormFieldType.Radio"></form-field>
                <form-field
                    class="g-col-6"
                    [formControl]="formGroup.controls.IsActive"
                    fieldLabel="Is Active?"
                    [formInputOptions]="isActiveOptions"
                    [type]="FormFieldType.Radio"></form-field>
            </div>
        </form>
    </div>
    <div class="page-footer">
        <button
            type="button"
            class="btn btn-primary mr-2"
            (click)="onSubmit()"
            [disabled]="isLoadingSubmit">
            <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
            Save
        </button>
        <a *ngIf="isCreating; else isEditing" routerLink="../" class="btn btn-primary-outline">
            Cancel
        </a>
        <ng-template #isEditing>
            <a routerLink="../../" class="btn btn-primary-outline">Cancel</a>
        </ng-template>
    </div>
</div>
