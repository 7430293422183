import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParcelDetailDto } from 'src/app/shared/generated/model/parcel-detail-dto';
import { WaterAccountTitleComponent } from 'src/app/shared/components/water-account/water-account-title/water-account-title.component';
import { GeographyDto } from 'src/app/shared/generated/model/models';
import { ModalService, ModalSizeEnum, ModalThemeEnum } from 'src/app/shared/services/modal/modal.service';
import { ParcelUpdateWaterAccountModalComponent } from '../parcel-update-water-account-modal/parcel-update-water-account-modal.component';
import { ParcelEditZoneAssignmentsModalComponent } from '../parcel-edit-zone-assignments-modal/parcel-edit-zone-assignments-modal.component';
import { ParcelModifyParcelStatusModalComponent } from '../parcel-modify-parcel-status-modal/parcel-modify-parcel-status-modal.component';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { ParcelUpdateOwnershipInfoModalComponent } from '../parcel-update-ownership-info-modal/parcel-update-ownership-info-modal.component';
import { FieldDefinitionComponent } from 'src/app/shared/components/field-definition/field-definition.component';
import { ParcelMapComponent } from 'src/app/shared/components/parcel-map/parcel-map.component';
import { RouterLink } from '@angular/router';
import { IconComponent } from '../icon/icon.component';
import { KeyValuePairListComponent } from '../key-value-pair-list/key-value-pair-list.component';
import { KeyValuePairComponent } from '../key-value-pair/key-value-pair.component';

@Component({
  selector: 'parcel-detail-manage-card',
  standalone: true,
  imports: [CommonModule, KeyValuePairListComponent, KeyValuePairComponent, WaterAccountTitleComponent, IconComponent, FieldDefinitionComponent, ParcelMapComponent, RouterLink],
  templateUrl: './parcel-detail-manage-card.component.html',
  styleUrls: ['./parcel-detail-manage-card.component.scss']
})
export class ParcelDetailManageCardComponent {
  @Input() parcel: ParcelDetailDto;
  @Input() geography: GeographyDto;
  @Output() onParcelUpdated = new EventEmitter();
  public CustomRichTextTypeEnum = CustomRichTextTypeEnum;

  constructor(
    private modalService: ModalService
  ) { }

  updateOwnershipInfo(): void {
    this.modalService.open(ParcelUpdateOwnershipInfoModalComponent,
      null,
      { ModalSize: ModalSizeEnum.Medium, ModalTheme: ModalThemeEnum.Light, OverflowVisible: true },
      { ParcelID: this.parcel.ParcelID }).instance.result
      .then(succeeded => {
        if (succeeded) {
          this.onParcelUpdated.emit();
        }
      });
  }

  updateWaterAccount(): void {
    this.modalService.open(ParcelUpdateWaterAccountModalComponent,
      null,
      { ModalSize: ModalSizeEnum.Medium, ModalTheme: ModalThemeEnum.Light, OverflowVisible: true },
      { ParcelID: this.parcel.ParcelID, GeographyID: this.parcel.GeographyID }).instance.result
      .then(succeeded => {
        if (succeeded) {
          this.onParcelUpdated.emit();
        }
      });
  }

  editZoneAssignments(): void {
    this.modalService.open(ParcelEditZoneAssignmentsModalComponent,
      null,
      { ModalSize: ModalSizeEnum.Medium, ModalTheme: ModalThemeEnum.Light, OverflowVisible: true },
      { ParcelID: this.parcel.ParcelID, GeographyID: this.parcel.GeographyID }).instance.result
      .then(succeeded => {
        if (succeeded) {
          this.onParcelUpdated.emit();
        }
      });
  }

  modifyParcelStatus(): void {
    this.modalService.open(ParcelModifyParcelStatusModalComponent,
      null,
      { ModalSize: ModalSizeEnum.Medium, ModalTheme: ModalThemeEnum.Light, OverflowVisible: true },
      { ParcelID: this.parcel.ParcelID, GeographyID: this.parcel.GeographyID }).instance.result
      .then(succeeded => {
        if (succeeded) {
          this.onParcelUpdated.emit();
        }
      });
  }
}
