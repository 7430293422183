//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[CustomRichTextType]

export enum CustomRichTextTypeEnum {
  PlatformOverview = 1,
  Disclaimer = 2,
  Homepage = 3,
  Help = 4,
  LabelsAndDefinitionsList = 5,
  ManageUserParcels = 6,
  Training = 7,
  CustomPages = 8,
  MailMergeReport = 9,
  ParcelList = 10,
  OnboardOverview = 11,
  OnboardWaterAccountPINs = 12,
  OnboardClaimParcels = 13,
  WellRegistryIntro = 14,
  TagList = 15,
  BulkTagParcels = 16,
  WaterAccounts = 17,
  WaterTransactions = 18,
  WaterTransactionCreate = 19,
  WaterTransactionBulkCreate = 20,
  WaterTransactionCSVUploadSupply = 21,
  WaterTransactionHistory = 22,
  WaterAccount = 23,
  Tag = 24,
  WaterAccountPIN = 25,
  SupplyType = 26,
  TotalSupply = 27,
  TotalUsage = 28,
  TransactionType = 29,
  EffectiveDate = 30,
  ReportingPeriodConfiguration = 31,
  WaterSupplyConfiguration = 32,
  WaterLevelsConfiguration = 33,
  TradingConfiguration = 34,
  ScenariosConfiguration = 35,
  WellRegistryConfiguration = 36,
  PermissionsConfiguration = 37,
  GeospatialDataConfiguration = 38,
  CustomPagesConfiguration = 39,
  UpdateParcelsUpload = 40,
  UpdateParcelsReviewParcels = 41,
  UpdateParcelsConfirm = 42,
  AccountReconciliation = 43,
  Footer = 44,
  EditAccounts = 45,
  EditUsers = 46,
  AccountActivity = 47,
  UsageByParcel = 48,
  AccountMap = 49,
  ChangeParcelOwnership = 50,
  WellBulkUpload = 51,
  WellName = 52,
  CountyWellPermitNo = 53,
  StateWCRNo = 54,
  WellDepth = 55,
  DateDrilled = 56,
  WaterTransactionCsvUploadUsage = 57,
  APNColumn = 58,
  ValueColumn = 59,
  UsageType = 60,
  Wells = 61,
  ExternalMapLayers = 62,
  ExternalMapLayersType = 63,
  ExternalMapLayersEdit = 64,
  ExternalMapLayersName = 65,
  ExternalMapLayersMinimumZoom = 66,
  OurGeographies = 67,
  PopUpField = 68,
  OpenETSyncIntegration = 69,
  OpenETInstructions = 70,
  UsageEstimates = 71,
  LastSuccessfulSyncDate = 72,
  LastSyncDate = 73,
  DateFinalized = 74,
  EstimateDate = 75,
  ZoneGroupsEdit = 76,
  ZoneGroupConfiguration = 77,
  ZoneGroupCSVUploader = 78,
  ZoneColumn = 79,
  ZoneGroupList = 80,
  HomeAboutCopy = 81,
  WaterAccountBudgetReport = 82,
  CustomPageEditProperties = 83,
  ZoneGroupUsageChart = 84,
  ModalUpdateWaterAccountInformation = 85,
  ModalCreateNewWaterAccount = 86,
  ModalAddParcelToWaterAccount = 87,
  ModalMergeWaterAccounts = 88,
  ModalUpdateWaterAccountParcels = 89,
  WaterAccountMergeType = 90,
  GeographyAbout = 91,
  GeographyAllocations = 92,
  GeographyWaterLevels = 93,
  GeographySupport = 94,
  MonitoringWellsGrid = 95,
  WaterAccountDashboard = 96,
  AddAWellScenario = 97,
  AllocationPlanEdit = 98,
  AllocationPlansConfigure = 99,
  CloneAllocationPlan = 100,
  AccountAllocationPlans = 101,
  RechargeScenario = 102,
  PostToSupply = 103,
  OpenETSyncVariable = 104,
  LandownerParcelIndex = 105,
  ScenarioPlanner = 106,
  ScenarioPlannerGET = 107,
  ScenarioPlannerScenarioRuns = 108,
  ActivityCenter = 109,
  WaterAccountSuggestions = 110,
  ModalReviewWaterAccountSuggestion = 111,
  WellRegistryConfigurationPage = 112,
  WellRegistryMapYourWell = 113,
  WellRegistryConfirmWellLocation = 114,
  WellRegistryIrrigatedParcels = 115,
  WellRegistryContacts = 116,
  WellRegistryBasicInformation = 117,
  WellRegistrySupportingInformation = 118,
  WellRegistryAttachments = 119,
  WellRegistrySubmit = 120,
  WellRegistryFieldWellName = 121,
  WellRegistryFieldSWN = 122,
  WellRegistryFieldWCR = 123,
  WellRegistryFieldCountyWellPermit = 124,
  WellRegistryFieldDateDrilled = 125,
  WellRegistryFieldWaterUseDescriptionAgricultural = 126,
  WellRegistryFieldWaterUseDescriptionStockWatering = 127,
  WellRegistryFieldWaterUseDescriptionDomestic = 128,
  WellRegistryFieldWaterUseDescriptionPublicMunicipal = 129,
  WellRegistryFieldWaterUseDescriptionPrivateMunicipal = 130,
  WellRegistryFieldWaterUseDescriptionOther = 131,
  WellRegistryFieldTopOfPerforations = 132,
  WellRegistryFieldBottomOfPerforations = 133,
  WellRegistryFieldMaxiumumFlow = 134,
  WellRegistryFieldTypicalFlow = 135,
  ManageReviewSubmittedWells = 136,
  LandownerWellList = 137,
  ManageAllWellRegistrations = 138,
  FormAsteriskExplanation = 139,
  WellRegistryIncompleteText = 140,
  ReferenceWellsList = 141,
  ReferenceWellsUploader = 142,
  LandingPageBody = 143,
  LandingPageUserCard = 144,
  LandingPageParcelCard = 145,
  LandingPageWellCard = 146,
  LandingPageWaterAccountCard = 147,
  LandingPageOverview = 148,
  LandingPageAllocationPlans = 149,
  LandingPageWaterLevels = 150,
  LandingPageContact = 151,
  ConfigureLandingPage = 152,
  HomepageUpdateProfileLink = 153,
  HomepageGrowerGuideLink = 154,
  HomepageGeographiesLink = 155,
  HomepageClaimWaterAccountsPanel = 156,
  ParcelStatus = 157,
  OnboardOverviewContent = 158,
  ParcelBulkActions = 159,
  MeterList = 160,
  SerialNumber = 161,
  MeterConfiguration = 162,
  Acknowledgements = 163,
  AdminGeographyEditForm = 164,
  LandingPageConfigure = 165,
  MeterDataConfigure = 166,
  AllocationPlanConfigureCard = 167,
  WellStatus = 168,
  UpdateWellInfo = 169,
  UpdateWellLocation = 170,
  UpdateWellIrrigatedParcels = 171,
  AdminFAQ = 172,
  GeneralFAQ = 173,
  WaterAccountCustomAttributesEdit = 174,
  ParcelCustomAttributesEdit = 175,
  WaterDashboardLink = 176,
  WaterManagerGuideLink = 177,
  ReviewParcelChanges = 178,
  WaterAccountRequestChanges = 179,
  WaterAccountRequestChangesCertification = 180,
  ConsolidateWaterAccountsDisclaimer = 181,
  KernScenarioModel = 182,
  MercedWaterResourcesModel = 183,
  YoloScenarioModel = 184,
  ConfigureGeographySetup = 185,
  ConfigureCustomAttributes = 186,
  ConfigureWaterManagers = 187
}
