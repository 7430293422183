<div class="list-view">
    <page-header icon="Parcels" [customRichTextTypeID]="richTextTypeID"></page-header>

    <div class="page-body grid-12">
        <app-alert-display></app-alert-display>

        <qanat-grid [rowData]="parcels" [columnDefs]="columnDefs" downloadFileName="parcels" rowSelection="multiple"
            [suppressRowClickSelection]="true" [pagination]="true" (gridReady)="onGridReady($event)"
            (selectionChanged)="onSelectionChanged()">
            <div customGridActions>
                <button (click)="changeStatus()" class="btn btn-sm btn-primary"
                    [disabled]="isLoadingSubmit || selectedParcelIDs?.length === 0"
                    [loadingSpinner]="{isLoading: isLoadingSubmit}">
                    Change Status
                </button>
            </div>
        </qanat-grid>
    </div>
</div>