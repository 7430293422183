import { Component, OnInit } from '@angular/core';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { Observable } from 'rxjs';
import { GeographyDto } from 'src/app/shared/generated/model/geography-dto';
import { SelectedGeographyService } from 'src/app/shared/services/selected-geography.service'; 
import { tap } from 'rxjs/operators';
import { OpenETConfigurationService } from 'src/app/shared/generated/api/open-et-configuration.service';
import { PageHeaderComponent } from 'src/app/shared/components/page-header/page-header.component';
import { GeographySwitcherComponent } from '../../../shared/components/geography-switcher/geography-switcher.component';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import { GeographyLogoComponent } from '../../../shared/components/geography-logo/geography-logo.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { DashboardManageNavComponent } from 'src/app/shared/components/dashboard-manage-nav/dashboard-manage-nav.component';

@Component({
  selector: 'qanat-dashboard-manage',
  templateUrl: './dashboard-manage.component.html',
  styleUrls: ['./dashboard-manage.component.scss'],
  standalone: true,
  imports: [NgIf, RouterLink, GeographyLogoComponent, IconComponent, GeographySwitcherComponent, DashboardManageNavComponent, RouterOutlet, PageHeaderComponent, AsyncPipe]
})
export class DashboardManageComponent implements OnInit {

  public isOpenETActive$: Observable<boolean>;
  public currentGeography$: Observable<GeographyDto>;

  public routerLinkActiveOptions = {
    exact: false
  };

  constructor(
    private selectedGeographyService: SelectedGeographyService,
    private router: Router,
    private openETConfigurationService: OpenETConfigurationService
  ) { }

  ngOnInit(): void {
    this.currentGeography$ = this.selectedGeographyService.curentUserSelectedGeographyObservable.pipe(
      tap(geographyDto => {
        if (geographyDto) {
          this.isOpenETActive$ = this.openETConfigurationService.geographiesGeographyIDOpenEtConfigurationGet(geographyDto.GeographyID);
          if (this.router.routerState.snapshot.url == '/manage') {
            this.redirectToGeography(geographyDto.GeographyName);
          }
        }
      })
    );
  }

  redirectToGeography(geographyName: string) {
    this.router.navigateByUrl(`/manage/${geographyName.toLowerCase()}`);
  }
}

