import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { UtilityFunctionsService } from 'src/app/shared/services/utility-functions.service';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { ColDef, GridApi, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-community';
import { forkJoin, Subscription } from 'rxjs';
import { GeographyDto } from 'src/app/shared/generated/model/geography-dto';
import { SelectedGeographyService } from 'src/app/shared/services/selected-geography.service';
import { AgGridHelper } from 'src/app/shared/helpers/ag-grid-helper';
import { CustomAttributeSimpleDto, ParcelWaterSupplyDto, UserDto, ZoneGroupMinimalDto } from 'src/app/shared/generated/model/models';
import { ParcelService } from 'src/app/shared/generated/api/parcel.service';
import { GeographyService } from 'src/app/shared/generated/api/geography.service';
import { ZoneGroupService } from 'src/app/shared/generated/api/zone-group.service';
import { CustomAttributeService } from 'src/app/shared/generated/api/custom-attribute.service';
import { CustomAttributeTypeEnum } from 'src/app/shared/generated/enum/custom-attribute-type-enum';
import { ParcelMapComponent } from '../../shared/components/parcel-map/parcel-map.component';
import { NgIf } from '@angular/common';
import { LoadingDirective } from '../../shared/directives/loading.directive';
import { AlertDisplayComponent } from '../../shared/components/alert-display/alert-display.component';
import { PageHeaderComponent } from 'src/app/shared/components/page-header/page-header.component';
import { QanatGridComponent } from 'src/app/shared/components/qanat-grid/qanat-grid.component';

@Component({
  selector: 'qanat-parcel-list',
  templateUrl: './parcel-list.component.html',
  styleUrls: ['./parcel-list.component.scss'],
  standalone: true,
  imports: [
    PageHeaderComponent,
    AlertDisplayComponent,
    LoadingDirective,
    NgIf,
    ParcelMapComponent,
    QanatGridComponent,
  ],
})
export class ParcelListComponent implements OnInit, OnDestroy {
  private geographyID: number;
  private selectedGeography$: Subscription = Subscription.EMPTY;
  public years: number[];

  private currentUser: UserDto;
  public isLoadingSubmit: boolean = false;

  public parcels: ParcelWaterSupplyDto[];
  public zoneGroups: ZoneGroupMinimalDto[];
  public gridApi: GridApi;

  public _highlightedParcelID: number;
  public set highlightedParcelID(value: number) {
    if (value != this._highlightedParcelID) {
      this._highlightedParcelID = value;
      this.selectHighlightedParcelIDRowNode();
    }
  }
  public get highlightedParcelID() {
    return this._highlightedParcelID;
  }

  public columnDefs: ColDef<ParcelWaterSupplyDto>[];
  public csvDownloadColIDsToExclude = [];
  public richTextTypeID: number = CustomRichTextTypeEnum.ParcelList;
  public customAttributes: CustomAttributeSimpleDto[];

  public mapCqlFilter: string;
  public geography: GeographyDto;
  public agGridOverlay: string = AgGridHelper.gridSpinnerOverlay;
  public isLoading: boolean = true;

  constructor(
    private cdr: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private utilityFunctionsService: UtilityFunctionsService,
    private parcelService: ParcelService,
    private geographyService: GeographyService,
    private selectedGeographyService: SelectedGeographyService,
    private zoneGroupService: ZoneGroupService,
    private customAttributeService: CustomAttributeService
  ) { }

  ngOnInit() {
    this.isLoading = true;
    this.selectedGeography$ = this.selectedGeographyService.curentUserSelectedGeographyObservable.subscribe(geography => {
      this.geography = geography;
      this.geographyID = geography.GeographyID;
      this.mapCqlFilter = `GeographyID = ${this.geographyID}`;
      this.getDataForGeographyID(this.geographyID);
    });
  }

  ngOnDestroy() {
    this.cdr.detach();
    this.selectedGeography$.unsubscribe();
  }

  getDataForGeographyID(geographyID: number) {
    forkJoin([
      this.authenticationService.getCurrentUser(),
      this.zoneGroupService.geographiesGeographyIDZoneGroupsGet(this.geographyID)
    ]).subscribe(([currentUser, zoneGroups]) => {
      this.currentUser = currentUser;
      this.gridApi.showLoadingOverlay();
      this.zoneGroups = zoneGroups;

      forkJoin([
        this.parcelService.geographiesGeographyIDParcelsManageGridItemsGet(geographyID),
        this.customAttributeService.geographiesGeographyIDCustomAttributesCustomAttributeTypeIDGet(geographyID, CustomAttributeTypeEnum.Parcel)
      ]).subscribe({
        next: ([parcels, customAttributes]) => {
          this.isLoading = false;
          this.parcels = parcels;
          this.customAttributes = customAttributes;
          this.createColumnDefs();
          this.cdr.detectChanges();
        },
        error: () => {
          this.parcels = [];
        }
      });
    });
  }

  private createColumnDefs() {
    this.columnDefs = [
      this.utilityFunctionsService.createLinkColumnDef('APN', 'ParcelNumber', 'ParcelID', { InRouterLink: '' }),
      this.utilityFunctionsService.createDecimalColumnDef('Area (Acres)', 'ParcelArea'),
      this.utilityFunctionsService.createLinkColumnDef('Account #', 'WaterAccountNumber', 'WaterAccountID', {
        InRouterLink: '../water-accounts/',
        FieldDefinitionType: 'WaterAccount',
        FieldDefinitionLabelOverride: 'Water Account #'
      }),
      this.utilityFunctionsService.createBasicColumnDef('Water Account Name', 'WaterAccountName'),
      this.utilityFunctionsService.createMultiLinkColumnDef('Wells on Parcel', 'WellsOnParcel', 'WellID', 'WellID', {
        InRouterLink: '../wells', MaxWidth: 300
      }),
      this.utilityFunctionsService.createMultiLinkColumnDef('Irrigated By', 'IrrigatedByWells', 'WellID', 'WellID', {
        InRouterLink: '../wells', MaxWidth: 300
      }),
      this.utilityFunctionsService.createBasicColumnDef('Parcel Status', 'ParcelStatusDisplayName', {
        FieldDefinitionType: 'ParcelStatus',
        CustomDropdownFilterField: 'ParcelStatusDisplayName'
      }),
      { headerName: 'Owner Name', field: 'OwnerName' },
      { headerName: 'Owner Address', field: 'OwnerAddress' }
    ];
    this.addZoneColumnsToColDefs();
    this.addCustomAttributeColumnsToColDefs();
  }

  private addZoneColumnsToColDefs() {
    this.zoneGroups.forEach(zoneGroup => {
      this.columnDefs.push(this.utilityFunctionsService.createZoneGroupColumnDef(zoneGroup, 'Zones'));
    });
  }

  private addCustomAttributeColumnsToColDefs() {
    this.columnDefs.push(...this.utilityFunctionsService.createCustomAttributeColumnDefs(this.customAttributes));
  }

  public onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
  }

  public onSelectionChanged(event: SelectionChangedEvent) {
    const selection = event.api.getSelectedRows()[0];
    if (selection && selection.ParcelID) {
      this.highlightedParcelID = selection.ParcelID;
    }
  }

  public selectHighlightedParcelIDRowNode() {
    this.gridApi.forEachNodeAfterFilterAndSort((rowNode, index) => {
      if (rowNode.data.ParcelID == this.highlightedParcelID) {
        rowNode.setSelected(true);
        this.gridApi.ensureIndexVisible(index, 'top');
      }
    });
  }

  public onMapSelection(parcelID: number) {
    this.highlightedParcelID = parcelID;
  }
}
