<div class="full-width-page">
    <page-header
        pageTitle="Water Accounts"
        [customRichTextTypeID]="richTextID"
        icon="WaterAccounts"
        [templateRight]="templateRight"></page-header>

    <ng-template #templateRight>
        <button routerLink="request-changes" class="btn btn-secondary request-changes-button">
            Request Water Account Changes
        </button>
    </ng-template>

    <water-dashboard-navigation></water-dashboard-navigation>

    <div class="page-body" [loadingSpinner]="{ isLoading: isLoading, loadingHeight: 500 }">
        <water-account-dashboard
            [waterAccounts]="waterAccounts"
            *ngIf="waterAccounts$ | async as waterAccounts"></water-account-dashboard>
    </div>
</div>
