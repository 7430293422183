<div class="modal-header">
    <custom-rich-text
        [customRichTextTypeID]="customRichTextID"
        [showLoading]="false"
        [showInfoIcon]="false"></custom-rich-text>
    <button type="button" class="close" (click)="close()">
        <i class="fa fa-times-circle"></i>
    </button>
</div>

<div class="modal-body" *ngIf="waterAccount$ | async as waterAccount">
    <div class="grid-12">
        <div class="g-col-12">
            <h2 class="section-title">
                <icon icon="WaterAccounts"></icon>
                #{{ waterAccount.WaterAccountNumber }}
                <small class="text-muted">{{ waterAccount.WaterAccountName }}</small>
            </h2>
        </div>
        <div class="g-col-6">
            <form action="" class="form" [formGroup]="formGroup">
                <div class="field">
                    <label class="field-label">Add Parcels</label>
                    <div class="flex-between">
                        <parcel-typeahead
                            [geographyID]="modalContext.GeographyID"
                            [selectedParcel]="selectedParcel"
                            (change)="selectedParcelChanged($event)"
                            [excludedParcelIDs]="waterAccountParcels | map : 'ParcelID'"
                            style="flex-grow: 1"></parcel-typeahead>
                        <button
                            type="button"
                            (click)="addSelectedParcel()"
                            class="btn btn-primary"
                            [disabled]="!this.selectedParcel">
                            + Add
                        </button>
                    </div>
                    <ol>
                        <li *ngFor="let parcel of waterAccountParcels; let i = index">
                            <div class="flex-between">
                                <div>
                                    <span class="parcel-index">{{ i + 1 }}.</span>
                                    <parcel-icon-with-number
                                        [parcel]="parcel"></parcel-icon-with-number>
                                </div>
                                <button
                                    class="btn btn-muted"
                                    type="button"
                                    (click)="removeParcel(parcel)">
                                    <i class="fas fa-times-circle"></i>
                                    Remove
                                </button>
                            </div>

                            <note
                                *ngIf="waterAccount.WaterAccountID != parcel.WaterAccountID"
                                class="mt-2">
                                Parcel moved from
                                <strong>
                                    {{ parcel.WaterAccountNameAndNumber ?? 'No Water Account' }}
                                </strong>
                            </note>
                        </li>
                    </ol>
                </div>
                <form-field
                    *ngIf="effectiveYearDropdownOptions"
                    [formControl]="formGroup.controls.EffectiveYear"
                    fieldLabel="Effective Year"
                    [type]="FormFieldType.Select"
                    [formInputOptions]="effectiveYearDropdownOptions"></form-field>
                <ng-container *ngIf="effectiveYear$ | async as effectiveYear">
                    <note class="mt-2 flex-start">
                        <strong>Effective On:</strong>
                        {{ effectiveYear | date : 'longDate' }}
                    </note>
                </ng-container>
            </form>
        </div>
        <div class="g-col-6" *ngIf="parcelsWithGeoJSON$ | async">
            <parcel-map
                mapID="UpdateParcelsMap"
                mapHeight="500px"
                [returnParcelOnClick]="true"
                [displayParcelLayerOnLoad]="true"
                (clickedOnParcel)="clickedOnParcel($event)"
                [collapsedLayerControl]="true"
                [cqlFilter]="'GeographyID in (' + waterAccount.Geography.GeographyID + ')'"
                [customGeoJSONLayers]="customGeoJSONLayers"
                [geographyID]="waterAccount.Geography.GeographyID"></parcel-map>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button
        class="btn btn-primary"
        (click)="save()"
        [disabled]="!formGroup.valid || isLoadingSubmit">
        Update Parcels & Save
    </button>
    <button class="btn btn-primary-outline" (click)="close()">Cancel</button>
</div>
