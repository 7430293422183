<div class="list-view">
    <page-header icon="Parcels" [customRichTextTypeID]="richTextTypeID"></page-header>

    <div class="page-body grid-12">
        <app-alert-display></app-alert-display>

        <div class="map-wrapper" [loadingSpinner]="{ isLoading, loadingHeight: 430 }">
            <ng-container *ngIf="parcels">
                <parcel-map mapID="parcelMap" mapHeight="540px" [(highlightedParcelID)]="highlightedParcelID"
                    [highlightParcelOnClick]="true" (highlightedWellIDChange)="onMapSelection($event)"
                    [cqlFilter]="mapCqlFilter" [boundingBoxInput]="geography?.BoundingBox"
                    [geographyID]="geographyID"></parcel-map>
            </ng-container>
        </div>

        <div class="table-wrapper">
            <qanat-grid [rowData]="parcels" [columnDefs]="columnDefs" downloadFileName="parcels" rowSelection="single"
                (gridReady)="onGridReady($event)" (selectionChanged)="onSelectionChanged($event)"
                (filterChanged)="selectHighlightedParcelIDRowNode()" [pagination]="true"></qanat-grid>
        </div>
    </div>
</div>