<div *ngIf="geographies$ | async as geographies" class="geography-list">
    <p class="select-label">Select a geography to claim water accounts:</p>

    <div
        *ngFor="let geography of geographies"
        class="geography-list-item"
        (click)="onGeographySelected(geography)">
        <div class="geography-logo">
            <geography-logo [geographyID]="geography.GeographyID"></geography-logo>
        </div>

        <div class="geography-name">
            <h2 class="geography-short-name">{{ geography.GeographyName }}</h2>

            <div class="geography-long-name">
                {{ geography.GeographyDisplayName }}
            </div>
        </div>
    </div>
</div>
