/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ZoneMinimalDto } from '././zone-minimal-dto';

import { FormControl, FormControlOptions, FormControlState, Validators } from "@angular/forms";
export class ZoneGroupMinimalDto { 
    ZoneGroupID?: number;
    GeographyID?: number;
    ZoneGroupName?: string;
    ZoneGroupSlug?: string;
    ZoneGroupDescription?: string;
    SortOrder?: number;
    ZoneList?: Array<ZoneMinimalDto>;
    HasAllocationPlan?: boolean;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export interface ZoneGroupMinimalDtoForm { 
    ZoneGroupID?: FormControl<number>;
    GeographyID?: FormControl<number>;
    ZoneGroupName?: FormControl<string>;
    ZoneGroupSlug?: FormControl<string>;
    ZoneGroupDescription?: FormControl<string>;
    SortOrder?: FormControl<number>;
    ZoneList?: FormControl<Array<ZoneMinimalDto>>;
    HasAllocationPlan?: FormControl<boolean>;
}

export class ZoneGroupMinimalDtoFormControls { 
    public static ZoneGroupID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static GeographyID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static ZoneGroupName = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static ZoneGroupSlug = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static ZoneGroupDescription = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static SortOrder = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static ZoneList = (value: FormControlState<Array<ZoneMinimalDto>> | Array<ZoneMinimalDto> = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<Array<ZoneMinimalDto>>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static HasAllocationPlan = (value: FormControlState<boolean> | boolean = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<boolean>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
}
