//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[Flag]

export enum FlagEnum {
  CanImpersonateUsers = 1,
  HasManagerDashboard = 2,
  HasAdminDashboard = 3,
  CanClaimWaterAccounts = 4,
  CanRegisterWells = 5,
  CanReviewWells = 6,
  CanUseScenarioPlanner = 7
}
