import { Component, Input, ViewContainerRef } from '@angular/core';
import { CommonModule, NgFor } from '@angular/common';
import { WaterAccountDashboardDto } from 'src/app/shared/generated/model/models';
import { WaterAccountTitleComponent } from 'src/app/shared/components/water-account/water-account-title/water-account-title.component';
import { ModelNameTagComponent } from 'src/app/shared/components/name-tag/name-tag.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'water-account-small-card',
  standalone: true,
  imports: [CommonModule, WaterAccountTitleComponent, NgFor, ModelNameTagComponent, RouterLink],
  templateUrl: './water-account-small-card.component.html',
  styleUrls: ['./water-account-small-card.component.scss']
})
export class WaterAccountSmallCardComponent {
  private _isHighlighted: boolean = false;

  @Input() set isHighlighted(value: boolean) {
    this._isHighlighted = value;
    if (this._isHighlighted) {
      this.vcr.element.nativeElement.scrollIntoView({ block: 'nearest' });
    }
  }
  get isHighlighted() {
    return this._isHighlighted;
  }

  @Input() waterAccount: WaterAccountDashboardDto;

  constructor(private vcr: ViewContainerRef) { }
}
