import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { WaterAccountService } from 'src/app/shared/generated/api/water-account.service';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { ParcelWaterAccountDashboardDto } from 'src/app/shared/generated/model/parcel-water-account-dashboard-dto';
import { NgIf, AsyncPipe } from '@angular/common';
import { LoadingDirective } from '../../../shared/directives/loading.directive';
import { PageHeaderComponent } from 'src/app/shared/components/page-header/page-header.component';
import { WaterDashboardNavigationComponent } from 'src/app/shared/components/water-dashboard-navigation/water-dashboard-navigation.component';
import { WaterDashboardParcelsComponent } from 'src/app/shared/components/water-dashboard-parcels/water-dashboard-parcels.component';

@Component({
  selector: 'dashboard-water-account-parcel',
  templateUrl: './dashboard-water-account-parcel.component.html',
  styleUrls: ['./dashboard-water-account-parcel.component.scss'],
  standalone: true,
  imports: [PageHeaderComponent, WaterDashboardNavigationComponent, LoadingDirective, NgIf, WaterDashboardParcelsComponent, AsyncPipe]
})
export class DashboardWaterAccountParcelComponent implements OnInit {
  public richTextID: number = CustomRichTextTypeEnum.LandownerParcelIndex;
  public isLoading: boolean = false;
  public parcels$: Observable<ParcelWaterAccountDashboardDto[]>;

  constructor(
    public waterAccountService: WaterAccountService,
    public authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.parcels$ = this.authenticationService.getCurrentUser().pipe(
      switchMap(userDto => this.waterAccountService.waterAccountsWaterAccountsDashboardParcelsUserUserIDGet(userDto.UserID)),
      tap(x => {
        this.isLoading = false;
      })
    );
  }

}