//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[ParcelStatus]

export enum ParcelStatusEnum {
  Assigned = 1,
  Inactive = 2,
  Unassigned = 3,
  Excluded = 4
}
