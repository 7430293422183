<div class="promo-card">
    <div class="promo-card-header">
        <h3>
            <icon [icon]="icon"></icon>
            {{ cardTitle }}
        </h3>
    </div>
    <div class="promo-card-body copy copy-3">
        <custom-rich-text [customRichTextTypeID]="customRichTextTypeID"></custom-rich-text>
    </div>
    <div class="promo-card-footer">
        <ng-content></ng-content>
    </div>
</div>
