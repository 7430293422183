<page-header
    [customRichTextTypeID]="richTextTypeID"
    pageTitle="Labels and Definitions"></page-header>

<div class="page-body">
    <app-alert-display></app-alert-display>

    <qanat-grid
        height="800px"
        [rowData]="fieldDefinitions"
        [columnDefs]="columnDefs"
        downloadFileName="field-definitions"
        [pagination]="true"
        [sizeColumnsToFitGrid]="true"></qanat-grid>
</div>
