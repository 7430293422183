import { Component, OnInit } from '@angular/core';
import { tap } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { UserService } from 'src/app/shared/generated/api/user.service';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { WellLandownerDashboardDto } from 'src/app/shared/generated/model/well-landowner-dashboard-dto';
import { NgIf, AsyncPipe } from '@angular/common';
import { LoadingDirective } from '../../shared/directives/loading.directive';
import { PageHeaderComponent } from 'src/app/shared/components/page-header/page-header.component';
import { WaterDashboardNavigationComponent } from 'src/app/shared/components/water-dashboard-navigation/water-dashboard-navigation.component';
import { WaterDashboardWellsComponent } from 'src/app/shared/components/water-dashboard-wells/water-dashboard-wells.component';

@Component({
  selector: 'dashboard-water-account-well-registration',
  templateUrl: './dashboard-water-account-well-registration.component.html',
  styleUrls: ['./dashboard-water-account-well-registration.component.scss'],
  standalone: true,
  imports: [PageHeaderComponent, WaterDashboardNavigationComponent, LoadingDirective, NgIf, WaterDashboardWellsComponent, AsyncPipe]
})
export class DashboardWaterAccountWellRegistrationComponent implements OnInit {
  public isLoading: boolean = false;
  public richTextID: number = CustomRichTextTypeEnum.LandownerWellList;
  public wells$: Observable<WellLandownerDashboardDto[]>;

  constructor(
    private userService: UserService,
    public authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.wells$ = this.userService.userWellsGet().pipe(tap(() => this.isLoading = false));
  }
}
