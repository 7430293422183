<div class="water-account-small-card" [class.highlighted]="isHighlighted">
    <div class="meta p-1">
        <icon icon="Wells"></icon>
        <span class="well-name">{{ well.WellName }}</span>
        <span class="link" *ngIf="well.WellRegistrationID">
            <a [routerLink]="['/profile', 'well-registrations', well?.WellRegistrationID]">
                Registration Details
            </a>
        </span>
    </div>
    <div class="parcel-number p-1">
        <icon icon="Parcels"></icon>
        <span>{{ well.Parcel?.ParcelNumber ?? 'No Parcel Selected' }}</span>
    </div>
</div>
