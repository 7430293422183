<div class="page-body grid-12">
    <div class="welcome-col g-col-6">
        <section class="hero">
            <div class="hero__photo">
                <img src="../assets/main/home/hero.jpg" alt="Photo of California farmlands" />
            </div>

            <div class="hero__actions welcome-card">
                <div class="welcome-card-header">
                    <h2 class="section-title">Welcome to the Groundwater Accounting Platform.</h2>
                </div>

                <div class="welcome-card-body">
                    <div *ngIf="currentUser$ | async as currentUser">
                        <div class="copy copy-3">
                            <p>
                                Hi, {{ currentUser.FullName }}! (
                                <a (click)="logout()" class="sign-out-link">Sign Out</a>
                                )
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="quick-links">
            <h2 class="section-title">Quick Links</h2>

            <div class="grid-9 quick-links-grid">
                <rich-link
                    [customRichTextTypeID]="updateProfileRichTextTypeID"
                    icon="User"
                    cardTitle="Update Profile"
                    class="g-col-3">
                    <button class="btn btn-primary btn-sm" routerLink="/profile">Update</button>
                </rich-link>
                <rich-link
                    [customRichTextTypeID]="growerGuideRichTextTypeID"
                    icon="WaterAccounts"
                    cardTitle="Guide for Growers"
                    class="g-col-3">
                    <button class="btn btn-primary btn-sm" routerLink="/grower-guide">
                        View Guide
                    </button>
                </rich-link>
                <rich-link
                    [customRichTextTypeID]="geographiesRichTextTypeID"
                    icon="Geography-Alt"
                    cardTitle="Geographies"
                    class="g-col-3">
                    <button class="btn btn-primary btn-sm" routerLink="/geographies">
                        Learn More
                    </button>
                </rich-link>
            </div>
        </section>
    </div>

    <div class="accounts-col g-col-6" [loadingSpinner]="isLoading">
        <app-alert-display></app-alert-display>
        <section class="accounts-and-data">
            <h2 class="section-title">Your Water Accounts & Data</h2>

            <div
                class="geography-cards grid-12"
                *ngIf="geographySummaries$ | async as geographySummaries">
                <large-geography-card
                    *ngFor="let geographySummary of geographySummaries"
                    [userGeographySummary]="geographySummary"></large-geography-card>

                <div *ngIf="geographySummaries.length == 0">
                    {{ noWaterAccountsText }}
                </div>
            </div>
        </section>

        <section class="claim-water-accounts">
            <custom-rich-text
                [customRichTextTypeID]="claimWaterAccountsRichTextTypeID"></custom-rich-text>

            <div class="claim-water-accounts-selector">
                <button class="btn btn-orange mt-3" [dropdownToggle]="geographySelectorWrapper">
                    Claim Water Accounts
                </button>
                <div class="geography-selector-wrapper" #geographySelectorWrapper>
                    <geography-selector
                        (geographySelected)="onGeographySelected($event)"></geography-selector>
                </div>
            </div>
        </section>
    </div>
</div>
