import { Component, OnInit } from '@angular/core';
import { ColDef, FilterChangedEvent, GetRowIdParams, GridApi, GridReadyEvent, SelectionChangedEvent } from 'ag-grid-community';
import { BehaviorSubject, Observable, switchMap, tap } from 'rxjs';
import { ConfirmService } from 'src/app/shared/services/confirm/confirm.service';
import { UtilityFunctionsService } from 'src/app/shared/services/utility-functions.service';
import { TagRendererComponent } from 'src/app/shared/components/ag-grid/tag-renderer/tag-renderer.component';
import { ParcelService } from 'src/app/shared/generated/api/parcel.service';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { GeographyDto } from 'src/app/shared/generated/model/geography-dto';
import { ParcelChangesGridItemDto } from 'src/app/shared/generated/model/parcel-changes-grid-item-dto';
import { UploadedGdbSimpleDto } from 'src/app/shared/generated/model/uploaded-gdb-simple-dto';
import { Alert } from 'src/app/shared/models/alert';
import { AlertContext } from 'src/app/shared/models/enums/alert-context.enum';
import { AlertService } from 'src/app/shared/services/alert.service';
import { SelectedGeographyService } from 'src/app/shared/services/selected-geography.service';
import { FormsModule } from '@angular/forms';
import { ParcelReviewChangesCardComponent } from '../../shared/components/parcel-review-changes-card/parcel-review-changes-card.component';
import { NoteComponent } from '../../shared/components/note/note.component';
import { NgIf, AsyncPipe, DecimalPipe, DatePipe } from '@angular/common';
import { AlertDisplayComponent } from '../../shared/components/alert-display/alert-display.component';
import { RouterLink } from '@angular/router';
import { PageHeaderComponent } from 'src/app/shared/components/page-header/page-header.component';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import { QanatGridComponent } from 'src/app/shared/components/qanat-grid/qanat-grid.component';

@Component({
  selector: 'parcels-review-changes',
  templateUrl: './parcels-review-changes.component.html',
  styleUrl: './parcels-review-changes.component.scss',
  standalone: true,
  imports: [PageHeaderComponent, RouterLink, AlertDisplayComponent, NgIf, NoteComponent, IconComponent, ParcelReviewChangesCardComponent, QanatGridComponent, FormsModule, AsyncPipe, DecimalPipe, DatePipe]
})
export class ParcelsReviewChangesComponent implements OnInit {
  public selectedGeography$: Observable<GeographyDto>;
  public geographyID: number;
  public customRichTextTypeID = CustomRichTextTypeEnum.ReviewParcelChanges;

  public latestGDBUpload$: Observable<UploadedGdbSimpleDto>;

  public parcels$: Observable<ParcelChangesGridItemDto[]>;
  public parcelRefresh$ = new BehaviorSubject(null);
  public parcels: ParcelChangesGridItemDto[];
  public parcelsToDisplay: ParcelChangesGridItemDto[];

  public selectedParcel: ParcelChangesGridItemDto;
  public unreviewedParcelsCount: number;
  public reviewedParcelsCount: number;
  public showReviewedParcels: boolean = false;

  public columnDefs: ColDef<ParcelChangesGridItemDto>[];
  public gridApi: GridApi;
  public anyGridFilterPresent: boolean = false;
  public filteredRowsCount: number;

  public isLoadingSubmit: boolean = false;

  constructor(
    private parcelService: ParcelService,
    private selectedGeographyService: SelectedGeographyService,
    private utilityFunctionsService: UtilityFunctionsService,
    private alertService: AlertService,
    private confirmService: ConfirmService,
  ) { }

  ngOnInit(): void {
    this.selectedGeography$ = this.selectedGeographyService.curentUserSelectedGeographyObservable.pipe(
      tap(geography => {
        this.geographyID = geography.GeographyID;

        this.latestGDBUpload$ = this.parcelService.geographiesGeographyIDParcelsUploadedGdbLatestGet(geography.GeographyID);

        this.parcels$ = this.parcelRefresh$.pipe(
          tap(() => this.gridApi?.showLoadingOverlay()),
          switchMap(() => this.parcelService.geographiesGeographyIDParcelsReviewChangesGridItemsGet(geography.GeographyID)),
          tap(parcels => {
            this.parcels = parcels;
            this.setParcelsToDisplay();
            this.gridApi?.hideOverlay();

            this.reviewedParcelsCount = parcels.filter(x => x.IsReviewed).length;
            this.unreviewedParcelsCount = parcels.length - this.reviewedParcelsCount;
          })
        );
      })
    );

    this.createColumnDefs();
  }

  private createColumnDefs() {
    this.columnDefs = [
      this.utilityFunctionsService.createBasicColumnDef('Reviewed?', 'IsReviewed', {
        ValueGetter: params => this.utilityFunctionsService.booleanValueGetter(params.data.IsReviewed),
        UseCustomDropdownFilter: true
      }),
      this.utilityFunctionsService.createLinkColumnDef('APN', 'ParcelNumber', 'ParcelID', {
        InRouterLink: '../',
        CellClass: params => params.data.IsReviewed ? 'muted-text' : null
      }),
      {
        headerName: 'Parcel Changes', cellRenderer: TagRendererComponent,
        valueGetter: params => params.data.ParcelFieldDiffs.filter(x => x.CurrentFieldValue != x.PreviousFieldValue).map(x => x.FieldShortName),
        cellRendererParams: params => {
          return { disabled: params.data.IsReviewed };
        },
        width: 400
      },
      this.utilityFunctionsService.createLinkColumnDef('Water Account', 'WaterAccount.WaterAccountNumber', 'WaterAccount.WaterAccountID', {
        InRouterLink: '../../water-accounts/',
        FieldDefinitionType: 'WaterAccount'
      }),
      this.utilityFunctionsService.createBasicColumnDef('Status', 'ParcelStatus.ParcelStatusDisplayName', {
        CustomDropdownFilterField: 'ParcelStatus.ParcelStatusDisplayName',
        FieldDefinitionType: 'ParcelStatus'
      }),
      this.utilityFunctionsService.createDateColumnDef('Date Reviewed', 'ReviewDate', 'shortDate')
    ];
  }

  public onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
    this.updateGridSelection();
  }

  public onFilterChanged(event: FilterChangedEvent) {
    this.anyGridFilterPresent = event.api.isAnyFilterPresent();
    let filteredRowsCount = 0;
    this.gridApi.forEachNodeAfterFilter(() => {
      filteredRowsCount++;
    });
    this.filteredRowsCount = filteredRowsCount;
  }

  public getRowId = (event: GetRowIdParams) => event.data.ParcelID;

  public setParcelsToDisplay() {
    this.parcelsToDisplay = this.showReviewedParcels
      ? this.parcels
      : this.parcels.filter(x => !x.IsReviewed);

    this.setSelectedParcel();
  }

  public setSelectedParcel() {
    if (this.selectedParcel) {
      const index = this.parcels.findIndex(x => x.ParcelID == this.selectedParcel.ParcelID);
      if (index >= 0) {
        this.selectedParcel = this.parcels[index];
      } else {
        this.selectedParcel = null;
      }
    }

    if (!this.selectedParcel && this.parcelsToDisplay.length > 0) {
      this.selectedParcel = this.parcelsToDisplay[0];
      this.updateGridSelection();
    }
  }

  public selectNextParcel() {
    if (this.parcelsToDisplay.length == 0) {
      this.selectedParcel = null;
      return;
    }

    const currentSelectedParcelIndex = this.parcelsToDisplay.findIndex(x => x.ParcelID == this.selectedParcel.ParcelID);

    let newIndex = currentSelectedParcelIndex + 1;
    if (newIndex == this.parcelsToDisplay.length) {
      newIndex = 0;
    }

    this.selectedParcel = this.parcelsToDisplay[newIndex];
    this.updateGridSelection();
  }

  public onParcelSelected(params: SelectionChangedEvent) {
    const selectedRow = params.api.getSelectedRows()[0];
    if (!selectedRow) return;

    this.selectedParcel = params.api.getSelectedRows()[0];
  }

  public updateGridSelection() {
    if (!this.gridApi || !this.selectedParcel) return;

    const rowNodeToSelect = this.gridApi.getRowNode(this.selectedParcel.ParcelID.toString());
    if (!rowNodeToSelect) return;

    rowNodeToSelect.setSelected(true, true);
    this.gridApi.ensureNodeVisible(rowNodeToSelect);
  }

  public onParcelReviewed() {
    this.selectedParcel = null;
    this.parcelRefresh$.next(null);
  }
  public onParcelUpdated() {
    this.parcelRefresh$.next(null);
  }

  public markAllAsReviewed() {
    const parcelIDs = [];
    this.gridApi.forEachNodeAfterFilter(x => parcelIDs.push(x.id));

    const message = `Are you sure you want to mark <b>${parcelIDs.length} parcels</b> as reviewed?`;
    this.confirmService.confirm({ buttonClassYes: 'btn-primary', title: 'Mark Parcels as Reviewed', message: message, buttonTextYes: 'Mark Parcels as Reviewed', buttonTextNo: 'Cancel' })
      .then(confirmed => {
        if (!confirmed) return;

        this.parcelService.parcelsReviewPut(parcelIDs).subscribe({
          next: () => {
            this.isLoadingSubmit = false;
            this.selectedParcel = null;
            this.alertService.pushAlert(new Alert(`${parcelIDs.length} parcels successfully marked as reviewed.`, AlertContext.Success));
            this.parcelRefresh$.next(null);
          },
          error: () => this.isLoadingSubmit = false
        });
      });
  }
}
