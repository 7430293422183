<page-header *ngIf="getModel$ | async as model" icon="Wells" [templateTitleAppend]="templateTitleAppend"
    [customRichTextTypeID]="richTextTypeID" pageTitle="Add a Well">
    <ng-template #templateTitleAppend>
        <name-tag class="mb-3" [name]="model.ModelName"></name-tag>
    </ng-template>
</page-header>

<div class="page-body">
    <app-alert-display></app-alert-display>
    <form class="form grid-12" #addAWellForm="ngForm">
        <div class="field">
            <label class="field-label large required">1. Name your scenario run</label>
            <input type="text" class="form-control" name="scenarioRunName" [(ngModel)]="model.ScenarioRunName" />
        </div>

        <scenario-map [modelShortName]="modelShortName" [selecting]="isSelectingMapLocation"
            (locationSelected)="onMapLocationSelection($event)" (mapReady)="onMapReady($event)">
            <ng-container *ngIf="!mapLoading">
                <scenario-map-marker-layer [map]="map" [markerObjects]="model.ScenarioPumpingWells"
                    [markerIcon]="pumpingWellMarkerIcon" [selectedMarkerObject]="selectedPumpingWell"
                    [selectedMarkerIcon]="selectedPumpingWellMarkerIcon"
                    (markerSelected)="onMapMarkerSelection($event)"></scenario-map-marker-layer>

                <scenario-map-marker-layer [map]="map" [markerObjects]="model.ScenarioObservationPoints"
                    [markerIcon]="observationPointMarkerIcon" [selectedMarkerObject]="selectedObservationPoint"
                    [selectedMarkerIcon]="selectedObservationPointMarker"
                    (markerSelected)="onMapMarkerSelection($event)"></scenario-map-marker-layer>

                <monitoring-wells-layer [map]="map" [layerControl]="layerControl" [displayOnLoad]="false"
                    [editingMap]="isSelectingMapLocation"></monitoring-wells-layer>
            </ng-container>
        </scenario-map>

        <div class="field g-col-6">
            <label class="field-label large required">2. Add one or more pumping wells</label>

            <div class="copy copy-3 mb-2">
                <p class="field-instructions">
                    Select "+ Add" and click on the map to place a well. Provide an estimated
                    extraction per month (in ac-ft) in the field below.
                </p>
            </div>

            <scenario-map-marker-input-card *ngFor="let well of model.ScenarioPumpingWells; let i = index"
                [latitude]="well.Latitude" [longitude]="well.Longitude" [selected]="pumpingWellSelected(well)"
                (cardSelected)="selectPumpingWell(i)" (cardDeleted)="deletePumpingWell(i)" colorScheme="blue">
                <div card-header class="field">
                    <label class="field-label required">Label</label>
                    <input type="text" class="form-control" name="pumpingWellName-{{ i }}"
                        [(ngModel)]="well.PumpingWellName" />
                </div>

                <div card-input class="field mb-2">
                    <label class="field-label required">Estimated Extraction (ac-ft/month)</label>
                    <input min="0" type="number" class="form-control" name="estimatedExtraction-{{ i }}"
                        [(ngModel)]="well.EstimatedExtraction" />
                </div>
            </scenario-map-marker-input-card>

            <div class="mt-3">
                <button class="btn btn-sm btn-primary-outline" (click)="addPumpingWell()">
                    <i class="fas fa-plus"></i>
                    Add
                </button>
            </div>
        </div>

        <div class="field g-col-6">
            <label class="field-label large">3. Add any observation points</label>

            <div class="copy copy-3 mb-2">
                <p class="field-instructions">
                    Select "+ Add" and click on the map to place an observation point that will
                    gather water level data in the scenario run.
                </p>
            </div>

            <scenario-map-marker-input-card *ngFor="let point of model.ScenarioObservationPoints; let i = index"
                [latitude]="point.Latitude" [longitude]="point.Longitude" [selected]="observationPointSelected(point)"
                (cardSelected)="selectObservationPoint(i)" (cardDeleted)="deleteObservationPoint(i)"
                colorScheme="yellow">
                <div card-header class="field">
                    <label class="field-label required">Label</label>
                    <input type="text" class="form-control" name="observationPointName-{{ i }}"
                        [(ngModel)]="point.ObservationPointName" />
                </div>
            </scenario-map-marker-input-card>

            <div class="mt-3">
                <button class="btn btn-sm btn-primary-outline" (click)="addObservationPoint()">
                    <i class="fas fa-plus"></i>
                    Add
                </button>
            </div>
        </div>

        <div class="form__actions">
            <label class="field-label large required my-2">4. Run your scenario</label>

            <button class="btn btn-primary" (click)="runScenario()" [disabled]="isLoadingSubmit"
                [buttonLoading]="isLoadingSubmit">
                Run Scenario
            </button>
        </div>
    </form>
</div>