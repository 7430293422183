<div class="grid-12">
    <app-alert-display></app-alert-display>
    <div class="g-col-12">
        <qanat-grid
            *ngIf="wells"
            [rowData]="wells"
            [columnDefs]="columnDefs"
            rowSelection="single"
            downloadFileName="wells"></qanat-grid>
    </div>

    <ng-template #isLoading>
        <div [loadingSpinner]="{ isLoading: true, loadingHeight: 720 }"></div>
    </ng-template>
</div>
