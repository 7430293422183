<ng-container *ngIf="routerLink; else staticNameTag">
    <a
        [routerLink]="routerLink"
        [style.background-color]="color"
        class="name-tag"
        [style.color]="textColor">
        {{ name }}
    </a>
</ng-container>

<ng-template #staticNameTag>
    <span class="name-tag" [style.background-color]="color" [style.color]="textColor">
        {{ name }}
    </span>
</ng-template>
