<div class="dashboard scenario-planner-dashboard">
    <aside
        class="sidebar sidebar-scenarios"
        *ngIf="modelsAndScenarios$ | async as modelsAndScenarios">
        <div class="sidebar-header">
            <icon icon="ScenarioPlanner"></icon>
        </div>
        <h5 class="sidebar-title has-icon">
            <icon icon="ScenarioPlanner"></icon>
            Scenario Planner
        </h5>
        <ul class="sidebar-nav entities-nav">
            <li class="sidebar-item">
                <a
                    title="Models"
                    [routerLink]="['/scenario-planner/models']"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }"
                    class="sidebar-link">
                    <icon icon="Model"></icon>
                    <span class="sidebar-link__label">Models</span>
                </a>
            </li>
            <li class="sidebar-item">
                <a
                    title="Scenario Runs"
                    [routerLink]="['/scenario-planner/runs']"
                    routerLinkActive="active"
                    class="sidebar-link">
                    <icon icon="ScenarioRun"></icon>
                    <span class="sidebar-link__label">Scenario Runs</span>
                </a>
            </li>
        </ul>
        <ng-container *ngIf="currentModel$ | async as currentModel">
            <h5 class="sidebar-title model-nav-header has-icon">
                <icon icon="Model"></icon>
                {{ currentModel.ModelName }}
            </h5>
            <ul class="sidebar-nav model-nav">
                <li>
                    <a
                        [title]="currentModel.ModelName + ' Overview'"
                        [routerLink]="['/scenario-planner/models', currentModel.ModelShortName]"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        class="sidebar-link">
                        <icon icon="Model"></icon>
                        <span class="sidebar-link__label">Model Overview</span>
                    </a>
                </li>

                <li *ngIf="currentAction$ | async as currentAction">
                    <a
                        [title]="currentAction.RunName"
                        [routerLink]="[
                            '/scenario-planner/models',
                            currentAction.Model.ModelShortName,
                            currentAction.Scenario.ScenarioShortName,
                            currentAction.GETActionID
                        ]"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        class="sidebar-link">
                        <icon icon="ScenarioRun"></icon>
                        <span class="sidebar-link__label">{{ currentAction.RunName }}</span>
                    </a>
                </li>

                <li class="sidebar-item" *ngFor="let scenario of modelsAndScenarios[1]">
                    <a
                        [title]="scenario.ScenarioName"
                        [routerLink]="[
                            '/scenario-planner/models',
                            currentModel.ModelShortName,
                            scenario.ScenarioShortName,
                            'new'
                        ]"
                        routerLinkActive="active"
                        class="sidebar-link">
                        <icon
                            [icon]="
                                scenario.ScenarioID == ScenarioEnum.AddaWell
                                    ? 'Wells'
                                    : 'WaterDropFilled'
                            "></icon>
                        <span class="sidebar-link__label">{{ scenario.ScenarioName }}</span>
                    </a>
                </li>
            </ul>
        </ng-container>
    </aside>

    <main class="main">
        <router-outlet #manageOutlet="outlet"></router-outlet>
    </main>

    <ng-template #isLoadingTemplate>
        <aside class="sidebar" [loadingSpinner]="true"></aside>
        <main class="main" [loadingSpinner]="true"></main>
    </ng-template>
</div>
