import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { WaterAccountService } from 'src/app/shared/generated/api/water-account.service';
import { CustomRichTextTypeEnum } from 'src/app/shared/generated/enum/custom-rich-text-type-enum';
import { WaterAccountDashboardDto } from 'src/app/shared/generated/model/models';
import { NgIf, AsyncPipe } from '@angular/common';
import { LoadingDirective } from '../../../shared/directives/loading.directive';
import { RouterLink } from '@angular/router';
import { PageHeaderComponent } from 'src/app/shared/components/page-header/page-header.component';
import { WaterDashboardNavigationComponent } from 'src/app/shared/components/water-dashboard-navigation/water-dashboard-navigation.component';
import { WaterAccountDashboardComponent } from 'src/app/shared/components/water-account-dashboard/water-account-dashboard.component';

@Component({
  selector: 'dashboard-water-account',
  templateUrl: './dashboard-water-account.component.html',
  styleUrls: ['./dashboard-water-account.component.scss'],
  standalone: true,
  imports: [PageHeaderComponent, RouterLink, WaterDashboardNavigationComponent, LoadingDirective, NgIf, WaterAccountDashboardComponent, AsyncPipe]
})
export class DashboardWaterAccountComponent implements OnInit {
  public richTextID: number = CustomRichTextTypeEnum.WaterAccountDashboard;
  public waterAccounts$: Observable<WaterAccountDashboardDto[]>;
  public isLoading: boolean = true;

  constructor(
    private authenticationService: AuthenticationService,
    private waterAccountService: WaterAccountService) { }

  ngOnInit(): void {
    this.waterAccounts$ = this.waterAccountService.waterAccountsWaterAccountsDashboardGet().pipe(
      tap(x => {
        this.isLoading = false;
      })
    );
  }
}
