import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ColDef, GridReadyEvent } from 'ag-grid-community';
import { Observable, switchMap, tap } from 'rxjs';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { UtilityFunctionsService } from 'src/app/shared/services/utility-functions.service';
import { ParcelService } from 'src/app/shared/generated/api/parcel.service';
import { WaterAccountService } from 'src/app/shared/generated/api/water-account.service';
import { GeographyDisplayDto, ParcelWaterSupplyAndUsageDto } from 'src/app/shared/generated/model/models';
import { ParcelWaterAccountDashboardDto } from 'src/app/shared/generated/model/parcel-water-account-dashboard-dto';
import { UserDto } from 'src/app/shared/generated/model/user-dto';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { ParcelSmallCardComponent } from '../parcel-small-card/parcel-small-card.component';
import { ParcelWaterAccountDashboardMapComponent } from '../parcel-water-account-dashboard-map/parcel-water-account-dashboard-map.component';
import { QanatGridComponent } from '../qanat-grid/qanat-grid.component';
import { LoadingDirective } from '../../directives/loading.directive';

@Component({
  selector: 'water-dashboard-parcels',
  templateUrl: './water-dashboard-parcels.component.html',
  styleUrls: ['./water-dashboard-parcels.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    NgFor,
    ParcelSmallCardComponent,
    ParcelWaterAccountDashboardMapComponent,
    QanatGridComponent,
    LoadingDirective,
    AsyncPipe,
  ],
})
export class WaterDashboardParcelsComponent implements OnInit, OnChanges {
  @Input() parcels: ParcelWaterAccountDashboardDto[];
  public currentUser: UserDto;
  public isLoading: boolean = false;
  public viewingWaterAccountsTab: boolean = true;
  public uniqueGeographies: GeographyDisplayDto[] = [];
  public selectedGeography: FormControl<GeographyDisplayDto> = new FormControl<GeographyDisplayDto>(null);
  public geographyParcelDict = {};
  public isReady: boolean = false;
  public parcel: ParcelWaterAccountDashboardDto;
  public parcelIDs: number[];

  public parcelWaterSupplyAndUsages$: Observable<ParcelWaterSupplyAndUsageDto[]>;
  public columnDefs: ColDef[];

  private _highlightedParcelID: number;
  set highlightedParcelID(value: number) {
    this._highlightedParcelID = value;
  }

  get highlightedParcelID(): number {
    return this._highlightedParcelID;
  }

  constructor(
    private utilityFunctionsService: UtilityFunctionsService,
    private parcelService: ParcelService,
    public waterAccountService: WaterAccountService,
    public authenticationService: AuthenticationService,
    public route: ActivatedRoute
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.waterAccounts && !changes.waterAccounts.firstChange) {
      this.filterGeographies();
      this.getGeographiesToShow();
    }
  }

  ngOnInit(): void {
    this.parcelIDs = this.parcels.map((x) => x.Parcel.ParcelID);
    this.filterGeographies();
    this.createColumnDefs();

    this.parcelWaterSupplyAndUsages$ = this.authenticationService.getCurrentUser().pipe(
      switchMap(currentUser => this.parcelService.parcelsWaterSupplyAndUsageGet()),
      tap(x => {
        this.isLoading = false;
      })
    );
  }

  private createColumnDefs(): void {
    this.columnDefs = [
      this.utilityFunctionsService.createLinkColumnDef('Parcel', 'ParcelNumber', 'ParcelID', { InRouterLink: './' }),
      this.utilityFunctionsService.createLinkColumnDef('Geography', 'Geography.GeographyName', '', {
        ValueGetter: params => {
          return { LinkValue: `${params.data.Geography.GeographyName}/overview`, LinkDisplay: params.data.Geography.GeographyName };
        },
        InRouterLink: '/geographies/',
        CustomDropdownFilterField: 'Geography.GeographyName'
      }),
      this.utilityFunctionsService.createDecimalColumnDef('Area (acres)', 'ParcelArea'),
      this.utilityFunctionsService.createDecimalColumnDef('Total Supply (ac-ft)', 'TotalSupply'),
      this.utilityFunctionsService.createDecimalColumnDef('Total Usage (ac-ft)', 'UsageToDate'),
      this.utilityFunctionsService.createLinkColumnDef('Water Account #', 'WaterAccount.WaterAccountNumber', 'WaterAccount.WaterAccountID', { InRouterLink: '../water-accounts/' })
    ];
  }

  parcelGridReady(event: GridReadyEvent) {
    event.api.hideOverlay();
  }

  filterGeographies() {
    const geographies = this.parcels.map((x) => x.Geography);
    this.uniqueGeographies = [];

    geographies.forEach((geography) => {
      const alreadyExists = this.uniqueGeographies.map((x) => x.GeographyID).includes(geography.GeographyID);
      if (!alreadyExists) {
        this.uniqueGeographies.push(geography);
      }
      this.geographyParcelDict[geography.GeographyName] = this.parcels.filter((x) => x.Geography.GeographyName == geography.GeographyName);
    });

    let geographyFromQueryParam = [];
    this.route.queryParams.subscribe(params => {
      if (params.geography) {
        const geographyIDQueryParam = parseInt(params.geography);
        geographyFromQueryParam = this.uniqueGeographies.filter(x => x.GeographyID == geographyIDQueryParam);
      }
    });

    if (geographyFromQueryParam.length == 1) {
      this.selectedGeography.setValue(geographyFromQueryParam[0]);
    } else if (this.authenticationService.isCurrentUserAnAdministrator()) {
      this.selectedGeography.setValue(this.uniqueGeographies.find((x) => x.GeographyName == 'Demo'));
      this.parcelIDs = this.parcels.filter((x) => x.Geography.GeographyName == 'Demo').map((x) => x.Parcel.ParcelID);
    } else if (this.uniqueGeographies.length == 1) {
      this.selectedGeography.setValue(this.uniqueGeographies[0]);
    }
    this.isReady = true;
  }

  getParcelsFromGeography(geography: GeographyDisplayDto) {
    return this.geographyParcelDict[geography.GeographyName];
  }

  getNumberOfParcels(geography: GeographyDisplayDto) {
    return '(' + this.geographyParcelDict[geography.GeographyName].length + ' Parcels)';
  }

  setSelectedParcel(parcel) {
    this.highlightedParcelID = parcel.Parcel.ParcelID;
  }

  getGeographiesToShow() {
    if (this.selectedGeography.value == null) {
      this.parcelIDs = this.parcels.map((x) => x.Parcel.ParcelID);
      return this.uniqueGeographies;
    } else {
      this.parcelIDs = this.parcels.filter((x) => x.Geography.GeographyName == this.selectedGeography.value.GeographyName).map((x) => x.Parcel.ParcelID);
      return [this.selectedGeography.value];
    }
  }

  isCurrentUserAdmin() {
    return this.authenticationService.isCurrentUserAnAdministrator();
  }
}
