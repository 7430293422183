<div class="model-run-card">
    <div class="thumbnail">
        <scenario-image [scenario]="GETAction.Scenario"></scenario-image>
    </div>

    <div class="details">
        <div class="icon">
            <icon icon="ScenarioRun"></icon>
        </div>

        <div class="info">
            <h4>{{ GETAction.Scenario.ScenarioName }}</h4>
            <h3>
                {{ GETAction.RunName }}
                <name-tag [name]="GETAction.Model.ModelName"></name-tag>
            </h3>
            <get-action-status-bar [getAction]="GETAction"></get-action-status-bar>
        </div>
    </div>

    <div class="cta">
        <div class="mr-3">
            <a
                [routerLink]="[
                    '/scenario-planner',
                    'models',
                    GETAction.Model.ModelShortName,
                    GETAction.Scenario.ScenarioShortName,
                    GETAction.GETActionID
                ]"
                class="btn btn-primary-outline">
                View Scenario Run
            </a>
        </div>
    </div>
</div>
