/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { FormControl, FormControlOptions, FormControlState, Validators } from "@angular/forms";
export class EntityCustomAttributesDto { 
    CustomAttributes?: { [key: string]: string; };
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export interface EntityCustomAttributesDtoForm { 
    CustomAttributes?: FormControl<{ [key: string]: string; }>;
}

export class EntityCustomAttributesDtoFormControls { 
    public static CustomAttributes = (value: FormControlState<{ [key: string]: string; }> | { [key: string]: string; } = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<{ [key: string]: string; }>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
}
