/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { FormControl, FormControlOptions, FormControlState, Validators } from "@angular/forms";
export class WellRegistryWorkflowProgressDtoSteps { 
    SelectAParcel?: boolean;
    MapYourWell?: boolean;
    ConfirmLocation?: boolean;
    IrrigatedParcels?: boolean;
    Contacts?: boolean;
    BasicInformation?: boolean;
    SupportingInformation?: boolean;
    Attachments?: boolean;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export interface WellRegistryWorkflowProgressDtoStepsForm { 
    SelectAParcel?: FormControl<boolean>;
    MapYourWell?: FormControl<boolean>;
    ConfirmLocation?: FormControl<boolean>;
    IrrigatedParcels?: FormControl<boolean>;
    Contacts?: FormControl<boolean>;
    BasicInformation?: FormControl<boolean>;
    SupportingInformation?: FormControl<boolean>;
    Attachments?: FormControl<boolean>;
}

export class WellRegistryWorkflowProgressDtoStepsFormControls { 
    public static SelectAParcel = (value: FormControlState<boolean> | boolean = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<boolean>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static MapYourWell = (value: FormControlState<boolean> | boolean = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<boolean>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static ConfirmLocation = (value: FormControlState<boolean> | boolean = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<boolean>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static IrrigatedParcels = (value: FormControlState<boolean> | boolean = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<boolean>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static Contacts = (value: FormControlState<boolean> | boolean = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<boolean>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static BasicInformation = (value: FormControlState<boolean> | boolean = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<boolean>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static SupportingInformation = (value: FormControlState<boolean> | boolean = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<boolean>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static Attachments = (value: FormControlState<boolean> | boolean = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<boolean>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
}
