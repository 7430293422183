export const environment = {
  production: false,
  staging: true,
  dev: false,
  mainAppApiUrl: 'https://internalapi-qanat.esa-qa.sitkatech.com',
  geoserverMapServiceUrl: 'https://geoserver-qanat.esa-qa.sitkatech.com/geoserver/Qanat',
  platformLongName: 'Groundwater Accounting Platform',
  getDashboardUrl: 'https://getqa.azurewebsites.net',
  parcelBoundingBoxLeft: -120.841518219986,
  parcelBoundingBoxRight: -120.216218621317,
  parcelBoundingBoxTop: 37.4718525078379,
  parcelBoundingBoxBottom: 37.0983621505508,
  datadogClientToken: 'pub6bc5bcb39be6b4c926271a35cb8cb46a'
};