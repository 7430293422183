import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'key-value-pair-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './key-value-pair-list.component.html',
  styleUrls: ['./key-value-pair-list.component.scss']
})
export class KeyValuePairListComponent {

}
