<div class="stat">
    <div class="title">{{ statTitle }}</div>
    <div class="value-units flex">
        <span class="value">{{ value | number : '1.2-2' }}</span>
        <span class="units">{{ units }}</span>
    </div>
    <div class="description">
        {{ description }}
    </div>
</div>
