import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { UtilityFunctionsService } from 'src/app/shared/services/utility-functions.service';
import { WaterAccountBudgetReportDto, WaterAccountDto, WaterTypeSimpleDto } from 'src/app/shared/generated/model/models';
import { ReportingPeriodService } from 'src/app/shared/generated/api/reporting-period.service';
import { WaterAccountService } from 'src/app/shared/generated/api/water-account.service';
import { WaterTypeService } from 'src/app/shared/generated/api/water-type.service';
import { Observable, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { QanatGridComponent } from 'src/app/shared/components/qanat-grid/qanat-grid.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { LoadingDirective } from 'src/app/shared/directives/loading.directive';

@Component({
  selector: 'water-account-budget-grid',
  standalone: true,
  imports: [CommonModule, AgGridModule, QanatGridComponent, RouterModule, FormsModule, LoadingDirective],
  templateUrl: './water-account-budget-grid.component.html',
  styleUrls: ['./water-account-budget-grid.component.scss']
})
export class WaterAccountBudgetGridComponent implements OnInit {
  @Input() waterAccounts: WaterAccountDto[];

  public data$: Observable<any>;
  public budgetReports: WaterAccountBudgetReportDto[];
  public waterTypes: WaterTypeSimpleDto[];
  public columnDefs: ColDef[];
  public gridApi: GridApi;
  public isLoading = true;

  public selectedYear: number;
  public yearsInReportingPeriods: number[];

  constructor(
    private utilityFunctionsService: UtilityFunctionsService,
    private waterTypeService: WaterTypeService,
    private waterAccountService: WaterAccountService,
    private reportingPeriodService: ReportingPeriodService
  ) { }

  ngOnInit(): void {
    this.selectedYear = new Date().getFullYear();

    this.data$ = forkJoin([
      this.waterAccountService.waterAccountsWaterAccountBudgetReportYearGet(this.selectedYear),
      this.waterTypeService.waterTypesCurrentUserGeographiesWaterTypesGet(),
      this.reportingPeriodService.reportingPeriodsYearsGet()
    ]).pipe(
      tap(x => {
        this.budgetReports = x[0];
        this.waterTypes = x[1];
        this.yearsInReportingPeriods = x[2];

        this.createColumnDefs();
        this.isLoading = false;
      })
    );
  }

  budgetGridReady(event: GridReadyEvent) {
    event.api.hideOverlay();
    this.gridApi = event.api;
  }

  private createColumnDefs(): void {
    this.columnDefs = [
      this.utilityFunctionsService.createLinkColumnDef('Water Account #', 'WaterAccountNumber', 'WaterAccountID', {
        InRouterLink: './',
        FieldDefinitionType: 'WaterAccount'
      }),
      { headerName: 'Water Account Name', field: 'WaterAccountName' },
      this.utilityFunctionsService.createBasicColumnDef('Geography', 'GeographyName', { CustomDropdownFilterField: 'GeographyName' }),
      this.utilityFunctionsService.createBasicColumnDef('Reporting Period', 'ReportingPeriodName', { CustomDropdownFilterField: 'ReportingPeriodName' }),
      this.utilityFunctionsService.createDecimalColumnDef('Total Supply (ac-ft)', 'TotalSupply'),
      this.utilityFunctionsService.createDecimalColumnDef('Total Usage (ac-ft)', 'UsageToDate'),
      this.utilityFunctionsService.createDecimalColumnDef('Current Available (ac-ft)', 'CurrentAvailable'),
      this.utilityFunctionsService.createDecimalColumnDef('Acres Managed', 'AcresManaged'),
    ];
  }

  updateReportForSelectedYear() {
    this.gridApi.showLoadingOverlay();

    this.waterAccountService
      .waterAccountsWaterAccountBudgetReportYearGet(this.selectedYear)
      .subscribe(waterAccountBudgetReport => {
        this.budgetReports = waterAccountBudgetReport;
      });
  }
}
