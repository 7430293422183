<page-header *ngIf="waterAccount" [templateTitleAppend]="templateTitleAppend" [templateRight]="templateRight"
    [pageTitle]="'#' + waterAccount.WaterAccountNumber" icon="Users">
    <ng-template #templateTitleAppend>
        <small class="text-muted account-id">{{ waterAccount.WaterAccountName }}</small>
    </ng-template>
    <ng-template #templateRight>
        <a routerLink="/water-dashboard/water-accounts/{{ waterAccount.WaterAccountID }}" target="_blank">
            <span class="text-muted">
                <icon icon="ExternalLink"></icon>
                Landowner View
            </span>
        </a>
    </ng-template>
</page-header>

<div class="page-body grid-9">
    <app-alert-display></app-alert-display>

    <ng-container *ngIf="waterAccount; else loadingAccount">
        <div class="card g-col-5 basics" id="water_account_details_anchor">
            <div class="card-header flex-between">
                <h3 class="card-title">Basics</h3>
            </div>

            <div class="card-body">
                <dl class="kv-pair">
                    <dt class="key">Account Name</dt>
                    <dd class="value">
                        {{ waterAccount.WaterAccountName }}
                        <em *ngIf="!waterAccount.WaterAccountName" class="text-muted">
                            Not Available
                        </em>
                    </dd>

                    <dt class="key">Contact Address</dt>
                    <dd class="value">
                        {{ waterAccount.ContactAddress }}
                        <em *ngIf="!waterAccount.ContactAddress" class="text-muted">
                            Not Available
                        </em>
                    </dd>

                    <dt class="key">Account Number</dt>
                    <dd class="value">
                        {{ waterAccount.WaterAccountNumber }}
                        <em *ngIf="!waterAccount.WaterAccountNumber" class="text-muted">
                            Not Available
                        </em>
                    </dd>

                    <dt class="key">
                        <field-definition fieldDefinitionType="WaterAccountPIN" [inline]="false"></field-definition>
                    </dt>
                    <dd class="value">
                        {{ waterAccount.WaterAccountPIN }}
                        <em *ngIf="!waterAccount.WaterAccountPIN" class="text-muted">
                            Not Available
                        </em>
                    </dd>

                    <dt class="key">Water Account PIN Last Used</dt>
                    <dd class="value">
                        {{ waterAccount.WaterAccountPINLastUseDate | date : 'short' }}
                        <em *ngIf="!waterAccount.WaterAccountPINLastUseDate" class="text-muted">
                            Not Available
                        </em>
                    </dd>

                    <dt class="key">Notes</dt>
                    <dd class="value">
                        {{ waterAccount.Notes }}
                        <em *ngIf="!waterAccount.Notes" class="text-muted">Not Available</em>
                    </dd>
                </dl>
            </div>
        </div>

        <div class="card g-col-4">
            <div class="card-header flex-between">
                <h3 class="card-title">Users</h3>
            </div>
            <div class="card-body">
                <div class="flex mb-3" *ngFor="let waterAccountUser of waterAccount.Users">
                    <div class="mr-2">{{ waterAccountUser.User?.FullName }}</div>
                    <div>
                        <em>{{ waterAccountUser.UserEmail }}</em>
                    </div>
                </div>
            </div>
        </div>

        <div class="g-col-9 management-actions">
            <h3 class="module-title">Management Actions</h3>
            <div class="flex-start">
                <button class="btn btn-primary-outline" (click)="openUpdateParcelsModal()">
                    <i class="fas fa-map"></i>
                    Update Parcels
                </button>
                <button class="btn btn-primary-outline" (click)="openUpdateInfoModal()">
                    <i class="fas fa-info-circle"></i>
                    Update Info
                </button>
                <button class="btn btn-primary-outline" (click)="openMergeModal()">
                    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1.98137 13.467L0.146973 12.6686C1.23977 10.159 2.66857 8.27182 4.36697 7.15502C2.66857 6.03822 1.23897 4.15102 0.146973 1.64142L1.98137 0.843018C3.03577 3.26702 4.86537 5.99502 8.14697 5.99502L10.547 5.99502V3.55502L16.147 7.15502L10.547 10.755V8.31502H8.14697C4.86537 8.31502 3.03577 11.043 1.98137 13.467Z"
                            fill="#0D5B73" />
                    </svg>
                    Merge
                </button>
                <button class="btn btn-danger-outline" (click)="openDeleteModal()">
                    <i class="fa fa-times-circle"></i>
                    Delete
                </button>
            </div>
        </div>

        <div *ngIf="parcels && parcels.length > 0" class="card parcels">
            <div class="card-header">
                <h3 class="card-title">Parcels</h3>
            </div>
            <div class="card-body grid-9">
                <div class="g-col-6">
                    <parcel-map mapID="parcelsMapForUser" [selectedParcelIDs]="getSelectedParcelIDs()" mapHeight="500px"
                        [geographyID]="geographyID" [collapsedLayerControl]="true"></parcel-map>
                </div>

                <div class="g-col-3 parcels__list">
                    <div class="headline">
                        <h4 class="module-title underline">APNs</h4>
                    </div>

                    <div class="copy copy-3">
                        <ul>
                            <li *ngFor="let parcel of parcels">
                                <a [routerLink]="['../../', 'parcels', parcel.ParcelID]">
                                    {{ parcel.ParcelNumber }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="copy copy-3">
                    <p><em class="text-muted">Showing current parcels only</em></p>
                </div>
            </div>
        </div>

        <div class="card custom-attributes g-col-10"
            *ngIf="waterAccountCustomAttributes$ | async as waterAccountCustomAttributes">
            <div class="card-header flex-between">
                <h3 class="card-title">Attributes</h3>

                <a *ngIf="waterAccountCustomAttributes.CustomAttributes" class="btn btn-sm btn-secondary"
                    routerLink="edit-attributes">
                    <span class="fas fa-edit"></span>
                    Edit Attributes
                </a>
            </div>
            <div class="card-body">
                <key-value-pair-list *ngIf="
                        waterAccountCustomAttributes.CustomAttributes;
                        else noCustomAttributesConfigured
                    ">
                    <key-value-pair [horizontal]="true" [copyValueToClipboard]="customAttribute.value !== ''" *ngFor="
                            let customAttribute of waterAccountCustomAttributes.CustomAttributes
                                | keyvalue
                        ">
                        <ng-container key>{{ customAttribute.key }}</ng-container>
                        <ng-container keyValue>
                            {{ customAttribute.value }}
                            <em *ngIf="!customAttribute.value" class="text-muted">Not Available</em>
                        </ng-container>
                    </key-value-pair>
                </key-value-pair-list>
                <ng-template #noCustomAttributesConfigured>
                    <div class="copy copy-3 no-custom-attributes">
                        No custom attributes have been configured for Water Accounts in this
                        geography.
                        <br />
                        Contact an administrator to learn more about this feature.
                    </div>
                </ng-template>
            </div>
        </div>

        <div *ngIf="wells && wells.length > 0" class="card wells">
            <div class="card-header">
                <h3 class="card-title">Wells</h3>
            </div>
            <div class="card-body grid-9">
                <div class="g-col-3 wells__list">
                    <div class="copy copy-3">
                        <ul>
                            <li *ngFor="let well of wells">
                                <a [routerLink]="['../../', 'wells', well.WellID]">
                                    {{ well.WellName }} ({{ well.WellID }})
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #loadingAccount>
        <h2 class="d-inline-block">
            Loading Account
            <span class="fa fa-spinner loading-spinner"></span>
        </h2>
    </ng-template>
</div>