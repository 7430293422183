<app-alert-display></app-alert-display>

<ng-container *ngIf="currentUser$ | async">
    <ng-container *ngIf="waterAccounts?.length > 0; else noWaterAccountsMessage">
        <div class="flex-center mb-4">
            <div class="button-group">
                <button
                    class="button-group__item active"
                    [class.active]="viewingWaterAccountsTab"
                    (click)="viewingWaterAccountsTab = true">
                    Water Account Map
                </button>
                <button
                    class="button-group__item"
                    [class.active]="!viewingWaterAccountsTab"
                    (click)="viewingWaterAccountsTab = false">
                    Budget Data
                </button>
            </div>
        </div>

        <section class="map-view" *ngIf="viewingWaterAccountsTab">
            <div class="grid-12">
                <div class="g-col-3">
                    <div class="form-field" [hidden]="uniqueGeographies.length === 1">
                        <div class="field">
                            <label for="GeographyPicker" class="field-label">
                                Filter by Geography
                            </label>
                            <select
                                name="GeographyPicker"
                                id="GeographyPicker"
                                *ngIf="uniqueGeographies.length > 0"
                                [formControl]="selectedGeography">
                                <option
                                    [ngValue]="null"
                                    *ngIf="!isCurrentUserAdmin() && uniqueGeographies.length > 1">
                                    All Water Accounts
                                </option>
                                <option
                                    [ngValue]="geography"
                                    *ngFor="let geography of uniqueGeographies">
                                    {{ geography.GeographyName }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid-12 mt-4">
                <div class="g-col-4">
                    <div class="water-account-list scroll-shadow">
                        <div class="mb-4" *ngFor="let geography of getGeographiesToShow()">
                            <h3 class="module-title underline mb-3">
                                {{ geography.GeographyName }}
                                {{ getNumberOfWaterAccounts(geography) }}
                            </h3>
                            <div class="water-account-list__wrapper">
                                <water-account-small-card
                                    *ngFor="let waterAccount of getWaterAccounts(geography)"
                                    [waterAccount]="waterAccount"
                                    [isHighlighted]="
                                        highlightedWaterAccountID === waterAccount.WaterAccountID
                                    "
                                    (click)="
                                        selectWaterAccount(waterAccount)
                                    "></water-account-small-card>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="g-col-8" *ngIf="isReady">
                    <water-account-dashboard-map
                        [waterAccounts]="waterAccounts"
                        [geography]="selectedGeography.value"
                        [(highlightedWaterAccountID)]="
                            highlightedWaterAccountID
                        "></water-account-dashboard-map>
                </div>
            </div>
        </section>

        <section class="budget-view" *ngIf="!viewingWaterAccountsTab">
            <water-account-budget-grid></water-account-budget-grid>
        </section>
    </ng-container>
    <ng-template #noWaterAccountsMessage>
        <div class="alert alert-info">
            <div class="alert-content">
                <i class="fa fa-info"></i>
                Your user profile does not have access to manage any Water Accounts at this time.
                Claim Water Accounts to get started or contact a water manager for your geography
                for assistance.
            </div>
        </div>
    </ng-template>
</ng-container>
