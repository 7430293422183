<page-header *ngIf="parcel$ | async as parcel" [templateRight]="templateRight"
    [templateTitleAppend]="templateTitleAppend" icon="Parcels" [pageTitle]="parcel.ParcelNumber">
    <ng-template #templateTitleAppend>
        <small class="text-muted">{{ parcel.ParcelArea | number : '1.0-1' }} acres</small>
    </ng-template>
    <ng-template #templateRight>
        <a [routerLink]="['/water-dashboard', 'parcels', parcel.ParcelID]" target="_blank">
            <span class="text-muted">
                <icon icon="ExternalLink"></icon>
                Landowner View
            </span>
        </a>
    </ng-template>
</page-header>

<div class="page-body grid-12">
    <app-alert-display></app-alert-display>

    <parcel-detail-manage-card *ngIf="parcel$ | async as parcel" [parcel]="parcel" [geography]="geography"
        (onParcelUpdated)="refreshParcel()"></parcel-detail-manage-card>

    <div class="card custom-attributes g-col-12" id="custom_attributes_anchor"
        *ngIf="parcelCustomAttributes$ | async as parcelCustomAttributes">
        <div class="card-header flex-between">
            <h3 class="card-title">Attributes</h3>

            <a *ngIf="parcelCustomAttributes.CustomAttributes" class="btn btn-sm btn-secondary"
                routerLink="edit-attributes">
                <span class="fas fa-edit"></span>
                Edit Attributes
            </a>
        </div>
        <div class="card-body">
            <key-value-pair-list *ngIf="parcelCustomAttributes.CustomAttributes; else noCustomAttributesConfigured">
                <key-value-pair [horizontal]="true" [copyValueToClipboard]="customAttribute.value !== ''" *ngFor="
                        let customAttribute of parcelCustomAttributes.CustomAttributes | keyvalue
                    ">
                    <ng-container key>{{ customAttribute.key }}</ng-container>
                    <ng-container keyValue>
                        {{ customAttribute.value }}
                        <em *ngIf="!customAttribute.value" class="text-muted">Not Available</em>
                    </ng-container>
                </key-value-pair>
            </key-value-pair-list>
            <ng-template #noCustomAttributesConfigured>
                <div class="copy copy-3 no-custom-attributes">
                    No custom attributes have been configured for Parcels in this geography.
                    <br />
                    Contact an administrator to learn more about this feature.
                </div>
            </ng-template>
        </div>
    </div>

    <vega-parcel-usage-chart id="water_measurements_anchor" *ngIf="parcel$ | async as parcel"
        [parcel]="parcel"></vega-parcel-usage-chart>

    <div class="card activity" id="ledger_activity_anchor" *ngIf="parcel$ | async as parcel">
        <div class="card-header flex-between">
            <h3 class="card-title">Parcel Supply Activity</h3>
            <a class="btn btn-secondary btn-sm" routerLink="../../water-transactions/new/{{ parcel.ParcelID }}">
                <span class="fas fa-edit"></span>
                Create New Transaction
            </a>
        </div>

        <div class="card-body">
            <qanat-grid *ngIf="parcelSupplies$ | async as parcelSupplies" [columnDefs]="parcelSupplyGridColumnDefs"
                [rowData]="parcelSupplies" height="300px" [pagination]="true" [paginationPageSize]="100"
                downloadFileName="parcel-supply-{{ parcel.ParcelNumber }}">
                <span gridFootnote>All transaction data is in units of acre-feet.</span>
            </qanat-grid>

            <div class="meta flex-end mt-2">
                <div class="copy copy-3">
                    <note>
                        <p class="flex-between">
                            <icon icon="Info"></icon>
                            All transaction data is in units of acre-feet.
                        </p>
                    </note>
                </div>
            </div>
        </div>
    </div>

    <div class="card ownership-history" id="ownership_history_anchor" *ngIf="parcel$ | async as parcel">
        <div class="card-header flex-between">
            <h3 class="card-title">Parcel History</h3>
            <button class="btn btn-secondary" (click)="updateOwnershipInfo()">
                Update Ownership Info
            </button>
        </div>

        <div class="card-body">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Effective Year</th>
                            <th>Water Account</th>
                            <th>Owner Name</th>
                            <th>Owner Address</th>
                            <th>Status</th>
                            <th>Updated</th>
                            <th>Updated By</th>
                            <th>Source</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let parcelHistory of parcelHistories$ | async">
                            <td>
                                {{ parcelHistory.EffectiveYear }}
                            </td>
                            <td>
                                <a *ngIf="parcelHistory.WaterAccount" class="no-underline" routerLink="../../water-accounts/{{
                                        parcelHistory.WaterAccountID
                                    }}">
                                    <water-account-title [waterAccountName]="
                                            parcelHistory.WaterAccount?.WaterAccountName
                                        " [waterAccountNumber]="
                                            parcelHistory.WaterAccount?.WaterAccountNumber.toString()
                                        "></water-account-title>
                                </a>
                            </td>
                            <td>
                                {{ parcelHistory.OwnerName }}
                            </td>
                            <td>
                                {{ parcelHistory.OwnerAddress }}
                            </td>
                            <td>
                                {{ parcelHistory.ParcelStatus.ParcelStatusDisplayName }}
                            </td>
                            <td>
                                {{ parcelHistory.UpdateDate | date : 'short' }}
                            </td>
                            <td>
                                {{ parcelHistory.UpdateUserFullName }}
                            </td>
                            <td>
                                {{ parcelHistory.IsManualOverride ? 'Manual Entry' : 'GDB Upload' }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="card ownership-history" id="water_account_history_anchor">
        <div class="card-header flex-between">
            <h3 class="card-title">Water Accounts</h3>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th style="width: 25%">Effective Year</th>
                            <th style="width: 25%">End Year</th>
                            <th style="width: 50%">Water Account</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="waterAccountParcels$ | async as waterAccountParcels">
                            <ng-container *ngIf="waterAccountParcels.length; else noHistory">
                                <tr *ngFor="let waterAccountParcel of waterAccountParcels">
                                    <td>
                                        {{ waterAccountParcel.EffectiveYear }}
                                    </td>
                                    <td>
                                        {{ waterAccountParcel.EndYear ?? 'Current' }}
                                    </td>
                                    <td>
                                        <a class="no-underline" routerLink="../../water-accounts/{{
                                                waterAccountParcel.WaterAccountID
                                            }}">
                                            <water-account-title [waterAccountName]="
                                                    waterAccountParcel.WaterAccount
                                                        ?.WaterAccountName
                                                " [waterAccountNumber]="
                                                    waterAccountParcel.WaterAccount?.WaterAccountNumber.toString()
                                                "></water-account-title>
                                        </a>
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-template #noHistory>
                                <td colspan="3">
                                    <em>This parcel does not have any water account history.</em>
                                </td>
                            </ng-template>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>