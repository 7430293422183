<div class="water-account-small-card" [class.highlighted]="isHighlighted">
    <div class="meta">
        <icon icon="Parcels"></icon>
        <span class="parcel-number">{{ parcel.Parcel.ParcelNumber }}</span>
        <span class="link">
            <a [routerLink]="['/water-dashboard', 'parcels', parcel?.Parcel.ParcelID]">Details</a>
        </span>
    </div>
    <div class="acres">{{ parcel.Parcel.ParcelArea | number: '1.2-2' }} acres</div>
</div>
