<div>
    <div class="modal-header">
        <h3 class="section-title">
            <icon icon="Wells"></icon>
            Delete Well
        </h3>
        <button type="button" class="close" (click)="close()">
            <i class="fa fa-times-circle"></i>
        </button>
    </div>

    <div class="modal-body">
        <div class="copy copy-3 description-color p-2">
            <p>Are you sure you want to delete this well? This cannot be undone.</p>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn btn-danger" (click)="save()">Delete Well</button>
        <button class="btn btn-primary-outline" (click)="close()">Cancel</button>
    </div>
</div>
