<page-header pageTitle="Confirm Well Location">
    <workflow-help [customRichTextTypeID]="customRichTextTypeID"></workflow-help>
</page-header>
<workflow-body>
    <app-alert-display></app-alert-display>

    <well-location-edit-map
        *ngIf="confirmWellLocation$ | async as wellLocation"
        [wellLatLng]="wellLatLng"
        [parcelGeoJson]="wellLocation.ParcelGeoJson"
        [boundingBox]="wellLocation.BoundingBox"
        (locationChanged)="onLocationChanged($event)"></well-location-edit-map>
</workflow-body>

<div class="page-footer">
    <fresca-button class="ml-auto" (click)="saveAndContinue()" [disabled]="isLoadingSubmit">
        <span *ngIf="isLoadingSubmit" class="fa fa-spinner loading-spinner"></span>
        Save & Continue
    </fresca-button>
</div>
