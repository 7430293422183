import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import 'leaflet.markercluster';
import '../../../../../node_modules/leaflet.fullscreen/Control.FullScreen.js';
import '../../../../../node_modules/leaflet-loading/src/Control.Loading.js';
import * as L from 'leaflet';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { GsaBoundariesComponent } from '../leaflet/layers/gsa-boundaries/gsa-boundaries.component';
import { UsageEntitiesLayerComponent } from '../leaflet/layers/usage-entities-layer/usage-entities-layer.component';
import { LeafletHelperService } from 'src/app/shared/services/leaflet-helper.service';
import { BoundingBoxDto, GeographyDto, WaterAccountDashboardDto } from 'src/app/shared/generated/model/models.js';
import { GeographyService } from 'src/app/shared/generated/api/geography.service';
import { WfsService } from 'src/app/shared/services/wfs.service';
import { GroupByPipe } from 'src/app/shared/pipes/group-by.pipe';

@Component({
  selector: 'water-account-dashboard-map',
  standalone: true,
  imports: [CommonModule, GsaBoundariesComponent, UsageEntitiesLayerComponent],
  templateUrl: './water-account-dashboard-map.component.html',
  styleUrls: ['./water-account-dashboard-map.component.scss']
})
export class WaterAccountDashboardMapComponent implements OnInit, OnDestroy, OnChanges {
  @Input() waterAccounts: WaterAccountDashboardDto[];
  @Input() geography: GeographyDto;

  // highlighted water account
  private _highlightedWaterAccountID: number = null;
  @Input() set highlightedWaterAccountID(value: number) {
    if (this.highlightedWaterAccountID != value) {
      this._highlightedWaterAccountID = value;
      this.highlightedWaterAccountIDChange.emit(value);
      this.changedWaterAccount(value);
    }
  }
  @Output() public highlightedWaterAccountIDChange: EventEmitter<number> = new EventEmitter<number>();
  get highlightedWaterAccountID(): number {
    return this._highlightedWaterAccountID;
  }

  private defaultStyle = {
    'color': '#3388ff',
    'weight': 2,
    'opacity': 0.65,
    'fillOpacity': 0.1
  };

  private highlightStyle = {
    'color': '#fcfc12',
    'weight': 2,
    'opacity': 0.65,
    'fillOpacity': 0.1
  };

  private usageEntityDefaultStyle = {
    'color': '#7ee556',
    'weight': 2,
    'opacity': 0.65,
    'fillOpacity': 0.1
  };

  private usageEntityHighlightStyle = {
    'color': '#7ee556',
    'weight': 2,
    'opacity': 0.65,
    'fillOpacity': 0.1
  };

  private geoserverWFSSubscription: Subscription = Subscription.EMPTY;
  private geoserverWFSUsageEntitiesSubscription: Subscription = Subscription.EMPTY;
  public map: L.Map;
  public featureLayer: any;
  public layerControl: L.Control.Layers;
  public boundingBox: BoundingBoxDto = {
    Left: environment.parcelBoundingBoxLeft,
    Bottom: environment.parcelBoundingBoxBottom,
    Right: environment.parcelBoundingBoxRight,
    Top: environment.parcelBoundingBoxTop
  };
  public tileLayers: { [key: string]: any } = LeafletHelperService.GetDefaultTileLayers();

  constructor(
    private wfsService: WfsService,
    private geographyService: GeographyService,
    private groupByPipe: GroupByPipe
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.geography && !changes.geography.firstChange) {
      this.updateWaterAccountParcels(true);
      this.updateWaterAccountUsageEntities();
    }
  }

  ngOnDestroy(): void {
    this.geoserverWFSSubscription.unsubscribe();
  }

  ngAfterContentInit(): void {
    this.initMap();
  }

  ngOnInit(): void {
    this.isLoadingWaterAccounts = true;
  }

  initMap(): void {
    const mapOptions: L.MapOptions = {
      minZoom: 6,
      maxZoom: 17,
      layers: [
        this.tileLayers.Aerial,
      ],
      fullscreenControl: true,
      gestureHandling: true
    } as L.MapOptions;
    this.map = L.map('WaterAccountDashboardMap', mapOptions);
    this.map.fitBounds([[this.boundingBox.Bottom, this.boundingBox.Left], [this.boundingBox.Top, this.boundingBox.Right]]);

    this.layerControl = new L.Control.Layers(this.tileLayers, null, { collapsed: true })
      .addTo(this.map);
    this.updateWaterAccountParcels();
    this.updateWaterAccountUsageEntities();
  }

  private allWaterAccountsFeatureGroup = L.featureGroup();
  private allUsageEntitiesFeatureGroup = L.featureGroup();
  public isLoadingWaterAccounts: boolean;

  private updateWaterAccountParcels(fitBoundsAfterLoad: boolean = true): void {
    this.layerControl.removeLayer(this.allWaterAccountsFeatureGroup);
    this.geoserverWFSSubscription.unsubscribe();
    this.isLoadingWaterAccounts = true;
    const waterAccountIDs = this.geography
      ? this.waterAccounts.filter(x => x.Geography.GeographyID == this.geography?.GeographyID).map(x => x.WaterAccountID).join(',')
      : this.waterAccounts.map(x => x.WaterAccountID).join(',');

    const cql_filter = `WaterAccountID in(${waterAccountIDs})`;

    this.geoserverWFSSubscription = this.wfsService.getGeoserverWFSLayer(null, 'Qanat:AllParcels', cql_filter)
      .subscribe(response => {
        const featuresGroupedByWaterAccountID = this.groupByPipe.transform(response, 'properties.WaterAccountID');
        this.allWaterAccountsFeatureGroup.clearLayers();

        Object.keys(featuresGroupedByWaterAccountID).forEach(waterAccountID => {
          const geoJson = L.geoJSON(featuresGroupedByWaterAccountID[waterAccountID], {
            style: this.defaultStyle
          });

          // IMPORTANT: THIS ONLY WORKS BECAUSE I'VE INSTALLED @angular/elements AND CONFIGURED THIS IN THE app.module.ts bootstrapping
          geoJson.bindPopup(`<water-account-popup-custom-element water-account-id="${waterAccountID}"></water-account-popup-custom-element>`, {
            maxWidth: 475,
            keepInView: true
          });

          geoJson.on('mouseover', (e) => {
            geoJson.setStyle({ 'fillOpacity': 0.5 });
          });
          geoJson.on('mouseout', (e) => {
            geoJson.setStyle({ 'fillOpacity': 0.1 });
          });

          geoJson.on('click', (e) => {
            this.changedWaterAccount(Number(waterAccountID));
          });

          this.allWaterAccountsFeatureGroup.addLayer(geoJson);
        });

        this.allWaterAccountsFeatureGroup.addTo(this.map);
        this.layerControl.addOverlay(this.allWaterAccountsFeatureGroup, '<span style=\'display:inline-flex; gap:5px;\'><span style=\'width:15px;height:16px;background:#3388ff30;display: inline-block;border: 2px solid #3388ff;\'></span>My Water Accounts</span>');
        if (fitBoundsAfterLoad) {
          this.map.fitBounds(this.allWaterAccountsFeatureGroup.getBounds());
        }
        this.isLoadingWaterAccounts = false;
      });
  }

  private updateWaterAccountUsageEntities(): void {
    this.layerControl.removeLayer(this.allUsageEntitiesFeatureGroup);
    if (this.geography == null) {
      return;
    }

    this.geographyService.geographiesGeographyIDGet(this.geography.GeographyID).subscribe(geography => {

      if (!geography.DisplayUsageGeometriesAsField) return;

      this.geoserverWFSUsageEntitiesSubscription.unsubscribe();
      const waterAccountIDs = this.waterAccounts.filter(x => x.Geography.GeographyID == this.geography.GeographyID).map(x => x.WaterAccountID).join(',');

      const cql_filter = `WaterAccountID in(${waterAccountIDs})`;

      this.geoserverWFSUsageEntitiesSubscription = this.wfsService.getGeoserverWFSLayer(null, 'Qanat:AllUsageEntities', cql_filter)
        .subscribe(response => {
          // const featuresGroupedByWaterAccountID = this.groupByPipe.transform(response, 'properties.WaterAccountID');
          this.allUsageEntitiesFeatureGroup.clearLayers();

          response.forEach(usageEntity => {
            const geoJson = L.geoJSON(usageEntity, {
              style: this.usageEntityDefaultStyle
            });

            // IMPORTANT: THIS ONLY WORKS BECAUSE I'VE INSTALLED @angular/elements AND CONFIGURED THIS IN THE app.module.ts bootstrapping
            geoJson.bindPopup(`<usage-entity-popup-custom-element usage-entity-id="${(usageEntity as any).properties.UsageEntityID}"></usage-entity-popup-custom-element>`, {
              maxWidth: 475,
              keepInView: true
            });

            geoJson.on('mouseover', (e) => {
              geoJson.setStyle({ 'fillOpacity': 0.5 });
            });
            geoJson.on('mouseout', (e) => {
              geoJson.setStyle({ 'fillOpacity': 0.1 });
            });

            this.allUsageEntitiesFeatureGroup.addLayer(geoJson);
          });

          this.allUsageEntitiesFeatureGroup.addTo(this.map);
          this.layerControl.addOverlay(this.allUsageEntitiesFeatureGroup, '<span style=\'display:inline-flex; gap:5px;\'><span style=\'width:15px;height:16px;background:#7ee55630;display: inline-block;border: 2px solid #7ee556;\'></span>My Fields</span>');

        });

    });


  }

  changedWaterAccount(waterAccountID: number) {
    this.highlightedWaterAccountID = waterAccountID;

    // clear styles
    this.allWaterAccountsFeatureGroup.setStyle(this.defaultStyle);

    // loop through the allWaterAccountsFeatureGroup
    this.allWaterAccountsFeatureGroup.eachLayer((layer) => {

      const geoJsonLayers = layer.getLayers();
      if (geoJsonLayers[0].feature.properties.WaterAccountID == waterAccountID) {
        layer.setStyle(this.highlightStyle);
        layer.openPopup();
        this.map.fitBounds(layer.getBounds());
      }
    });
  }

}
