import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-community';
import { Observable, share, switchMap, tap } from 'rxjs';
import { routeParams } from 'src/app/app.routes';
import { UtilityFunctionsService } from 'src/app/shared/services/utility-functions.service';
import { CustomAttributeService } from 'src/app/shared/generated/api/custom-attribute.service';
import { ParcelService } from 'src/app/shared/generated/api/parcel.service';
import { EntityCustomAttributesDto, GeographyDto, ParcelDetailDto, ParcelSupplyDetailDto, ParcelHistoryDto, WaterAccountParcelDto } from 'src/app/shared/generated/model/models';
import { ModalService, ModalSizeEnum, ModalThemeEnum } from 'src/app/shared/services/modal/modal.service';
import { SelectedGeographyService } from 'src/app/shared/services/selected-geography.service';
import { ViewingDetailMenuService } from 'src/app/shared/services/viewing-detail-menu.service';
import { WaterAccountTitleComponent } from '../../shared/components/water-account/water-account-title/water-account-title.component';
import { NoteComponent } from '../../shared/components/note/note.component';
import { VegaParcelUsageChartComponent } from '../../shared/components/vega/vega-parcel-usage-chart/vega-parcel-usage-chart.component';
import { AlertDisplayComponent } from '../../shared/components/alert-display/alert-display.component';
import { NgIf, NgFor, AsyncPipe, DecimalPipe, DatePipe, KeyValuePipe } from '@angular/common';
import { PageHeaderComponent } from 'src/app/shared/components/page-header/page-header.component';
import { IconComponent } from 'src/app/shared/components/icon/icon.component';
import { ParcelDetailManageCardComponent } from 'src/app/shared/components/parcel-detail-manage-card/parcel-detail-manage-card.component';
import { KeyValuePairListComponent } from 'src/app/shared/components/key-value-pair-list/key-value-pair-list.component';
import { KeyValuePairComponent } from 'src/app/shared/components/key-value-pair/key-value-pair.component';
import { QanatGridComponent } from 'src/app/shared/components/qanat-grid/qanat-grid.component';
import { ParcelUpdateOwnershipInfoModalComponent } from 'src/app/shared/components/parcel-update-ownership-info-modal/parcel-update-ownership-info-modal.component';

@Component({
  selector: 'parcel-detail',
  templateUrl: './parcel-detail.component.html',
  styleUrls: ['./parcel-detail.component.scss'],
  standalone: true,
  imports: [NgIf, PageHeaderComponent, RouterLink, IconComponent, AlertDisplayComponent, ParcelDetailManageCardComponent, KeyValuePairListComponent, NgFor, KeyValuePairComponent, VegaParcelUsageChartComponent, QanatGridComponent, NoteComponent, WaterAccountTitleComponent, AsyncPipe, DecimalPipe, DatePipe, KeyValuePipe]
})
export class ParcelDetailComponent implements OnInit, OnDestroy {

  @ViewChild('parcelSupplyGrid') parcelSupplyGrid: AgGridAngular;

  public geography: GeographyDto;
  public parcel$: Observable<ParcelDetailDto>;
  public parcelSupplies$: Observable<ParcelSupplyDetailDto[]>;
  public parcelHistories$: Observable<ParcelHistoryDto[]>;
  public waterAccountParcels$: Observable<WaterAccountParcelDto[]>;

  public parcelID: number;
  public parcelSupplyGridColumnDefs: ColDef[];
  public supplyTypeColDefInsertIndex = 3;

  public parcelCustomAttributes$: Observable<EntityCustomAttributesDto>;

  constructor(
    private route: ActivatedRoute,
    private parcelService: ParcelService,
    private cdr: ChangeDetectorRef,
    private utilityFunctionsService: UtilityFunctionsService,
    private selectedGeographyService: SelectedGeographyService,
    private viewingDetailMenuService: ViewingDetailMenuService,
    private customAttributeService: CustomAttributeService,
    private modalService: ModalService
  ) {
  }

  ngOnInit() {
    this.refreshParcel();
  }

  refreshParcel(): void {
    const parcelID = parseInt(this.route.snapshot.paramMap.get(routeParams.parcelID));
    this.parcelID = parcelID;

    this.parcel$ = this.selectedGeographyService.curentUserSelectedGeographyObservable.pipe(
      tap(geography => this.geography = geography),
      switchMap(() => this.parcelService.parcelsParcelIDZonesGet(parcelID)),
      tap(parcel => this.viewingDetailMenuService.loadedParcel(parcel)),
      share()
    );

    this.parcelSupplies$ = this.parcelService.parcelsParcelIDGetSupplyEntriesGet(parcelID).pipe(tap(x => this.initializeSupplyGrid()));
    this.parcelHistories$ = this.parcelService.parcelsParcelIDHistoryGet(parcelID);
    this.waterAccountParcels$ = this.parcelService.parcelsParcelIDWaterAccountParcelsGet(parcelID);
    this.parcelCustomAttributes$ = this.customAttributeService.customAttributesParcelsParcelIDGet(parcelID);
  }

  initializeSupplyGrid() {
    this.parcelSupplyGridColumnDefs = [
      this.utilityFunctionsService.createBasicColumnDef('Supply Type', 'WaterType.WaterTypeName', {
        FieldDefinitionType: 'SupplyType',
        CustomDropdownFilterField: 'WaterType.WaterTypeName'
      }),
      this.utilityFunctionsService.createDateColumnDef('Effective Date', 'EffectiveDate', 'M/d/yyyy', { FieldDefinitionType: 'EffectiveDate' }),
      this.utilityFunctionsService.createDateColumnDef('Transaction Date', 'TransactionDate', 'short'),
      this.utilityFunctionsService.createDecimalColumnDef('Transaction Volume (ac-ft)', 'TransactionAmount'),
      this.utilityFunctionsService.createDecimalColumnDef('Transaction Depth (ac-ft / ac)', '', {
        ValueGetter: params => this.utilityFunctionsService.customDecimalValueGetter((params.data.TransactionAmount / params.data.Parcel.ParcelArea), 2)
      }),
      {
        headerName: 'Comment',
        field: 'UserComment',
        filter: false,
        sortable: false,
      }
    ];
  }

  ngOnDestroy() {
    this.cdr.detach();
    this.viewingDetailMenuService.unLoadedParcel();
  }

  updateOwnershipInfo(): void {
    this.modalService.open(ParcelUpdateOwnershipInfoModalComponent,
      null,
      { ModalSize: ModalSizeEnum.Medium, ModalTheme: ModalThemeEnum.Light, OverflowVisible: true },
      { ParcelID: this.parcelID }).instance.result
      .then(succeeded => {
        if (succeeded) {
          this.refreshParcel();
        }
      });
  }
}
