<div class="water-account-small-card" [class.highlighted]="isHighlighted">
    <div class="meta">
        <span *ngFor="let zone of waterAccount.Zones">
            <name-tag [name]="zone.ZoneName" [color]="zone.ZoneColor"></name-tag>
        </span>
        <span class="parcels-nametag">{{ waterAccount.NumOfParcels }} Parcels</span>
    </div>
    <water-account-title
        [waterAccountName]="waterAccount.WaterAccountName"
        [waterAccountNumber]="waterAccount.WaterAccountNumber.toString()"></water-account-title>
    <div class="link">
        <a
            [routerLink]="[
                '/water-dashboard',
                'water-accounts',
                waterAccount?.WaterAccountID,
                'water-budget'
            ]">
            Details
        </a>
    </div>
</div>
