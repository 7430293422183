/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { WaterAccountDisplayDto } from '././water-account-display-dto';
import { ParcelStatusSimpleDto } from '././parcel-status-simple-dto';

import { FormControl, FormControlOptions, FormControlState, Validators } from "@angular/forms";
export class ParcelMinimalDto { 
    ParcelID?: number;
    GeographyID?: number;
    WaterAccountID?: number;
    ParcelNumber?: string;
    ParcelArea?: number;
    ParcelStatusID?: number;
    OwnerAddress?: string;
    OwnerName?: string;
    WaterAccount?: WaterAccountDisplayDto;
    ParcelStatus?: ParcelStatusSimpleDto;
    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}

export interface ParcelMinimalDtoForm { 
    ParcelID?: FormControl<number>;
    GeographyID?: FormControl<number>;
    WaterAccountID?: FormControl<number>;
    ParcelNumber?: FormControl<string>;
    ParcelArea?: FormControl<number>;
    ParcelStatusID?: FormControl<number>;
    OwnerAddress?: FormControl<string>;
    OwnerName?: FormControl<string>;
    WaterAccount?: FormControl<WaterAccountDisplayDto>;
    ParcelStatus?: FormControl<ParcelStatusSimpleDto>;
}

export class ParcelMinimalDtoFormControls { 
    public static ParcelID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static GeographyID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WaterAccountID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static ParcelNumber = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static ParcelArea = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static ParcelStatusID = (value: FormControlState<number> | number = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<number>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static OwnerAddress = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static OwnerName = (value: FormControlState<string> | string = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<string>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static WaterAccount = (value: FormControlState<WaterAccountDisplayDto> | WaterAccountDisplayDto = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<WaterAccountDisplayDto>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
    public static ParcelStatus = (value: FormControlState<ParcelStatusSimpleDto> | ParcelStatusSimpleDto = undefined, formControlOptions?: FormControlOptions | null) => new FormControl<ParcelStatusSimpleDto>(
        value,
        formControlOptions ?? 
        {
            nonNullable: false,
            validators: 
            [
            ],
        }
    );
}
