import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { GeographyDisplayDto, WellLandownerDashboardDto, WellRegistrationLocationDto } from 'src/app/shared/generated/model/models';
import * as L from 'leaflet';
import { AgGridModule } from 'ag-grid-angular';
import { LandownerWellGridComponent } from 'src/app/shared/components/well/landowner-well-grid/landowner-well-grid.component';
import { QanatMapComponent, QanatMapInitEvent } from '../leaflet/qanat-map/qanat-map.component';
import { OpenedWellPopupEvent, WellsLayerComponent } from '../leaflet/layers/wells-layer/wells-layer.component';
import { ActivatedRoute } from '@angular/router';
import { WellSmallCardComponent } from '../well-small-card/well-small-card.component';

@Component({
  selector: 'water-dashboard-wells',
  standalone: true,
  imports: [CommonModule, WellSmallCardComponent, ReactiveFormsModule, AgGridModule, LandownerWellGridComponent, QanatMapComponent, WellsLayerComponent],
  templateUrl: './water-dashboard-wells.component.html',
  styleUrls: ['./water-dashboard-wells.component.scss']
})
export class WaterDashboardWellsComponent implements OnInit {
  @Input() wells: WellLandownerDashboardDto[];
  public wellLocationDtos: WellRegistrationLocationDto[];
  public uniqueGeographies: GeographyDisplayDto[] = [];
  public selectedGeography: FormControl<GeographyDisplayDto> = new FormControl<GeographyDisplayDto>(null);
  public geographyWellDict: { [GeographyName: string]: WellLandownerDashboardDto[] } = {};
  public viewingWellCardTab: boolean = true;
  public wellIDs: number[] = [];
  public isReady: boolean = false;

  private _highlightedWellID: number;
  set highlightedWellID(value: number) {
    this._highlightedWellID = value;
  }

  get highlightedWellID(): number {
    return this._highlightedWellID;
  }

  constructor(
    public authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute
  ) { }


  public ngOnInit(): void {
    this.wellIDs = this.wells.map((x) => x.WellID);
    this.wellLocationDtos;
    this.filterGeographies();
  }

  filterGeographies() {
    const geographies = this.wells.map((x) => x.Geography);
    this.uniqueGeographies = [];

    geographies.forEach((geography) => {
      const alreadyExists = this.uniqueGeographies.map((x) => x.GeographyID).includes(geography.GeographyID);
      if (!alreadyExists) {
        this.uniqueGeographies.push(geography);
      }
      this.geographyWellDict[geography.GeographyName] = this.wells.filter((x) => x.Geography.GeographyName == geography.GeographyName);
    });

    let geographyFromQueryParam = [];
    this.route.queryParams.subscribe(params => {
      if (params.geography) {
        const geographyIDQueryParam = parseInt(params.geography);
        geographyFromQueryParam = this.uniqueGeographies.filter(x => x.GeographyID == geographyIDQueryParam);
      }
    });

    if (geographyFromQueryParam.length == 1) {
      this.selectedGeography.setValue(geographyFromQueryParam[0]);
    } else if (this.authenticationService.isCurrentUserAnAdministrator()) {
      this.selectedGeography.setValue(this.uniqueGeographies.find((x) => x.GeographyName == 'Demo'));
      this.wellIDs = this.wells.filter((x) => x.Geography.GeographyName == 'Demo').map((x) => x.WellID);
    } else if (this.uniqueGeographies.length == 1) {
      this.selectedGeography.setValue(this.uniqueGeographies[0]);
    }
    this.isReady = true;
  }

  getGeographiesToShow() {
    if (this.selectedGeography.value == null) {
      this.wellIDs = this.wells.map((x) => x.WellID);
      return this.uniqueGeographies;
    } else {
      this.wellIDs = this.wells.filter((x) => x.Geography.GeographyName == this.selectedGeography.value.GeographyName).map((x) => x.WellID);
      return [this.selectedGeography.value];
    }
  }

  isCurrentUserAdmin() {
    return this.authenticationService.isCurrentUserAnAdministrator();
  }

  getNumberOfWells(geography: GeographyDisplayDto) {
    return '(' + this.geographyWellDict[geography.GeographyName].length + ' Wells)';
  }

  getWellsFromGeography(geography: GeographyDisplayDto) {
    return this.geographyWellDict[geography.GeographyName];
  }

  setSelectedWell(well: WellLandownerDashboardDto) {
    this.highlightedWellID = well.WellID;
  }

  // the map stuff
  public map: L.Map;
  public layerControl: L.layerControl;
  public mapIsReady: boolean = false;

  handleMapReady(event: QanatMapInitEvent): void {
    this.map = event.map;
    this.layerControl = event.layerControl;
    this.mapIsReady = true;
    this.cdr.detectChanges();
  }

  openedPopup(event: OpenedWellPopupEvent) {
    this.highlightedWellID = event.wellID;
  }

  handleWellDelete(wellID: number) {
    // delete the well from this list.
    Object.keys(this.geographyWellDict).forEach(geographyName => {
      this.geographyWellDict[geographyName] = this.geographyWellDict[geographyName].filter(x => x.WellID != wellID);
    });
    this.wells = this.wells.filter(x => x.WellID != wellID);

  }
}
