import { Component, Input, ViewContainerRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WellLandownerDashboardDto } from 'src/app/shared/generated/model/well-landowner-dashboard-dto';
import { WaterAccountTitleComponent } from 'src/app/shared/components/water-account/water-account-title/water-account-title.component';
import { RouterLink } from '@angular/router';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'well-small-card',
  standalone: true,
  imports: [CommonModule, WaterAccountTitleComponent, IconComponent, RouterLink],
  templateUrl: './well-small-card.component.html',
  styleUrls: ['./well-small-card.component.scss']
})
export class WellSmallCardComponent {
  @Input() well: WellLandownerDashboardDto;

  @Input() set isHighlighted(value: boolean) {
    this._isHighlighted = value;
    if (this._isHighlighted) {
      this.vcr.element.nativeElement.scrollIntoView({ block: 'nearest' });
    }
  }
  get isHighlighted() {
    return this._isHighlighted;
  }
  private _isHighlighted: boolean = false;

  constructor(
    private vcr: ViewContainerRef
  ) { }

  getGeographyLowerCase() {
    return this.well.Geography.GeographyName.toLowerCase();
  }

}
