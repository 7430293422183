<div
    class="water-account-title"
    [ngClass]="{ small: small == true }"
    [title]="'#' + waterAccountNumber + ' ' + waterAccountName">
    <h2>
        <icon icon="WaterAccounts"></icon>
        <span class="water-account-title__name">
            #{{ waterAccountNumber }}
            <span class="water-account-title__id">
                {{ nameLength ? waterAccountName.substring(0, nameLength) : waterAccountName
                }}{{ nameLength && waterAccountName.length > nameLength ? '...' : '' }}
            </span>
        </span>
    </h2>
</div>
