<div class="list-view">
    <page-header
        icon="ActivityCenter"
        [templateRight]="templateRight"
        [customRichTextTypeID]="richTextTypeID">
        <ng-template #templateRight>
            <button class="btn btn-primary btn-rounded" (click)="addMeterModal()">
                + Add Meter
            </button>
        </ng-template>
    </page-header>

    <div class="page-body grid-12">
        <app-alert-display></app-alert-display>
        <div class="table-responsive">
            <qanat-grid
                *ngIf="meters$ | async as meters"
                [rowData]="meters"
                [columnDefs]="columnDefs"
                (gridReady)="onGridReady($event)"
                rowSelection="single"
                [downloadFileName]="'meters'"></qanat-grid>
        </div>
    </div>
</div>
