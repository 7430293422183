//  IMPORTANT:
//  This file is generated. Your changes will be lost.
//  Source Table: [dbo].[GETActionStatus]

export enum GETActionStatusEnum {
  Created = 1,
  GETIntegrationFailure = 2,
  CreatedInGET = 3,
  Queued = 4,
  Processing = 5,
  Complete = 6,
  SystemError = 7,
  InvalidOutput = 8,
  InvalidInput = 9,
  HasDryCells = 10,
  AnalysisFailed = 11,
  AnalysisSuccess = 12,
  ProcesingInputs = 13,
  RunningAnalysis = 14
}
