/**
 * Qanat.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { MeterGridDto } from '../model/meter-grid-dto';
import { MeterLinkDisplayDto } from '../model/meter-link-display-dto';
import { MeterStatusSimpleDto } from '../model/meter-status-simple-dto';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { catchError } from 'rxjs/operators';
import { ApiService } from '../../services';


@Injectable({
  providedIn: 'root'
})
export class MeterService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration
    , private apiService: ApiService) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param geographyID 
     * @param meterID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDMeterMeterIDGet(geographyID: number, meterID: number, observe?: 'body', reportProgress?: boolean): Observable<MeterGridDto>;
    public geographiesGeographyIDMeterMeterIDGet(geographyID: number, meterID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MeterGridDto>>;
    public geographiesGeographyIDMeterMeterIDGet(geographyID: number, meterID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MeterGridDto>>;
    public geographiesGeographyIDMeterMeterIDGet(geographyID: number, meterID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDMeterMeterIDGet.');
        }

        if (meterID === null || meterID === undefined) {
            throw new Error('Required parameter meterID was null or undefined when calling geographiesGeographyIDMeterMeterIDGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MeterGridDto>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/meter/${encodeURIComponent(String(meterID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param meterID 
     * @param meterGridDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDMeterMeterIDPost(geographyID: number, meterID: number, meterGridDto?: MeterGridDto, observe?: 'body', reportProgress?: boolean): Observable<MeterGridDto>;
    public geographiesGeographyIDMeterMeterIDPost(geographyID: number, meterID: number, meterGridDto?: MeterGridDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MeterGridDto>>;
    public geographiesGeographyIDMeterMeterIDPost(geographyID: number, meterID: number, meterGridDto?: MeterGridDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MeterGridDto>>;
    public geographiesGeographyIDMeterMeterIDPost(geographyID: number, meterID: number, meterGridDto?: MeterGridDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDMeterMeterIDPost.');
        }

        if (meterID === null || meterID === undefined) {
            throw new Error('Required parameter meterID was null or undefined when calling geographiesGeographyIDMeterMeterIDPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MeterGridDto>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/meter/${encodeURIComponent(String(meterID))}`,
            meterGridDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param meterGridDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDMeterPost(geographyID: number, meterGridDto?: MeterGridDto, observe?: 'body', reportProgress?: boolean): Observable<MeterGridDto>;
    public geographiesGeographyIDMeterPost(geographyID: number, meterGridDto?: MeterGridDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MeterGridDto>>;
    public geographiesGeographyIDMeterPost(geographyID: number, meterGridDto?: MeterGridDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MeterGridDto>>;
    public geographiesGeographyIDMeterPost(geographyID: number, meterGridDto?: MeterGridDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDMeterPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MeterGridDto>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/meter`,
            meterGridDto,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDMetersDropdownItemsGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<MeterLinkDisplayDto>>;
    public geographiesGeographyIDMetersDropdownItemsGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MeterLinkDisplayDto>>>;
    public geographiesGeographyIDMetersDropdownItemsGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MeterLinkDisplayDto>>>;
    public geographiesGeographyIDMetersDropdownItemsGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDMetersDropdownItemsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<MeterLinkDisplayDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/meters/dropdown-items`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param geographyID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public geographiesGeographyIDMetersGet(geographyID: number, observe?: 'body', reportProgress?: boolean): Observable<Array<MeterGridDto>>;
    public geographiesGeographyIDMetersGet(geographyID: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MeterGridDto>>>;
    public geographiesGeographyIDMetersGet(geographyID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MeterGridDto>>>;
    public geographiesGeographyIDMetersGet(geographyID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (geographyID === null || geographyID === undefined) {
            throw new Error('Required parameter geographyID was null or undefined when calling geographiesGeographyIDMetersGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<MeterGridDto>>(`${this.basePath}/geographies/${encodeURIComponent(String(geographyID))}/meters`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public meterStatusGet(observe?: 'body', reportProgress?: boolean): Observable<Array<MeterStatusSimpleDto>>;
    public meterStatusGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MeterStatusSimpleDto>>>;
    public meterStatusGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MeterStatusSimpleDto>>>;
    public meterStatusGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<MeterStatusSimpleDto>>(`${this.basePath}/meter-status`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(catchError((error: any) => { return this.apiService.handleError(error)}));
    }

}
