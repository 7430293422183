<div class="list-view">
    <page-header icon="Wells" [templateRight]="templateRight" [customRichTextTypeID]="richTextID">
        <ng-template #templateRight>
            <a class="btn btn-primary btn-rounded" [routerLink]="['upload']">
                Upload Reference Wells
            </a>
        </ng-template>
    </page-header>

    <div class="page-body grid-12">
        <app-alert-display></app-alert-display>
        <div class="table-responsive">
            <qanat-grid
                *ngIf="referenceWells$ | async as referenceWells"
                [rowData]="referenceWells"
                [columnDefs]="columnDefs"
                (gridReady)="onGridReady($event)"
                rowSelection="single"
                [downloadFileName]="'reference-wells'"></qanat-grid>
        </div>
    </div>
</div>
